import { actionTypes, snackbarVariant } from '../../config/app-constants';

export function highlightOverview() {
  return { type: actionTypes.appFooterActionTypes.HIGHLIGHT_OVERVIEW, value: { selectedIndex: 0 } };
}

export function highlightBudgets() {
  return { type: actionTypes.appFooterActionTypes.HIGHLIGHT_BUDGETS, value: { selectedIndex: 1 } };
}

export function highlightTransactions() {
  return { type: actionTypes.appFooterActionTypes.HIGHLIGHT_TRANSACTIONS, value: { selectedIndex: 3 } };
}

export function highlightMore() {
  return { type: actionTypes.appFooterActionTypes.HIGHLIGHT_MORE, value: { selectedIndex: 4 } };
}

export function showNotification(message, variant, okLabel, onOkClick, autoHide = true) {
  return {
    type: actionTypes.appFooterActionTypes.SHOW_NOTIFICATION,
    value: { show: true, message, okLabel, onOkClick, variant, autoHide }
  };
}

export function openAddTransactionPage() {
  return {
    type: actionTypes.appFooterActionTypes.OPEN_ADD_TRANSACTION_PAGE,
    value: { openAddTransaction: true }
  };
}

export function closeAddTransactionPage() {
  return {
    type: actionTypes.appFooterActionTypes.CLOSE_ADD_TRANSACTION_PAGE,
    value: { openAddTransaction: false }
  };
}

export function closeNotification() {
  return {
    type: actionTypes.appFooterActionTypes.SHOW_NOTIFICATION,
    value: {
      show: false,
      message: '',
      okLabel: '',
      variant: snackbarVariant.SUCCESS,
      onOkClick: () => {}
    }
  };
}
