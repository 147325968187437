import React from 'react';
import AppFooter from './AppFooter';
import { userIdFromCache } from '../shared/util';
import { Routes } from '../../config/app-constants';
import { withRouter } from 'react-router';
import AppHeader from './AppHeader';

class App extends React.Component {
  constructor(props) {
    super(props);
    if (userIdFromCache() === null) {
      this.props.history.push(Routes.appRoot);
    }
  }

  render() {
    return (
      <>
        <AppHeader
          title={this.props.title}
          onBackButtonClick={this.props.onBackButtonClick}
          showAvatar={this.props.showAvatar}
          hideInfoIcon={this.props.hideInfoIcon}
          toolbar={this.props.toolbar}
        />
        <div>{this.props.children}</div>
        <AppFooter />
      </>
    );
  }
}

let AppWithRouter = withRouter(App);
export default AppWithRouter;
