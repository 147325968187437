import { withStyles } from '@material-ui/core';
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import Trash from '@material-ui/icons/Delete';
import { colors } from '../../mui/colors';
import Star from '@material-ui/icons/Star';

/**
 * Success icon with style for Snackbar
 * @param theme
 * @returns {{icon: {marginRight: *, fontSize: number, opacity: number}}}
 */
const iconStyle = theme => ({
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit
  }
});
function successIcon(props) {
  const { classes } = props;
  return <CheckCircleIcon className={classNames(classes.icon)} />;
}
export const SuccessCheckCircleIcon = withStyles(iconStyle)(successIcon);

/**
 * Delete icon with style for Snackbar
 * @param props
 * @returns {*}
 */
function deleteSuccessIcon(props) {
  const { classes } = props;
  return <Trash className={classNames(classes.icon)} />;
}
export const DeleteIcon = withStyles(iconStyle)(deleteSuccessIcon);

/**
 * Release icon with style for Snackbar
 * @param theme
 * @returns {{icon: {marginRight: *, color: string, fontSize: number, opacity: number}}}
 */
const releaseIconStyle = theme => ({
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit,
    color: colors.yellow
  }
});
function releaseIcon(props) {
  const { classes } = props;
  return <Star className={classNames(classes.icon)} />;
}
export const NewReleaseIcon = withStyles(releaseIconStyle)(releaseIcon);
