import { MenuItem, Typography } from '@material-ui/core';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, withStyles } from '@material-ui/core/';
import { DeleteOutlined, EditOutlined, MoreVert } from '@material-ui/icons';
import React from 'react';
import App from '../../../containers/app';
import {
  findMatchById,
  getSubCategoryForId,
  isCategoryZeroLevel
} from '../../../containers/shared/transactionsWorkflow';
import { isEmptyString, userIdFromCache } from '../../../containers/shared/util';
import { getMyCategories, deleteTransactionWithId, addOneFreeTransaction } from '../../../db';
import { TransactionDetailItems } from '../Add/TransactionDetailItems';
import { notifications, snackbarVariant, actionTypes } from '../../../config/app-constants';
import BottomDialogStyled from '../../BottomDialogStyled';
import moment from 'moment';
import { store } from '../../..';
import { showNotification } from '../../../actions/app/appFooterActions';
import { AddEditWithRouter } from '../../../containers/transactions/AddEdit';

export default class TransactionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      existingTransaction: props.existingTransaction,
      remote: {},
      openDeleteTransactionDialog: false,
      openEditTransaction: false
    };
  }

  existingTransactionLoaded = (selectedCategory, selectedSubCategory, existingTransaction, userCategories) => {
    return {
      name: existingTransaction.name,
      currency: existingTransaction.currency,
      transactionDate: existingTransaction.date,
      amount: existingTransaction.amount,
      isExpense: existingTransaction.isExpense,
      selectedCategory: selectedCategory,
      selectedSubCategory: selectedSubCategory,
      userCategories: userCategories,
      userId: existingTransaction.userId, // fill existing transaction with userId
      id: existingTransaction.id // fill existing transaction with its uniqueId
    };
  };

  componentWillMount() {
    let filledTransactionPromise = getMyCategories(userIdFromCache()).then(userCategories => {
      let categoryId = this.state.existingTransaction.categoryId;
      let subCategoryId = this.state.existingTransaction.subCategoryId;

      if (isCategoryZeroLevel(categoryId, subCategoryId)) {
        return this.existingTransactionLoaded({}, {}, this.state.existingTransaction, userCategories);
      }

      let selectedCategory = findMatchById(userCategories, categoryId);
      let selectedSubCategory = !isEmptyString(subCategoryId)
        ? getSubCategoryForId(selectedCategory, subCategoryId)
        : {};
      return this.existingTransactionLoaded(
        selectedCategory,
        selectedSubCategory,
        this.state.existingTransaction,
        userCategories
      );
    });

    filledTransactionPromise.then(data => this.setState({ remote: { ...data } }));
  }

  onDeleteClick = () => this.setState({ openDeleteTransactionDialog: true });
  onTransactionDeleteSuccess = transaction => {
    return {
      type: actionTypes.listTransaction.TRANSACTION_DELETED_OK,
      value: { showTransactionDetail: false, transaction }
    };
  };
  onDeleteOk = () => {
    // hide the transaction delete dialog
    this.setState({ openDeleteTransactionDialog: false });

    // delete the transaction
    const transaction = this.props.existingTransaction;
    deleteTransactionWithId(transaction.id)
      .then(() => {
        let month = moment(transaction.date).format('M');
        let isCurrentMonthTransaction = parseInt(month, 10) === new Date().getMonth() + 1;
        if (isCurrentMonthTransaction) {
          addOneFreeTransaction(transaction.userId);
        }
      })
      .then(() => {
        // notify the user
        store.dispatch(showNotification(notifications.deleteTransaction, snackbarVariant.SUCCESS));
        // go back to transactions
        //this.props.onBackToTransactions();
        store.dispatch(this.onTransactionDeleteSuccess(transaction));
      })
      .catch(error => console.error(error));
  };
  onDeleteCancel = () => this.setState({ openDeleteTransactionDialog: false });
  onEditClick = () => this.setState({ openEditTransaction: true });
  onEditTransactionDiscard = () => this.setState({ openEditTransaction: false });

  render() {
    const {
      amount,
      isExpense,
      transactionDate,
      name,
      selectedCategory,
      selectedSubCategory,
      currency
    } = this.state.remote;

    return (
      <App
        showAvatar={!this.props.existingTransaction}
        title={'Transaction'}
        hideInfoIcon={true}
        onBackButtonClick={this.props.onBackToTransactions}
        toolbar={<ActionToolbarStyled onDeleteClick={this.onDeleteClick} onEditClick={this.onEditClick} />}
      >
        {this.state.openEditTransaction && (
          <AddEditWithRouter
            currency={currency}
            editTransaction={true}
            remoteTransaction={this.state.remote}
            onEditTransactionDiscard={this.onEditTransactionDiscard}
          />
        )}
        {!this.state.openEditTransaction && (
          <TransactionDetailItems
            amount={amount}
            isExpense={isExpense}
            currency={currency}
            date={transactionDate}
            name={name}
            categoryName={selectedCategory ? selectedCategory.name : ''}
            subCategoryName={selectedSubCategory ? selectedSubCategory.name : ''}
            readOnly
          />
        )}
        {this.state.openDeleteTransactionDialog && (
          <BottomDialogStyled
            open={this.state.openDeleteTransactionDialog}
            message={'Are you sure you want to delete this transaction?'}
            okLabel={'Delete Transaction'}
            onOk={this.onDeleteOk}
            cancelLabel={'Cancel'}
            onCancel={this.onDeleteCancel}
          />
        )}
      </App>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  edit: {
    color: theme.palette.common.white
  }
});

class ActionToolbar extends React.Component {
  state = {
    auth: true,
    anchorEl: null
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <>
        <IconButton onClick={this.props.onEditClick}>
          <EditOutlined className={classes.edit} />
        </IconButton>
        <IconButton
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>
            <List component="nav">
              <ListItem onClick={this.props.onDeleteClick}>
                <ListItemIcon>
                  <DeleteOutlined color={'primary'} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="subtitle1" color={'primary'}>
                      Delete
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </MenuItem>
        </Menu>
      </>
    );
  }
}
const ActionToolbarStyled = withStyles(styles)(ActionToolbar);
