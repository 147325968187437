import React from 'react';
import {OnBoardCard} from './onBoardCard';

export const UserOnboard = ({title, description, icon, onClickNext, onClickPrevious, disablePrevious, disableNext, showOnBoard, onSkip}) => {
    return <OnBoardCard title={title}
        icon={icon}
        description={description}
        onClickNext={onClickNext}
        onClickPrevious={onClickPrevious}
        disablePrevious={disablePrevious}
        disableNext={disableNext}
        showOnBoard={showOnBoard}
        onSkip={onSkip}
    />;
};