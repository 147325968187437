import React from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AppHeaderActions from '../../../actions/app/appHeaderActions';
import { MBHeader } from '../../../components/Header';

class AppHeader extends React.Component {
  render() {
    return (
      <MBHeader
        title={this.props.title}
        onBackButtonClick={this.props.onBackButtonClick}
        showAvatar={this.props.showAvatar}
        onClickInfo={this.props.onClickInfo}
        hideInfoIcon={this.props.hideInfoIcon}
        toolbar={this.props.toolbar}
      />
    );
  }
}

const mapStateToProps = state => {
  return { appHeaderState: state.appHeaderReducer };
};
const mapDispatchToProps = dispatch => {
  return {
    onClickInfo: bindActionCreators(AppHeaderActions.showOnBoarding, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);
