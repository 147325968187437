import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import deeksha from '../../../../images/team/deeksha.png';
import harit from '../../../../images/team/harit.png';
import { colors } from '../../../mui/colors';
import { SocialIcon } from '../../../SocialIcon';
import Typography from '@material-ui/core/Typography';

export const Team = () => (
  <Row className="team">
    <Col xs={12} sm={12} md={12} lg={12}>
      <TeamHeader />
    </Col>
    <Col xs={12} sm={12} md={12} lg={12}>
      <TeamMembers />
    </Col>
  </Row>
);

const TeamHeader = () => (
  <div className="team-headline">
    <Typography variant={'h5'} style={{ color: colors.primary }}>
      Team
    </Typography>
  </div>
);

const TeamMembers = () => (
  <Row around={'xs'} id={'team'}>
    <Col xs={12} sm={4} md={4} lg={4}>
      <TeamMember
        photo={deeksha}
        name={'Deeksha Sharma'}
        title={'Creator'}
        linkedInUrl={'https://www.linkedin.com/in/deekshasharma/'}
        twitterUrl={'https://twitter.com/deekshasharma25'}
        githubUrl={'https://github.com/deekshasharma'}
        mediumUrl={'https://medium.com/@deeksha.sharma25'}
      />
    </Col>
    <Col xs={12} sm={4} md={4} lg={4}>
      <TeamMember
        photo={harit}
        name={'Harit Himanshu'}
        title={'Co-Creator'}
        linkedInUrl={'https://www.linkedin.com/in/hhimanshu/'}
        twitterUrl={'https://twitter.com/harittweets'}
        githubUrl={'https://github.com/hhimanshu'}
        mediumUrl={'https://medium.com/@harittweets'}
      />
    </Col>
  </Row>
);

const TeamMember = ({ photo, name, title, linkedInUrl, twitterUrl, githubUrl, mediumUrl }) => (
  <Row>
    <Col xs={12}>
      <Row className="team-member" center="xs">
        <TeamPhoto photo={photo} altName={name} />
        <TeamMemberName name={name} />
        <TeamMemberTitle title={title} />
        <SocialHandle linkedInUrl={linkedInUrl} twitterUrl={twitterUrl} githubUrl={githubUrl} mediumUrl={mediumUrl} />
      </Row>
    </Col>
  </Row>
);

const TeamPhoto = ({ photo, altName }) => (
  <Col xs={12} sm={12} md={12} lg={12}>
    <img src={photo} alt={altName} className="team-photo" />
  </Col>
);

const TeamMemberName = ({ name }) => (
  <Col xs={12} sm={12} md={12} lg={12} className="team-member-name">
    {name}
  </Col>
);

const TeamMemberTitle = ({ title }) => (
  <Col xs={12} sm={12} md={12} lg={12} className="team-member-title">
    {title}
  </Col>
);

const SocialHandle = ({ linkedInUrl, twitterUrl, githubUrl, mediumUrl }) => (
  <Col xs={12} sm={12} md={12} lg={12} className="team-member-social-handles">
    <Row center="xs">
      <Col xs={1} sm={1} md={1} lg={1}>
        <SocialIcon
          fontSizePercent={'120%'}
          destinationUrl={linkedInUrl}
          fontColor={colors.primary1Color}
          iconClass="fa fa-linkedin"
        />
      </Col>
      <Col xs={1} sm={1} md={1} lg={1}>
        <SocialIcon
          fontSizePercent={'120%'}
          destinationUrl={twitterUrl}
          fontColor={colors.primary1Color}
          iconClass="fa fa-twitter"
        />
      </Col>
      <Col xs={1} sm={1} md={1} lg={1}>
        <SocialIcon
          fontSizePercent={'120%'}
          destinationUrl={githubUrl}
          fontColor={colors.primary1Color}
          iconClass="fa fa-github"
        />
      </Col>
      <Col xs={1} sm={1} md={1} lg={1}>
        <SocialIcon
          fontSizePercent={'120%'}
          destinationUrl={mediumUrl}
          fontColor={colors.primary1Color}
          iconClass="fa fa-medium"
        />
      </Col>
    </Row>
  </Col>
);
