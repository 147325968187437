import React from 'react';
import { CurrencyItem } from './CurrencyItem/index';
import { List, Grid } from '@material-ui/core';
import { colors } from '../../mui/colors';
import MFullScreenDialog from '../../mui/MFullScreenDialog';
import { MbSearch } from '../../MbSearch';

export const AllCurrencies = ({
  shouldRenderAllCurrencies,
  closeAllCurrencies,
  saveDefaultCurrency,
  allCurrencies,
  searchTerm,
  onSearch
}) => {
  return (
    <MFullScreenDialog
      appBarStyle={preferredCurrencyStyle}
      titleStyle={preferredCurrencyTitleStyle}
      open={shouldRenderAllCurrencies}
      onClickClose={closeAllCurrencies}
      title={'Default Currency'}
    >
      <Grid container>
        <Grid item xs={12}>
          <MbSearch placeholder={'Search Currency'} value={searchTerm} onChange={onSearch} />
        </Grid>
        <Grid item xs={12}>
          <List>
            {allCurrencies.map((currency, key) => (
              <CurrencyItem key={key} currency={currency} saveDefaultCurrency={saveDefaultCurrency} />
            ))}
          </List>
        </Grid>
      </Grid>
    </MFullScreenDialog>
  );
};

const preferredCurrencyStyle = {
  backgroundColor: colors.primary1Color
};

const preferredCurrencyTitleStyle = {
  fontSize: '98%'
};
