import { actionTypes } from '../../config/app-constants';
import { fetchTransactions } from '../../db';

export function changeListTransactionMonth(selectedMonth) {
  return {
    type: actionTypes.listTransaction.CHANGE_LIST_TRANSACTION_MONTH,
    value: { selectedMonth }
  };
}

export function changeListTransactionYear(selectedYear) {
  return {
    type: actionTypes.listTransaction.CHANGE_LIST_TRANSACTION_YEAR,
    value: { selectedYear }
  };
}

export function onTransactionSelected(selectedTransaction) {
  return {
    type: actionTypes.listTransaction.SHOW_TRANSACTION_DETAIL,
    value: { showTransactionDetail: true, selectedTransaction }
  };
}

export function backToTransactionList() {
  return {
    type: actionTypes.listTransaction.BACK_TO_TRANSACTION_DETAIL,
    value: { showTransactionDetail: false }
  };
}

export function loadTransactions(year, month, userId) {
  return function(dispatch) {
    return fetchTransactions(year, month, userId)
      .then(transactions => dispatch(loadTransactionsOnSuccess(transactions)))
      .catch(function(error) {});
  };
}

export function loadTransactionsOnSuccess(transactions) {
  return {
    type: actionTypes.listTransaction.LOAD_TRANSACTIONS_OK,
    value: { transactions, transactionsLoaded: true }
  };
}
