import React from 'react';
import { TagLine } from '../TagLine';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { BannerImage } from '../BannerImage';
import banner from '../../../../images/icons/banner/banner.svg';
import GetStarted from '../GettingStarted';
import { colors } from '../../../mui/colors';
import Typography from '@material-ui/core/Typography';

export const BannerMobile = ({ loginWithGoogle, loginWithFacebook, loginWithTwitter }) => (
  <div className="landing-page-mobile-banner" style={{ backgroundColor: colors.primary }}>
    <Row center="xs">
      <TagLine />
    </Row>
    <Row className="landing-page-mobile-image-button">
      <Col xsOffset={1} xs={5} sm={5} className="mobile-get-started-button">
        <GetStarted
          loginWithGoogle={loginWithGoogle}
          loginWithFacebook={loginWithFacebook}
          loginWithTwitter={loginWithTwitter}
        />

        <Typography variant={'body2'} style={{ color: colors.primaryTextColor, paddingLeft: '2vw', paddingTop: '4vh' }}>
          Or
        </Typography>

        <div style={{ paddingTop: '4vh' }}>
          <GetStarted
            signIn={true}
            loginWithGoogle={loginWithGoogle}
            loginWithFacebook={loginWithFacebook}
            loginWithTwitter={loginWithTwitter}
          />
        </div>
      </Col>

      <Col xs={6} sm={6}>
        <BannerImage image={banner} alt="banner" />
      </Col>
    </Row>
  </div>
);
