import React from 'react';
import { colors } from '../../components/mui/colors';
import './styles.css';
import { Col, Row } from 'react-flexbox-grid';
import MFullScreenDialog from '../mui/MFullScreenDialog';
import { MButton } from '../mui/MButton';

export const Feedback = ({ open, onClose, onSubmitFeedback, description, onDescriptionChange }) => {
  let inValidForm = description.trim().length === 0;
  let buttonTextColor = inValidForm ? colors.grayColor : colors.primaryTextColor;
  return (
    <MFullScreenDialog
      open={open}
      title={'Send Feedback'}
      onClickClose={onClose}
      appBarStyle={dialogStyle}
      titleStyle={dialogStyle.title}
    >
      <div className="feedback-desc-container">
        <textarea
          className="feedback-writing-area"
          autoFocus={true}
          placeholder="Share your issues or ideas"
          maxLength={350}
          wrap="hard"
          value={description}
          onChange={e => onDescriptionChange(e.target.value)}
        />
      </div>
      <Row className="send-feedback-button">
        <Col xs={12}>
          <Row center="xs">
            <Col xs={6}>
              <MButton
                onClick={onSubmitFeedback}
                disabled={inValidForm}
                style={{ backgroundColor: colors.primary4Color }}
              >
                <div style={{ color: buttonTextColor }}>Send Feedback</div>
              </MButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </MFullScreenDialog>
  );
};

const dialogStyle = {
  backgroundColor: colors.primary1Color,
  title: {
    fontSize: '95%'
  }
};
