import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { colors } from '../../../../mui/colors';
import './styles.css';
import { landingPageMenuItems } from '../../../../../config/app-constants';

class MobileMenu extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MenuIcon style={{ color: colors.primaryTextColor }} />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {}
          }}
        >
          {landingPageMenuItems.map(option => (
            <MenuItem key={option.key} onClick={this.handleClose}>
              <a className="mobile-menu-item" href={option.value}>
                {option.key}
              </a>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default MobileMenu;
