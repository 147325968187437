import { Select, withStyles } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import React from 'react';
import { colors } from '../../mui/colors';

const styles = {
  select: {
    color: colors.primaryTextColor
  },
  icon: {
    color: colors.primaryTextColor
  }
};

export const MSelect = withStyles(styles)(({ classes, open, onClose, onOpen, value, onChange, items }) => (
  <Select
    classes={{
      icon: classes.icon,
      select: classes.select
    }}
    open={open}
    onClose={onClose}
    onOpen={onOpen}
    value={value}
    onChange={onChange}
  >
    {items.map((entry, key) => (
      <MenuItem key={key} value={entry.value}>
        {entry.display}
      </MenuItem>
    ))}
  </Select>
));
