import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from './colors';

// example from https://material-ui.com/style/color/
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    }
  },
  typography: {
    useNextVariants: true
  }
});

export const robotoFonts = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900
};

export const emphasis = {
  textOnPrimaryWhite: {
    high: '1.0',
    medium: '0.60',
    disabled: '0.38'
  },
  textOnSecondaryDark: {
    high: '1.0',
    medium: '1.0',
    disabled: '0.38'
  },
  textOnSurfaceBlack: {
    high: '0.87',
    medium: '0.6',
    disabled: '0.38'
  },
  blackText: {
    high: '0.87',
    medium: '0.6',
    disabled: '0.38'
  },
  whiteText: {
    high: '1.0',
    medium: '0.6',
    disabled: '0.38'
  }
};
