import PropTypes from 'prop-types';
import MFullScreenDialog from '../../mui/MFullScreenDialog';
import React from 'react';
import Button from '@material-ui/core/Button';
import { TransactionDetailItems } from './TransactionDetailItems';

export default class ReviewScreen extends React.Component {
  render() {
    const {
      open,
      amount,
      isExpense,
      name,
      currency,
      date,
      categoryName,
      subCategoryName,
      nameTextRef,
      onCloseClick,
      onSaveClick,
      onAmountClick,
      onDateClick,
      onNameChange,
      onClearNameClick,
      onCategoryClick
    } = this.props;

    return (
      <MFullScreenDialog
        open={open}
        actionToolbar={<ActionToolbar title={'save'} onClick={onSaveClick} />}
        title={'Transaction'}
        onClickClose={onCloseClick}
      >
        <TransactionDetailItems
          amount={amount}
          isExpense={isExpense}
          currency={currency}
          date={date}
          name={name}
          categoryName={categoryName}
          subCategoryName={subCategoryName}
          nameTextRef={nameTextRef}
          onAmountClick={onAmountClick}
          onDateClick={onDateClick}
          onNameChange={onNameChange}
          onClearNameClick={onClearNameClick}
          onCategoryClick={onCategoryClick}
        />
      </MFullScreenDialog>
    );
  }
}

const ActionToolbar = ({ title, onClick }) => (
  <Button color="inherit" onClick={onClick}>
    {title}
  </Button>
);

ReviewScreen.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired
};
