import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { colors } from '../mui/colors';
import { emphasis } from '../mui/theme';
import MoneyOutlined from '@material-ui/icons/MoneyOutlined';

export const CurrencyDisplay = ({ currencyCode, currencyName }) => {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      style={{
        paddingLeft: '5vw',
        paddingRight: '5vw',
        height: '10vh',
        backgroundColor: '#DFF5FF'
      }}
    >
      <Grid>
        <Grid item>
          <Typography variant="subtitle1" style={{ color: colors.primary }}>
            {currencyCode}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ opacity: emphasis.textOnSurfaceBlack.medium }}>{currencyName}</Typography>
        </Grid>
      </Grid>

      <Grid item>
        <MoneyOutlined style={{ color: colors.primary }} />
      </Grid>
    </Grid>
  );
};
