import { actionTypes, defaultCurrencyNewUser } from '../../config/app-constants';

export const defaultAppFooterState = {
  selectedIndex: 0,
  openAddTransaction: false,
  defaultCurrency: defaultCurrencyNewUser,
  notification: {
    show: false,
    message: '',
    okLabel: '',
    variant: '',
    autoHide: true,
    onOkClick: () => {}
  }
};

export default function appFooterReducer(state = defaultAppFooterState, action) {
  switch (action.type) {
    case actionTypes.appFooterActionTypes.HIGHLIGHT_OVERVIEW:
      return Object.assign({}, state, action.value);
    case actionTypes.appFooterActionTypes.HIGHLIGHT_BUDGETS:
      return Object.assign({}, state, action.value);
    case actionTypes.appFooterActionTypes.HIGHLIGHT_TRANSACTIONS:
      return Object.assign({}, state, action.value);
    case actionTypes.appFooterActionTypes.HIGHLIGHT_MORE:
      return Object.assign({}, state, action.value);
    case actionTypes.appFooterActionTypes.SHOW_NOTIFICATION:
      return Object.assign({}, state, { notification: action.value });
    case actionTypes.appFooterActionTypes.HIDE_NOTIFICATION:
      return Object.assign({}, state, { notification: action.value });
    case actionTypes.appFooterActionTypes.OPEN_ADD_TRANSACTION_PAGE:
      return Object.assign({}, state, action.value);
    case actionTypes.appFooterActionTypes.CLOSE_ADD_TRANSACTION_PAGE:
      return Object.assign({}, state, action.value);
    case actionTypes.currencyActionTypes.LOAD_DEFAULT_CURRENCY_OK:
      return Object.assign({}, state, action.value);
    case actionTypes.currencyActionTypes.SAVE_DEFAULT_CURRENCY:
      return Object.assign({}, state, { defaultCurrency: action.value.currencyCode });
    default:
      return state;
  }
}
