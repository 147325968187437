import React from 'react';
import { Card } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import DeleteAction from '@material-ui/icons/Delete';
import { colors } from '../../mui/colors';
import { MCard } from '../../mui/MCard';
import { MButton } from '../../mui/MButton';
import { categoryNameToCardColor } from '../../../containers/shared/categoryWorkflow';
import { Placeholder } from '../../Placeholder';
import { emptyComponents } from '../../../config/app-constants';

export const ListAllBudgets = ({
  budgets,
  totalBudgetedAmount,
  onClick,
  onDeleteClick,
  isEmptyBudget,
  currencySymbol
}) => {
  return (
    <div className="budget-container">
      <TotalBudgetHeader
        onClick={onClick}
        budgets={budgets}
        totalBudgetedAmount={totalBudgetedAmount}
        currencySymbol={currencySymbol}
      />

      {isEmptyBudget && (
        <Placeholder
          title={emptyComponents.budgets.title}
          text={emptyComponents.budgets.text}
          image={emptyComponents.budgets.image}
        />
      )}

      {budgets.map((budget, key) => {
        return (
          <div className="budget-card" key={key}>
            <Card style={{ backgroundColor: categoryNameToCardColor(budget.categoryName) }}>
              <Row>
                <BudgetListItemCategory categoryName={budget.categoryName} />
                <BudgetListItemEditDelete onDeleteClick={onDeleteClick} budgetId={budget.id} />
              </Row>

              <Row>
                <BudgetListItemAmount budgetListAmount={budget.amount} currencySymbol={currencySymbol} />
                <BudgetListItemFrequency frequency={budget.frequency} />
              </Row>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export const TotalBudgetHeader = ({ onClick, budgets, totalBudgetedAmount, currencySymbol }) => {
  return (
    <MCard style={{ marginBottom: '1vh' }}>
      <Row>
        <Col
          xs={7}
          style={{
            paddingTop: '4vh',
            paddingBottom: '4vh',
            paddingLeft: '14vw'
          }}
        >
          <div style={{ fontWeight: 'bold' }}>{currencySymbol + totalBudgetedAmount.toFixed(2)}</div>
          <div
            style={{
              fontSize: '70%',
              fontWeight: 'bold',
              color: '#5D7079',
              paddingTop: '1vh'
            }}
          >
            TOTAL BUDGETED
          </div>
        </Col>
        <Col
          xs={5}
          style={{
            paddingTop: '4vh',
            paddingBottom: '4vh',
            paddingLeft: '15vw'
          }}
        >
          <MButton
            onClick={onClick}
            style={{
              width: '20vw',
              backgroundColor: colors.primary3Color,
              color: colors.primaryTextColor
            }}
          >
            ADD
          </MButton>
        </Col>
      </Row>
    </MCard>
  );
};

export const BudgetListItemCategory = ({ categoryName }) => {
  return (
    <Col xs className="budget-category-container">
      <div className="category-amount-repeat-labels">CATEGORY</div>
      <div className="budget-value">{categoryName}</div>
    </Col>
  );
};

export const BudgetListItemAmount = ({ budgetListAmount, currencySymbol }) => {
  return (
    <Col xs className="budget-amount-container">
      <div className="category-amount-repeat-labels">AMOUNT</div>
      <div className="budget-value">{currencySymbol + budgetListAmount}</div>
    </Col>
  );
};

export const BudgetListItemEditDelete = ({ onDeleteClick, budgetId }) => {
  return (
    <Col xs>
      <div className="budget-edit-delete-container">
        <div onClick={() => onDeleteClick(budgetId)}>
          <DeleteAction nativeColor={'#5D7079'} />
        </div>
      </div>
    </Col>
  );
};

export const BudgetListItemFrequency = ({ frequency }) => {
  return (
    <Col xs>
      <div className="budget-frequency-container">
        <div className="category-amount-repeat-labels">REPEATS</div>
        <div className="budget-value">{frequency.toUpperCase()}</div>
      </div>
    </Col>
  );
};
