import { combineReducers } from 'redux';
import currencyReducer from './settings/currencyReducer';
import appFooterReducer from './app/appFooterReducer';
import createBudgetReducer from './features/createBudgetReducer';
import listBudgetReducer from './features/listBudgetReducer';
import overviewReducer from './features/overviewReducer';
import transactionReducer from './features/transactionReducer';
import moreReducer from './features/moreReducer';
import feedbackReducer from './features/feedbackReducer';
import appHeaderReducer from './app/appHeaderReducer';

export const rootReducer = combineReducers({
  currencyReducer,
  appFooterReducer,
  transactionReducer,
  createBudgetReducer,
  listBudgetReducer,
  overviewReducer,
  moreReducer,
  feedbackReducer,
  appHeaderReducer
});
