import React from 'react';
import Logo from '../../../../images/icons/banner/moneybonsai.svg';
import { colors } from '../../../mui/colors';
import './styles.css';
import { Col, Row } from 'react-flexbox-grid';
import { DesktopMenu } from './DesktopMenu';
import MobileMenu from './MobileMenu';

export const LandingPageHeader = ({ isMobileView }) => (
  <div className="landing-page-header" style={{ backgroundColor: colors.primary }}>
    <Row between={'xs'}>
      <Col xs={6} sm={2} md={2} lg={2}>
        <AppLogo logo={Logo} alt="moneybonsai" />
      </Col>
      <Col xs={2} sm={4} md={4} lg={4}>
        {getMenu(isMobileView)}
      </Col>
    </Row>
  </div>
);

const AppLogo = ({ logo, alt }) => (
  <a href="/">
    <img src={logo} alt={alt} height={40} />
  </a>
);
const getMenu = isMobileView => (isMobileView ? <MobileMenu /> : <DesktopMenu />);
