import emptyOverview from '../images/icons/others/emptyoverview.svg';
import emptyBudget from '../images/icons/others/emptybudget.svg';
import emptyTransactions from '../images/icons/others/emptytransactions.svg';
import emptyCategoryAdd from '../images/icons/others/emptyCategoriesAddTransaction.svg';
import emptyBudgetCategory from '../images/icons/others/emptybudgetcategories.svg';

export const applicationVersion = '3.10.2';
export const welcomeTimeoutInSeconds = 8000;
export const currentUserId = 'uid';
export const currentUserPhoto = 'userPhoto';
export const isNewUser = 'isNewUser';
export const defaultCurrency = 'defaultCurrency';
export const freemiumUsersMaxAllowedTransactionsPerMonth = 50;
export const freemiumAccount = 'freemium';
export const blogLink = 'https://medium.com/moneybonsai/';
export const defaultCurrencyNewUser = 'CAD';
export const maxCategories = 20;
export const maxSubCategories = 20;
export const defaultTransactionName = 'QUICK ADD';
export const emptyString = '';
export const actionOnAddTransaction = {
  addCategoryAddTransaction: 'ADD_CATEGORY_ADD_TRANSACTION',
  editCategoryAddTransaction: 'EDIT_CATEGORY_ADD_TRANSACTION',
  onlyAddTransaction: 'ONLY_ADD_TRANSACTION'
};
export const actionOnEditTransaction = {
  addCategoryEditTransaction: 'ADD_CATEGORY_EDIT_TRANSACTION',
  editCategoryEditTransaction: 'EDIT_CATEGORY_EDIT_TRANSACTION',
  onlyEditTransaction: 'ONLY_EDIT_TRANSACTION'
};
export const budgetFrequency = {
  monthly: 'monthly',
  once: 'once'
};

export const transactionTypes = ['SPENT', 'EARNED' /*, 'TRANSFER'*/];
export const emptyComponents = {
  overview: {
    title: 'Breakdown of transactions not available',
    text: 'Tap on + button to create transactions and view your spendings by categories',
    image: emptyOverview
  },
  budgets: {
    title: 'You have no budgets',
    text: 'Tap on ADD to create a budget and track how your expenses perform to expected spending amount',
    image: emptyBudget
  },
  transactions: {
    title: 'You have no transactions',
    text: 'Tap on + button to add transaction',
    image: emptyTransactions
  },
  categoriesViaAddTransaction: {
    title: 'You do not have personalised categories',
    text: 'Choose from SUGGESTED tab OR tap on + ADD CATEGORY to create your own',
    image: emptyCategoryAdd
  },
  categoriesViaManage: {
    title: 'You do not have personalised categories',
    text: 'Add transaction using our SUGGESTED categories OR have your own from CREATE tab',
    image: emptyCategoryAdd
  },
  categoriesViaBudgetCreate: {
    title: 'You do not have personalised categories',
    text: 'To budget categories, add few transactions and assign them categories',
    image: emptyBudgetCategory
  }
};

export const transactionCategoryFieldToDelete = {
  SUBCATEGORY_ONLY: 'SUBCATEGORY_ONLY',
  ALL_CATEGORY_FIELDS: 'ALL_CATEGORY_FIELDS',
  NO_DELETE: 'NO_DELETE'
};

export const notifications = {
  addTransaction: 'Transaction Added!',
  deleteTransaction: 'Transaction Deleted!',
  editTransaction: 'Transaction Updated!',
  upgrade: { message: 'New Version Available!', okLabel: 'UPGRADE' }
};

export const snackbarVariant = {
  SUCCESS: 'success',
  NEW_RELEASE: 'newRelease',
  DELETE: 'delete'
};

export const Routes = {
  appRoot: '/',
  welcome: '/welcome',
  overview: '/overview',
  summaryDetail: '/summaryDetail',
  transactions: '/transactions',
  budgets: '/budgets',
  budgetsAdd: '/budgets/add',
  more: '/more',
  settings: '/settings',
  privacy: '/privacy',
  terms: '/terms',
  cookies: '/cookies'
};

export const landingPageMenuItems = [
  { key: 'Features', value: '#features' },
  { key: 'Pricing', value: '#pricing' },
  { key: 'Team', value: '#team' }
];

/**
 * TWO_LEVEL: CATEGORY and SUBCATEGORY AVAILABLE
 * ONE_LEVEL: ONLY CATEGORY AVAILABLE
 * ZERO_LEVEL: TRANSACTION NOT CATEGORIZED
 * DELETED_CATEGORY_LEVEL: CATEGORY was DELETED (cascades to SUBCATEGORIES)
 * DELETED_SUBCATEGORY_LEVEL: SUBCATEGORY WAS DELETED
 */
export const transactionCategoryLevelsToLabels = {
  ONE_LEVEL: {
    subCategoryLabel: ''
  },
  ZERO_LEVEL: {
    categoryLabel: 'UNKNOWN',
    subCategoryLabel: ''
  },
  DELETED_LEVEL: {
    categoryLabel: 'UNKNOWN',
    subCategoryLabel: ''
  }
};

export const appLinks = {
  product: [
    { name: 'Features', link: '#features' },
    { name: 'Pricing', link: '#pricing' },
    { name: "What's new", link: 'https://releases.moneybonsai.com', target: '_blank', rel: 'noopener noreferrer' }
  ],
  company: [{ name: 'About', link: '#team' }, { name: 'Team', link: '#team' }],
  legal: [
    { name: 'Privacy', link: Routes.privacy, target: '_blank', rel: 'noopener noreferrer' },
    { name: 'Terms', link: Routes.terms, target: '_blank', rel: 'noopener noreferrer' },
    { name: 'Cookies', link: Routes.cookies, target: '_blank', rel: 'noopener noreferrer' }
  ],
  contactUs: [
    {
      name: 'Twitter @moneybonsai',
      link: 'https://twitter.com/moneybonsai',
      target: '_blank',
      rel: 'noopener noreferrer'
    },
    { name: 'Email', link: 'mailTo:mailto:team@moneybonsai.com' }
  ],
  social: {
    medium: 'https://medium.com/moneybonsai',
    facebook: 'https://www.facebook.com/moneybonsai',
    instagram: 'https://www.instagram.com/moneybonsai'
  }
};

export const actionTypes = {
  listTransaction: {
    LOAD_TRANSACTIONS_OK: 'LOAD_TRANSACTIONS_OK',
    CHANGE_LIST_TRANSACTION_MONTH: 'CHANGE_LIST_TRANSACTION_MONTH',
    CHANGE_LIST_TRANSACTION_YEAR: 'CHANGE_LIST_TRANSACTION_YEAR',
    SHOW_TRANSACTION_DETAIL: 'SHOW_TRANSACTION_DETAIL',
    BACK_TO_TRANSACTION_DETAIL: 'BACK_TO_TRANSACTION_DETAIL',
    TRANSACTION_ADDED_OK: 'TRANSACTION_ADDED_OK',
    TRANSACTION_DELETED_OK: 'TRANSACTION_DELETED_OK',
    TRANSACTION_EDITED_OK: 'TRANSACTION_EDITED_OK'
  },
  reviewTransactionActionTypes: {
    NUM_FREE_TRANSACTIONS_UPDATED: 'NUM_FREE_TRANSACTIONS_UPDATED'
  },
  currencyActionTypes: {
    SHOW_ALL_CURRENCIES: 'SHOW_ALL_CURRENCIES',
    CLOSE_ALL_CURRENCIES: 'CLOSE_ALL_CURRENCIES',
    LOAD_DEFAULT_CURRENCY_OK: 'LOAD_DEFAULT_CURRENCY_OK',
    LOAD_ALL_CURRENCIES: 'LOAD_ALL_CURRENCIES',
    SAVE_DEFAULT_CURRENCY: 'SAVE_DEFAULT_CURRENCY',
    ON_SEARCH_CURRENCY: 'ON_SEARCH_CURRENCY'
  },
  appFooterActionTypes: {
    HIGHLIGHT_OVERVIEW: 'HIGHLIGHT_OVERVIEW',
    HIGHLIGHT_BUDGETS: 'LOAD_BUDGETS',
    HIGHLIGHT_TRANSACTIONS: 'HIGHLIGHT_TRANSACTIONS',
    HIGHLIGHT_MORE: 'HIGHLIGHT_MORE',
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
    OPEN_ADD_TRANSACTION_PAGE: 'OPEN_ADD_TRANSACTION_PAGE',
    CLOSE_ADD_TRANSACTION_PAGE: 'CLOSE_ADD_TRANSACTION_PAGE'
  },
  createBudgetActionTypes: {
    OPEN_BUDGET_CATEGORY: 'OPEN_BUDGET_CATEGORY',
    OPEN_BUDGET_CATEGORY_SUCCESS: 'OPEN_BUDGET_CATEGORY_SUCCESS',
    BUDGET_CATEGORY_SELECTED: 'BUDGET_CATEGORY_SELECTED',
    CLOSE_BUDGET_CATEGORY: 'CLOSE_BUDGET_CATEGORY',
    OPEN_BUDGET_AMOUNT: 'OPEN_BUDGET_AMOUNT',
    BUDGET_AMOUNT_DONE: 'BUDGET_AMOUNT_DONE',
    CHANGE_FREQUENCY: 'CHANGE_FREQUENCY',
    CLEAR_CREATE_BUDGET: 'CLEAR_CREATE_BUDGET',
    SHOW_DUPLICATE_BUDGET: 'SHOW_DUPLICATE_BUDGET'
  },
  listBudgetActionTypes: {
    LOAD_BUDGETS_OK: 'LOAD_BUDGETS_OK',
    DELETE_CLICKED: 'DELETE_CLICKED',
    BUDGET_DELETED: 'BUDGET_DELETED',
    BUDGET_DELETE_CANCEL: 'BUDGET_DELETE_CANCEL'
  },
  overviewActionTypes: {
    OVERVIEW_LOADED_OK: 'OVERVIEW_LOADED_OK',
    SHOW_OVERVIEW_DETAIL: 'SHOW_OVERVIEW_DETAIL',
    CHANGE_OVERVIEW_MONTH: 'CHANGE_OVERVIEW_MONTH',
    CHANGE_OVERVIEW_YEAR: 'CHANGE_OVERVIEW_YEAR'
  },
  moreActionTypes: {
    OPEN_FEEDBACK: 'OPEN_FEEDBACK',
    CLOSE_FEEDBACK: 'CLOSE_FEEDBACK',
    OPEN_MANAGE_CATEGORIES: 'OPEN_MANAGE_CATEGORIES',
    BACK_FROM_MANAGE_CATEGORIES: 'BACK_FROM_MANAGE_CATEGORIES'
  },
  feedbackActionTypes: {
    SUBMIT_FEEDBACK: 'SUBMIT_FEEDBACK',
    SUBMITTED_OK: 'SUBMITTED_OK',
    ON_DESCRIPTION_CHANGE: 'ON_DESCRIPTION_CHANGE'
  },
  appHeaderActionTypes: {
    SHOW_ONBOARD: 'SHOW_ONBOARD'
  }
};
