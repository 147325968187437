import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { appLinks } from '../../../../config/app-constants';
import { withStyles } from '@material-ui/core/styles';
import mbTree from '../../../../images/icons/banner/moneybonsai.svg';
import { colors } from '../../../mui/colors';
import { SocialIcon } from '../../../SocialIcon';
import { robotoFonts } from '../../../mui/theme';

export const LandingPageFooter = () => {
  return (
    <div style={{ backgroundColor: colors.primaryBlue, flexGrow: 1 }}>
      <Grid container justify={'space-evenly'}>
        <Grid item xs={12} sm={2} lg={3}>
          <LogoFooter />
        </Grid>

        <Grid item xs={12} sm={2} lg={1}>
          <FooterColumns headerName={'Product'} items={appLinks.product} />
        </Grid>

        <Grid item xs={12} sm={2} lg={1}>
          <FooterColumns headerName={'Company'} items={appLinks.company} />
        </Grid>

        <Grid item xs={12} sm={2} lg={1}>
          <FooterColumns headerName={'Legal'} items={appLinks.legal} />
        </Grid>

        <Grid item xs={12} sm={2} lg={2}>
          <FooterColumns headerName={'Contact'} items={appLinks.contactUs} />
        </Grid>

        <Grid item xs={12} sm={2} lg={2}>
          <SocialIcons />
        </Grid>
      </Grid>
    </div>
  );
};

function LogoWithText(props) {
  const { classes } = props;
  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container direction="row">
        <img src={mbTree} alt="moneybonsai" height={'40px'} />
      </Grid>

      <Grid item>
        <Typography variant="body2" className={classes.text}>
          © 2019 moneybonsai
        </Typography>
      </Grid>
    </Grid>
  );
}

const footerLogoStyle = {
  root: {
    flexGrow: 1
  },
  container: {
    marginLeft: '4vw',
    marginTop: '2vh'
  },
  name: {
    color: colors.primaryTextColor,
    marginLeft: '0.5vw'
  },
  text: {
    color: colors.primaryTextColor,
    opacity: 0.38,
    fontWeight: robotoFonts.light,
    marginTop: '2vh'
  }
};
export const LogoFooter = withStyles(footerLogoStyle)(LogoWithText);

function ColumnHeader(props) {
  const { classes, headerName } = props;
  return (
    <Typography variant="subtitle1" className={classes.footerColumnHeader}>
      {headerName}
    </Typography>
  );
}

function ColumnItem(props) {
  const { classes, name, link, target, rel } = props;
  return (
    <a href={link} target={target} rel={rel} style={{ textDecoration: 'none' }}>
      <ListItem button disableGutters>
        <ListItemText>
          <Typography variant="subtitle2" className={classes.footerColumnItems}>
            {name}
          </Typography>
        </ListItemText>
      </ListItem>
    </a>
  );
}

function FooterColumn(props) {
  const { classes, headerName, items } = props;
  return (
    <Grid container direction="column" color={colors.primaryTextColor} className={classes.footerColumn}>
      <Grid item>
        <ColumnHeader classes={classes} headerName={headerName} />
      </Grid>

      <Grid item>
        <List component="nav">
          {items.map((item, index) => (
            <ColumnItem
              key={index}
              classes={classes}
              name={item.name}
              link={item.link}
              target={item.target}
              rel={item.rel}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

const styles = {
  root: {
    flexGrow: 1
  },
  footerColumn: {
    marginLeft: '4vw',
    marginTop: '2vh'
  },
  footerColumnHeader: {
    color: colors.primaryTextColor,
    opacity: 1
  },
  footerColumnItems: {
    color: colors.primaryTextColor,
    opacity: 0.85,
    fontWeight: robotoFonts.light
  }
};
export const FooterColumns = withStyles(styles)(FooterColumn);

export const SocialIcons = () => {
  return (
    <Grid container direction="row" justify={'space-around'} style={{ marginTop: '2vh', marginBottom: '2vh' }}>
      <Grid item>
        <SocialIcon
          destinationUrl={appLinks.social.medium}
          fontColor={colors.primaryTextColor}
          iconClass="fa fa-medium"
        />
      </Grid>
      <Grid item>
        <SocialIcon
          destinationUrl={appLinks.social.facebook}
          fontColor={colors.primaryTextColor}
          iconClass="fa fa-facebook-f"
        />
      </Grid>
      <Grid item>
        <SocialIcon
          destinationUrl={appLinks.social.instagram}
          fontColor={colors.primaryTextColor}
          iconClass="fa fa-instagram"
        />
      </Grid>
    </Grid>
  );
};
