import { getCategoryIdToLabel } from './categoryWorkflow';
import { transactionCategoryLevelsToLabels } from '../../config/app-constants';

export const getBudgetsWithCategoryName = (budgets, userCategories) => {
  return budgets.map(budget => {
    return Object.assign({}, budget, {
      categoryName: getCategoryIdToLabel(budget.categoryId, userCategories)
    });
  });
};

export const getTotalBudgetedAmount = budgets => {
  return budgets.reduce((acc, budget) => {
    let budgetAmount =
      budget.categoryName === transactionCategoryLevelsToLabels.DELETED_LEVEL.categoryLabel
        ? 0.0
        : parseFloat(budget.amount);
    return acc + budgetAmount;
  }, 0.0);
};

export const getRemainingCategoriesToBudget = (userCategories, budgetedCategoryIds) => {
  let budgetedCategoryIdsSet = new Set(budgetedCategoryIds);
  return userCategories.filter(uc => !budgetedCategoryIdsSet.has(uc.id));
};
