import React from 'react';
import * as moment from 'moment';
import { Col, Row } from 'react-flexbox-grid';
import { MSelect } from './mui/MSelect';
import { colors } from '../components/mui/colors';

class MonthYear extends React.Component {
  state = {
    openYear: false,
    openMonth: false
  };

  handleYearChange = event => {
    this.props.onYearChange(event);
    this.handleYearClose();
  };

  handleMonthChange = event => {
    this.props.onMonthChange(event);
    this.handleMonthClose();
  };

  handleYearClose = () => {
    this.setState({ openYear: false });
  };

  handleYearOpen = () => {
    this.setState({ openYear: true });
  };

  handleMonthClose = () => {
    this.setState({ openMonth: false });
  };

  handleMonthOpen = () => {
    this.setState({ openMonth: true });
  };

  render() {
    return (
      <Row center="xs" style={{ background: colors.primaryBlue, paddingBottom: '1vh', paddingTop: '1vh' }}>
        <Col xs={6} sm={6} md={6} lg={6}>
          <MSelect
            onClose={this.handleMonthClose}
            onOpen={this.handleMonthOpen}
            value={this.props.month}
            onChange={this.handleMonthChange}
            items={getMonths()}
          />
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <MSelect
            open={this.state.openYear}
            onClose={this.handleYearClose}
            onOpen={this.handleYearOpen}
            value={this.props.year}
            onChange={this.handleYearChange}
            items={getYears()}
          />
        </Col>
      </Row>
    );
  }
}

const getMonths = () => {
  return moment.months().map((month, key) => ({
    value: key,
    display: month
  }));
};

const getYears = () => {
  return [2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013].map(year => ({
    value: year,
    display: year
  }));
};

export default MonthYear;
