import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { OverviewRowHeader } from './OverviewRowHeader';
import { OverviewRowBody } from './OverviewRowBody';
import AppCard from '../AppCard';
import { MIconRightArrow } from '../mui/MIconRightArrow';

export const OverviewBudgetCard = ({
  cardColor,
  categoryName,
  spent,
  budgeted,
  numTransactions,
  isOverBudget,
  onClick,
  currencySymbol
}) => (
  <AppCard color={cardColor}>
    <Row onClick={onClick}>
      <Col xs={10} sm={10} md={10} lg={10}>
        <OverviewRowHeader
          categoryName={categoryName}
          spent={spent}
          budgeted={budgeted}
          isOverBudget={isOverBudget}
          currencySymbol={currencySymbol}
        />
        <OverviewRowBody
          numTransactions={numTransactions}
          spent={spent}
          budgeted={budgeted}
          isOverBudget={isOverBudget}
          currencySymbol={currencySymbol}
        />
      </Col>
      <Col xs={2} sm={2} md={2} lg={2} className="overview-budget-row-next">
        <MIconRightArrow />
      </Col>
    </Row>
  </AppCard>
);
