import React from 'react';
import { LandingPageHeader } from './LandingPageHeader';
import { LandingPageFooter } from './LandingPageFooter';
import Features from './Features';
import Pricing from './Pricing';
import { Team } from './Team';
import { BannerWebsite } from './BannerWebsite';
import { BannerMobile } from './BannerMobile';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { colors } from '../../mui/colors';
import { MButton } from '../../mui/MButton';

export const LandingPage = ({
  isMobileView,
  loginWithGoogle,
  loginWithFacebook,
  loginWithTwitter,
  onCloseCookies,
  showCookies
}) => (
  <div style={{ height: '100%' }}>
    {showCookies && <CookiesBanner onCloseCookies={onCloseCookies} />}
    <LandingPageHeader isMobileView={isMobileView} />
    <section id="banner">{getBanner(isMobileView, loginWithGoogle, loginWithFacebook, loginWithTwitter)}</section>
    <section id="features">
      <Features />
    </section>
    <section id="pricing">
      <Pricing
        loginWithGoogle={loginWithGoogle}
        loginWithFacebook={loginWithFacebook}
        loginWithTwitter={loginWithTwitter}
      />
    </section>
    <section id="team">
      <Team />
    </section>
    <LandingPageFooter />
  </div>
);

const getBanner = (isMobileView, loginWithGoogle, loginWithFacebook, loginWithTwitter) =>
  isMobileView ? (
    <BannerMobile
      loginWithGoogle={loginWithGoogle}
      loginWithFacebook={loginWithFacebook}
      loginWithTwitter={loginWithTwitter}
    />
  ) : (
    <BannerWebsite
      loginWithGoogle={loginWithGoogle}
      loginWithFacebook={loginWithFacebook}
      loginWithTwitter={loginWithTwitter}
    />
  );

function WebsiteCookies(props) {
  const { classes } = props;
  return (
    <div>
      <Paper className={classes.root} elevation={1}>
        <Typography component="p" color="inherit">
          Moneybonsai uses cookies to improve your experience. To learn more, check out our cookie policy at the bottom
          of this website. By clicking OK or continuing to use our site, you agree that we can place these cookies.
        </Typography>
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <MButton
            fullWidth={false}
            style={{ backgroundColor: colors.primary1Color, color: colors.primaryTextColor }}
            onClick={props.onCloseCookies}
          >
            OK
          </MButton>
        </div>
      </Paper>
    </div>
  );
}

WebsiteCookies.propTypes = {
  classes: PropTypes.object.isRequired
};
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: colors.primary6Color,
    color: colors.grayColor
  }
});
const CookiesBanner = withStyles(styles)(WebsiteCookies);
export default CookiesBanner;
