import React from 'react';
import { CategoryImage } from '../../../../../containers/shared/categoryImage/index';
import './styles.css';

export const CategoryRow = ({ category, onCategoryClick }) => {
  return (
    <div className="category-row-container">
      <CategoryItem category={category} onCategoryClick={onCategoryClick} />
    </div>
  );
};

export const CategoryItem = ({ category, onCategoryClick }) => {
  const width = 50;
  const height = 40;
  let categoryName = category.name;
  return (
    <div className="image-name-container" onClick={() => onCategoryClick(category)}>
      <div>
        <CategoryImage categoryName={categoryName} w={width} h={height} />
      </div>
      <div className="category-name">{categoryName}</div>
    </div>
  );
};
