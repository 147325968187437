import React from 'react';
import './styles.css';
import banner from '../../../../images/icons/banner/banner.svg';
import { CallToAction } from './CallToAction';
import { BannerImage } from '../BannerImage';
import { colors } from '../../../mui/colors';
import Grid from '@material-ui/core/Grid';

export const BannerWebsite = ({ loginWithGoogle, loginWithFacebook, loginWithTwitter }) => {
  return (
    <div>
      <Grid container direction="row" style={{ backgroundColor: colors.primary }} justify={'space-around'}>
        <Grid item>
          <CallToAction
            loginWithGoogle={loginWithGoogle}
            loginWithFacebook={loginWithFacebook}
            loginWithTwitter={loginWithTwitter}
          />
        </Grid>
        <Grid item style={{ height: '50vh', width: '50vh' }}>
          <BannerImage image={banner} alt="banner" />
        </Grid>
      </Grid>
    </div>
  );
};
