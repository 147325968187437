import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';

export const OverviewDetailTransaction = ({ dayOfMonth, dayOfWeek, name, amount, amountColor, currencySymbol }) => (
  <Row className="overview-detail-transaction">
    <TransactionDate dayOfMonth={dayOfMonth} dayOfWeek={dayOfWeek} />
    <TransactionName name={name} />
    <TransactionAmount amount={amount} amountColor={amountColor} currencySymbol={currencySymbol} />
  </Row>
);

const TransactionName = ({ name }) => (
  <Col xs={7} sm={7} md={7} lg={7} className="overview-detail-transaction-name">
    {name}
  </Col>
);

const TransactionAmount = ({ amount, amountColor, currencySymbol }) => (
  <Col xs={2} sm={3} md={3} lg={3} style={{ color: `${amountColor}` }} className="overview-detail-transaction-amount">
    {currencySymbol + amount}
  </Col>
);

const TransactionDate = ({ dayOfMonth, dayOfWeek }) => (
  <Col xs={2} sm={2} md={2} lg={2}>
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="overview-detail-transaction-day-of-month">
        {dayOfMonth}
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} className="overview-detail-transaction-day-of-week">
        {dayOfWeek}
      </Col>
    </Row>
  </Col>
);
