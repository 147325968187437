import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { colors } from '../../components/mui/colors';
import { OverviewHeaderItem } from './OverviewHeaderItem';
import { MCard } from '../mui/MCard';
import './styles.css';
import { roundForDisplay } from '../../containers/shared/util';

export const OverviewHeader = ({ spent, earned, daysLeft, currencySymbol }) => (
  <MCard style={{ marginBottom: '2vh' }}>
    <Row className="overview-header" center="xs" middle="xs">
      <Col xs={4} sm={4} md={4} lg={4}>
        <OverviewHeaderItem
          label={'SPENT'}
          value={currencySymbol + roundForDisplay(spent)}
          valueColor={colors.debitColor}
        />
      </Col>
      <Col xs={4} sm={4} md={4} lg={4}>
        <OverviewHeaderItem
          label={'EARNED'}
          value={currencySymbol + roundForDisplay(earned)}
          valueColor={colors.creditColor}
        />
      </Col>
      <Col xs={4} sm={4} md={4} lg={4}>
        <OverviewHeaderItem label={'DAYS LEFT'} value={daysLeft} valueColor={colors.blackColor} />
      </Col>
    </Row>
  </MCard>
);
