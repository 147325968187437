import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { OverviewRowIconAndCategory } from './OverviewRowIconAndCategory';
import { BudgetExceededStatus } from './BudgetExceededStatus';
import { InBudgetStatus } from './InBudgetStatus';
import { getDifference } from '../../../containers/shared/util';

export const OverviewRowHeader = ({ categoryName, spent, budgeted, isOverBudget, currencySymbol }) => {
  return (
    <Row className="overview-row-header">
      <Col xs={6} sm={6} md={6} lg={6}>
        <OverviewRowIconAndCategory categoryName={categoryName} />
      </Col>
      <Col xsOffset={1} xs={5} sm={5} md={5} lg={5}>
        {isOverBudget && <BudgetExceededStatus />}
        {!isOverBudget && (
          <InBudgetStatus amountRemaining={getDifference(budgeted, spent)} currencySymbol={currencySymbol} />
        )}
      </Col>
    </Row>
  );
};
