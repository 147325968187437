import React from 'react';
import { colors } from '../../components/mui/colors';
import { OverviewDetailBudgetHeader } from '../OverviewDetailBudgetHeader';
import { OverviewDetailCard } from '../OverviewDetailCard';
import { OverviewDetailHeader } from '../OverviewDetailHeader';
import MFullScreenDialog from '../mui/MFullScreenDialog';
import './styles.css';
import { getAbsDiff } from '../../containers/shared/util';
import {
  getNetSpentFromBudgeted,
  getOverBudgetOrRemainingColor,
  getOverBudgetOrRemainingLabel,
  getSpentOrEarnedColor,
  getSpentOrEarnedLabel
} from '../../containers/shared/overviewWorkflow';

const SubcategoriesStyle = {
  backgroundColor: colors.primary1Color,
  title: {
    fontSize: '95%'
  }
};

export const OverviewDetailPage = ({
  category,
  daysRemaining,
  isBudgetedCategory,
  budgeted,
  spent,
  earned,
  spendingBySubCategory,
  onClick,
  currencySymbol
}) => (
  <MFullScreenDialog
    open={true}
    onClickClose={onClick}
    title={'Overview Detail'}
    appBarStyle={SubcategoriesStyle}
    titleStyle={SubcategoriesStyle.title}
  >
    <div className="overview-detail-page">
      {isBudgetedCategory &&
        getOverviewDetailBudgetHeader(category, daysRemaining, spent, earned, budgeted, currencySymbol)}
      {!isBudgetedCategory && getOverviewDetailHeader(category, daysRemaining, spent, earned, currencySymbol)}

      {spendingBySubCategory.map(entry => {
        let { spent, earned, transactions } = entry.payload;
        let netSpent = getAbsDiff(earned, spent);
        let spentOrEarnedLabel = getSpentOrEarnedLabel(spent, earned);
        let spentOrRemainingColor = getSpentOrEarnedColor(spent, earned);

        return (
          <OverviewDetailCard
            subCategory={entry.subCategory}
            key={entry.subCategory}
            numTransactions={transactions.length}
            totalAmountLabel={spentOrEarnedLabel}
            totalAmountValue={netSpent}
            totalAmountColor={spentOrRemainingColor}
            transactions={transactions}
            currencySymbol={currencySymbol}
          />
        );
      })}
    </div>
  </MFullScreenDialog>
);

const getOverviewDetailBudgetHeader = (category, daysRemaining, spent, earned, budgeted, currencySymbol) => {
  let netSpentFromBudgeted = getNetSpentFromBudgeted(spent, earned, budgeted);
  let overBudgetOrRemainingColor = getOverBudgetOrRemainingColor(spent, budgeted);
  let overBudgetOrRemainingLabel = getOverBudgetOrRemainingLabel(spent, budgeted);

  return (
    <OverviewDetailBudgetHeader
      category={category}
      daysRemaining={daysRemaining}
      budgeted={budgeted}
      spentOrRemainingLabel={overBudgetOrRemainingLabel}
      spentOrRemainingValue={netSpentFromBudgeted}
      spentOrRemainingColor={overBudgetOrRemainingColor}
      currencySymbol={currencySymbol}
    />
  );
};

const getOverviewDetailHeader = (category, daysRemaining, spent, earned, currencySymbol) => {
  let spentOrEarnedValue = getAbsDiff(earned, spent);
  let spentOrEarnedLabel = getSpentOrEarnedLabel(spent, earned);
  let spentOrEarnedColor = getSpentOrEarnedColor(spent, earned);

  return (
    <OverviewDetailHeader
      category={category}
      daysRemaining={daysRemaining}
      spentOrEarnedValue={spentOrEarnedValue}
      spentOrEarnedLabel={spentOrEarnedLabel}
      spentOrEarnedColor={spentOrEarnedColor}
      currencySymbol={currencySymbol}
    />
  );
};
