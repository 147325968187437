import React from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';
import { emphasis } from '../mui/theme';

export const HeadingContent = ({ heading, value }) => (
  <HeadingChildrenStyled heading={heading}>
    <Typography variant={'subtitle1'} color={'primary'}>
      {value}
    </Typography>
  </HeadingChildrenStyled>
);

const HeadingChildrenStyles = theme => ({
  overline: {
    opacity: emphasis.textOnSurfaceBlack.medium,
    lineHeight: 1,
    paddingBottom: theme.spacing.unit,
    border: '0px solid black'
  }
});

const HeadingChildren = ({ heading, children, classes }) => (
  <Grid container>
    <Grid xs={12} item>
      <Typography variant={'overline'} className={classes.overline}>
        {heading}
      </Typography>
    </Grid>
    <Grid xs={12} item>
      {children}
    </Grid>
  </Grid>
);
export const HeadingChildrenStyled = withStyles(HeadingChildrenStyles)(HeadingChildren);
