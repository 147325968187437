import {actionTypes} from '../../config/app-constants';

export const defaultAppHeaderState = {
    showOnBoard: false,
    showAvatar: true,

};

export default function appHeaderReducer(state = defaultAppHeaderState, action) {
    switch(action.type){
    case actionTypes.appHeaderActionTypes.SHOW_ONBOARD:
        return Object.assign({}, state, action.value);
    default:
        return state;
    }
    
}