import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100px'
  }
});

function MChip(props) {
  const { classes, chipColor, fontColor, label, onClick } = props;
  const chipStyle = { backgroundColor: chipColor, color: fontColor };

  return (
    <Chip
      className={classes.root}
      style={chipStyle}
      label={label}
      onClick={onClick}
      color="primary"
      variant="outlined"
    />
  );
}

MChip.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(MChip);
