import React from 'react';
import { colors } from '../../components/mui/colors';
import { Col, Row } from 'react-flexbox-grid';
import { OverviewDetailBudgetHeaderItem } from './OverviewDetailBudgetHeaderItem';
import { OverviewDetailCategoryDaysHeader } from './OverviewDetailCategoryDaysHeader';
import { MCard } from '../mui/MCard';
import './styles.css';

export const OverviewDetailBudgetHeader = ({
  category,
  daysRemaining,
  budgeted,
  spentOrRemainingLabel,
  spentOrRemainingValue,
  spentOrRemainingColor,
  currencySymbol
}) => (
  <MCard style={{ marginBottom: '2vh' }}>
    <Row className="overview-detail-budget-header" center="xs" middle="xs">
      <Col xs={4} sm={4} md={4} lg={4}>
        <OverviewDetailCategoryDaysHeader category={category} daysRemaining={daysRemaining} />
      </Col>
      <Col xs={4} sm={4} md={4} lg={4}>
        <OverviewDetailBudgetHeaderItem
          label={spentOrRemainingLabel}
          value={spentOrRemainingValue}
          valueColor={spentOrRemainingColor}
          currencySymbol={currencySymbol}
        />
      </Col>
      <Col xs={4} sm={4} md={4} lg={4}>
        <OverviewDetailBudgetHeaderItem
          label={'BUDGETED'}
          value={budgeted}
          valueColor={colors.blackColor}
          currencySymbol={currencySymbol}
        />
      </Col>
    </Row>
  </MCard>
);
