import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { OverviewDetailCardHeaderAmount } from './OverviewDetailCardHeaderAmount';
import { SubCategoryAndTransactions } from './SubCategoryAndTransactions';

export const OverviewDetailCardHeader = ({
  subCategory,
  numTransactions,
  totalAmountLabel,
  totalAmountValue,
  totalAmountColor,
  currencySymbol
}) => (
  <Row center="xs">
    <Col xs={5} sm={5} md={5} lg={5}>
      <SubCategoryAndTransactions subCategory={subCategory} numTransactions={numTransactions} />
    </Col>
    <Col xs={5} sm={5} md={5} lg={5}>
      <OverviewDetailCardHeaderAmount
        totalAmountLabel={totalAmountLabel}
        totalAmountValue={totalAmountValue}
        totalAmountColor={totalAmountColor}
        currencySymbol={currencySymbol}
      />
    </Col>
  </Row>
);
