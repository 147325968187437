import { actionTypes } from '../../config/app-constants';
import { fetchTransactions, getAllBudgets, getMyCategories } from '../../db';
import { ACTIONS, analyticsEvent, CATEGORIES } from '../../analytics/events';
import { getRemainingDaysInMonth } from '../../containers/shared/util';
import { getOrderedSpendingsBySubCategory, getOverviewPayload } from '../../containers/shared/overviewWorkflow';

export function showOverviewDetail(
  category,
  isBudgetedCategory,
  isOverSpent,
  budgeted,
  spent,
  earned,
  transactions,
  subCategories
) {
  let orderedSpendingsBySubCategory = getOrderedSpendingsBySubCategory(transactions, subCategories);

  analyticsEvent(CATEGORIES.OVERVIEW_DETAIL, ACTIONS.OVERVIEW_DETAIL.VIEWED);

  return {
    type: actionTypes.overviewActionTypes.SHOW_OVERVIEW_DETAIL,
    value: {
      showDetailPage: true,
      showDetails: {
        category,
        isBudgetedCategory,
        isOverSpent,
        budgeted,
        spent,
        earned,
        spendingBySubCategory: orderedSpendingsBySubCategory
      }
    }
  };
}

export function hideOverviewDetail() {
  return {
    type: actionTypes.overviewActionTypes.SHOW_OVERVIEW_DETAIL,
    value: { showDetailPage: false }
  };
}

export function loadOverview(year, month, userId) {
  return function(dispatch) {
    return Promise.all([fetchTransactions(year, month, userId), getAllBudgets(userId), getMyCategories(userId)]).then(
      ([transactions, budgets, userCategories]) => {
        let payload = getOverviewPayload(transactions, budgets, userCategories);
        let todayDate = new Date().getDate();
        let requestedDate = new Date(new Date(year, month, todayDate));
        let remainingDaysInMonth = getRemainingDaysInMonth(requestedDate);
        let overviewPayload = Object.assign({}, payload, {
          overviewLoaded: true,
          remainingDaysInMonth,
          userCategories
        });
        dispatch(onOverviewLoadSuccess(overviewPayload));
      }
    );
  };
}

function onOverviewLoadSuccess(overviewPayload) {
  return {
    type: actionTypes.overviewActionTypes.OVERVIEW_LOADED_OK,
    value: overviewPayload
  };
}

export function onOverviewYearChange(selectedYear) {
  return {
    type: actionTypes.overviewActionTypes.CHANGE_OVERVIEW_YEAR,
    value: { selectedYear }
  };
}

export function onOverviewMonthChange(selectedMonth) {
  return {
    type: actionTypes.overviewActionTypes.CHANGE_OVERVIEW_MONTH,
    value: { selectedMonth }
  };
}
