import React from 'react';
import { AppRoot } from '../../components/AppRoot';
import { Routes } from '../../config/app-constants';
import { loginWithFacebook, loginWithGoogle, loginWithTwitter } from '../home/login/auth';
import { userIdFromCache } from '../shared/util';

export class Website extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showCookies: !localStorage.getItem('showCookies') };
  }

  componentWillMount(props) {
    if (userIdFromCache() !== null) {
      this.props.history.push(Routes.overview);
    }
  }

  onCloseCookies = () => {
    this.setState({ showCookies: false });
    localStorage.setItem('showCookies', 'false');
  };

  handleGoogleLogin = () => {
    loginWithGoogle().catch(function(error) {
      alert(error); // or show toast
    });
    this.props.history.push(Routes.welcome, { initiateAuthentication: true });
  };

  handleWithTwitter = () => {
    loginWithTwitter().catch(error => alert(error));
    this.props.history.push(Routes.welcome, { initiateAuthentication: true });
  };

  handleWithFacebook = () => {
    loginWithFacebook().catch(error => alert(error));
    this.props.history.push(Routes.welcome, { initiateAuthentication: true });
  };

  render() {
    return (
      <AppRoot
        loginWithGoogle={this.handleGoogleLogin}
        loginWithTwitter={this.handleWithTwitter}
        loginWithFacebook={this.handleWithFacebook}
        onCloseCookies={this.onCloseCookies}
        showCookies={this.state.showCookies}
      />
    );
  }
}
