import React from 'react';
import {Col, Row} from "react-flexbox-grid";
import './styles.css';

export const OverviewHeaderItem = ({label, value, valueColor}) => (
    <Row>
        <Col xs={12} sm={12} md={12} lg={12}
             style={{color: `${valueColor}`}}
             className='overview-status-item-value'>{value}</Col>
        <Col xs={12} sm={12} md={12} lg={12}
             className='overview-status-item-label'>{label}</Col>
    </Row>
);