import uuid from 'uuid';
import { isEmptyObject, isEmptyString, isValidAmount, isValidCurrencySymbol, userIdFromCache } from './util';
import {
  actionOnAddTransaction,
  actionOnEditTransaction,
  defaultTransactionName,
  transactionCategoryFieldToDelete
} from '../../config/app-constants';
import moment from 'moment';

// TODO Do not return undefined in case no match found. Always return {}
/**
 * Return the object if found for a given name
 * @param collection
 * @param item
 * @returns {*}
 */
export const findMatchByName = (collection, item) => {
  return collection.filter(eachItem => eachItem.name === item.name)[0];
};

/**
 * Return the object if found for a given Id
 * @param collection
 * @param id
 * @returns {*|{}}
 */
export const findMatchById = (collection, id) => {
  if (isEmptyString(id)) return {};
  return collection.filter(eachItem => eachItem.id === id)[0] || {};
};

/**
 * New subCategory object
 * @param name
 * @returns {{createdAt: Date, modifiedAt: Date, name: *, id}}
 */
export const constructNewSubCategory = name => {
  if (isEmptyString(name)) throw new Error('subCategory name cannot be empty');

  return {
    id: uuid(),
    name: name.trim(),
    createdAt: new Date(),
    modifiedAt: new Date()
  };
};

/**
 * Updated Category with changes
 * @param selectedCategory
 * @param subCategories
 * @param subCategoriesEnabled
 * @returns {{subCategoriesEnabled: *, modifiedAt: Date, id: *, subCategories: *}}
 */
export const getUpdatedCategory = (selectedCategory, subCategories, subCategoriesEnabled) => {
  // todo[mb-186]: can subCategories be empty from application perspective? need information to write tests - Yes
  // todo[mb-186]: we should pass selectedCategory.id directly so that function is unaware of its shape
  return {
    id: selectedCategory.id,
    subCategories: subCategories,
    subCategoriesEnabled: subCategoriesEnabled,
    modifiedAt: new Date()
  };
};

/**
 * New Category Object
 * @param name
 * @param subCategories
 * @param subCategoriesEnabled
 * @param createdByUser
 * @param userId
 * @returns {{subCategoriesEnabled: (*|boolean), createdAt: Date, createdByUser: *, modifiedAt: Date, name: *, userId: *, subCategories: (*|Array)}}
 */
export const constructNewCategory = (
  name,
  subCategories,
  subCategoriesEnabled,
  createdByUser,
  userId = userIdFromCache()
) => {
  if (isEmptyString(name)) throw new Error('category name cannot be empty');

  return {
    name: name.trim(),
    userId: userId,
    subCategories: subCategories || [],
    subCategoriesEnabled: subCategoriesEnabled,
    createdAt: new Date(),
    modifiedAt: new Date(),
    createdByUser: createdByUser
  };
};

/**
 * Are there any changes in selectedCategory
 * @param selectedCategory
 * @param subCategories
 * @param subCategoriesEnabled
 * @returns {boolean|*}
 */
export const shouldEditCategory = (selectedCategory, subCategories, subCategoriesEnabled) => {
  if (isEmptyObject(selectedCategory)) return false;
  let isDisabled = selectedCategory.subCategoriesEnabled && !subCategoriesEnabled;
  let isNewSubCategory = subCategoriesEnabled && selectedCategory.subCategories.length < subCategories.length;
  return isDisabled || isNewSubCategory;
};

/**
 * New Transaction
 * @param name
 * @param amount
 * @param transactionDate
 * @param isExpense
 * @param currencyName
 * @param selectedCategory
 * @param enableSubCategory
 * @param selectedSubCategory
 * @param userId
 * @returns {{date: *, createdAt: Date, amount: *, name: *, isExpense: *, currency: *, lastModified: Date, userId: *}}
 */
export const constructNewTransaction = (
  name,
  amount,
  transactionDate,
  isExpense,
  currencyName,
  selectedCategory,
  enableSubCategory,
  selectedSubCategory,
  userId = userIdFromCache()
) => {
  validateTransactionInputs(userId, amount, transactionDate, currencyName);

  let transaction = {
    name: isEmptyString(name) ? defaultTransactionName : name.trim(),
    amount: amount,
    date: transactionDate,
    isExpense: isExpense,
    currency: currencyName,
    createdAt: new Date(),
    lastModified: new Date(),
    userId: userId
  };
  if (!isEmptyObject(selectedCategory)) {
    transaction.categoryId = selectedCategory.id;
  }
  if (enableSubCategory && !isEmptyObject(selectedSubCategory)) {
    transaction.subCategoryId = selectedSubCategory.id;
  }
  return transaction;
};

let validateTransactionInputs = (userId, amount, transactionDate, currencyName) => {
  if (isEmptyString(userId)) throw new Error('userId must be provided');
  if (!isValidAmount(amount)) throw new Error('amount must be greater than zero');
  if (!moment.isDate(transactionDate)) throw new Error('transactionDate must be a valid date');
  if (!isValidCurrencySymbol(currencyName)) throw new Error('currencyName must be valid');
};

/**
 * Updated transaction with changes
 * @param id
 * @param name
 * @param amount
 * @param transactionDate
 * @param isExpense
 * @param currencyName
 * @param selectedCategory
 * @param selectedSubCategory
 * @param userId
 * @returns {{date: *, amount: *, name: *, isExpense: *, currency: *, id: *, lastModified: Date, userId: *}}
 */
export const constructUpdatedTransaction = (
  id,
  name,
  amount,
  transactionDate,
  isExpense,
  currencyName,
  selectedCategory,
  selectedSubCategory,
  userId = userIdFromCache()
) => {
  if (isEmptyString(id)) throw new Error('transaction id must be provided');
  validateTransactionInputs(userId, amount, transactionDate, currencyName);

  let transaction = {
    id: id,
    name: isEmptyString(name) ? defaultTransactionName : name.trim(),
    amount: amount,
    date: transactionDate,
    isExpense: isExpense,
    currency: currencyName,
    lastModified: new Date(),
    userId: userId
  };
  if (!isEmptyObject(selectedCategory)) {
    transaction.categoryId = selectedCategory.id;
  }
  if (!isEmptyObject(selectedSubCategory)) {
    transaction.subCategoryId = selectedSubCategory.id;
  }
  return transaction;
};

/**
 * What action to perform when user Adds transaction
 * @param userCategories
 * @param selectedCategory
 * @param subCategories
 * @param subCategoriesEnabled
 * @param suggestedCategories
 * @returns {string}
 */
export const getActionOnSubmit = (
  userCategories,
  selectedCategory,
  subCategories,
  subCategoriesEnabled,
  suggestedCategories
) => {
  //------SUGGESTED category selected or NEW category added-----//
  let isSuggested = isSuggestedCategory(suggestedCategories, selectedCategory.id);
  let isNewCategoryAdded = isNewCategory(selectedCategory);
  if (isSuggested || isNewCategoryAdded) return actionOnAddTransaction.addCategoryAddTransaction;

  //----Existing category either with new sub-category OR subCategories disabled----//
  let isCategoryEdited = shouldEditCategory(selectedCategory, subCategories, subCategoriesEnabled);
  if (isCategoryEdited) return actionOnAddTransaction.editCategoryAddTransaction;

  return actionOnAddTransaction.onlyAddTransaction;
};

/**
 * Return true if given categoryId is found in suggested categories
 * @param suggestedCategories
 * @param categoryId
 * @returns {boolean}
 */
export const isSuggestedCategory = (suggestedCategories, categoryId) => {
  if (isEmptyString(categoryId) || isEmptyObject(suggestedCategories)) return false;
  let suggested = findMatchById(suggestedCategories, categoryId);
  return !isEmptyObject(suggested);
};

/**
 * Return true if given category is a new Category
 * @param selectedCategory
 * @returns {boolean}
 */
export const isNewCategory = selectedCategory => {
  if (isEmptyObject(selectedCategory)) return false;
  return isEmptyString(selectedCategory.id);
};

/**
 * Return true if given category is not a userCategory
 * @param userCategories
 * @param categoryId
 * @returns {boolean}
 */
export const isMyCategory = (userCategories, categoryId) => {
  if (isEmptyString(categoryId) || isEmptyObject(userCategories)) return false;
  let myCategory = findMatchById(userCategories, categoryId);
  return !isEmptyObject(myCategory);
};

/**
 * Get SubCategory object from a given Category
 * @param category
 * @param subCategoryId
 * @returns {*}
 */
export const getSubCategoryForId = (category, subCategoryId) => {
  if (isEmptyObject(category) || isEmptyString(subCategoryId)) return {};
  return category.subCategories.filter(subCategory => subCategory.id === subCategoryId)[0] || {};
};

/**
 * Is categoryId and subCategoryId missing
 * @param categoryId
 * @param subCategoryId
 * @returns {boolean}
 */
export const isCategoryZeroLevel = (categoryId, subCategoryId) => {
  return isEmptyString(categoryId) && isEmptyString(subCategoryId);
};

/**
 * Should SAVE button be disabled
 * @param existingTransaction
 * @param reviewState
 * @returns {*}
 */
export const shouldDisableSaveButton = (existingTransaction, reviewState) => {
  // ---- New Transaction Case ---- //
  let isNewTransaction = t => isEmptyObject(t);
  let isInvalidNameOrAmount = (name, amount) => isEmptyString(name) || !isValidAmount(amount);

  if (isNewTransaction(existingTransaction)) return isInvalidNameOrAmount(reviewState.name, reviewState.amount);

  // ---- Edit Transaction Case ---- //
  let isZeroToZeroAndNoNewCategory = (eT, rS) => isEmptyString(eT.categoryId) && isEmptyObject(rS.selectedCategory);

  let isOneToOneSameCategory = (eT, rS) =>
    !isEmptyString(eT.categoryId) &&
    isEmptyString(eT.subCategoryId) &&
    !isEmptyObject(rS.selectedCategory) &&
    eT.categoryId === rS.selectedCategory.id &&
    isEmptyString(rS.selectedSubCategory.id);

  let isTwoToTwoSameCategoryAndSubCategory = (eT, rS) =>
    !isEmptyString(eT.categoryId) &&
    !isEmptyString(eT.subCategoryId) &&
    !isEmptyObject(rS.selectedSubCategory) &&
    !isEmptyObject(rS.selectedSubCategory) &&
    eT.categoryId === rS.selectedCategory.id &&
    eT.subCategoryId === rS.selectedSubCategory.id;

  let isOneOrTwoToZero = (eT, rS) => !isEmptyString(eT.categoryId) && isEmptyObject(rS.selectedCategory);

  let currentTransactionName = reviewState.name.trim();
  let currentTransactionAmount = reviewState.amount;

  if (isInvalidNameOrAmount(currentTransactionName, currentTransactionAmount)) return true;

  let existingTransactionName = existingTransaction.name;
  let existingTransactionAmount = existingTransaction.amount;
  let existingTransactionDate = moment(existingTransaction.date).format('MMM D, YYYY');
  let reviewStateTransactionDate = moment(reviewState.transactionDate).format('MMM D, YYYY');

  if (
    currentTransactionName === existingTransactionName &&
    currentTransactionAmount === existingTransactionAmount &&
    existingTransactionDate === reviewStateTransactionDate &&
    reviewState.isExpense === existingTransaction.isExpense &&
    (isZeroToZeroAndNoNewCategory(existingTransaction, reviewState) ||
      isOneToOneSameCategory(existingTransaction, reviewState) ||
      isTwoToTwoSameCategoryAndSubCategory(existingTransaction, reviewState) ||
      isOneOrTwoToZero(existingTransaction, reviewState))
  ) {
    return true;
  }

  return false;
};

/**
 * Adapted from shouldDisableSave button for AddEdit/index.js for the Edit Transaction use case.
 * This is so that if the remote and local transactions are exactly same, no DB call should be made.
 * It has been modified a bit since in AddEdit/index.js, local and remote transactions have similar structures,
 * so finding ids or keys follow the identical path unlike previous implementation.
 * @param {remote} remote represents the transaction on the server (read-only)
 * @param {local} local represents the transaction data in edited mode (by the user)
 */
export const isLocalAndRemoteTransactionSame = (remote, local) => {
  let isZeroToZeroAndNoNewCategory = (eT, rS) => isEmptyString(eT.categoryId) && isEmptyObject(rS.selectedCategory);

  let isOneToOneSameCategory = (r, l) =>
    !isEmptyString(r.selectedCategory.id) &&
    isEmptyString(r.selectedSubCategory.id) &&
    !isEmptyObject(l.selectedCategory) &&
    r.selectedCategory.id === l.selectedCategory.id &&
    isEmptyString(l.selectedSubCategory.id);

  let isTwoToTwoSameCategoryAndSubCategory = (r, l) =>
    !isEmptyString(r.selectedCategory.id) &&
    !isEmptyString(r.selectedSubCategory.id) &&
    !isEmptyObject(l.selectedSubCategory) &&
    !isEmptyObject(l.selectedSubCategory) &&
    r.selectedCategory.id === l.selectedCategory.id &&
    r.selectedSubCategory.id === l.selectedSubCategory.id;

  let isOneOrTwoToZero = (r, l) => !isEmptyString(r.selectedCategory.id) && isEmptyObject(l.selectedCategory);

  let currentTransactionName = local.name.trim();
  let currentTransactionAmount = local.amount;

  let existingTransactionName = remote.name;
  let existingTransactionAmount = remote.amount;
  let existingTransactionDate = moment(remote.transactionDate).format('MMM D, YYYY');
  let reviewStateTransactionDate = moment(local.transactionDate).format('MMM D, YYYY');

  if (
    currentTransactionName === existingTransactionName &&
    currentTransactionAmount === existingTransactionAmount &&
    existingTransactionDate === reviewStateTransactionDate &&
    local.isExpense === remote.isExpense &&
    (isZeroToZeroAndNoNewCategory(remote, local) ||
      isOneToOneSameCategory(remote, local) ||
      isTwoToTwoSameCategoryAndSubCategory(remote, local) ||
      isOneOrTwoToZero(remote, local))
  ) {
    return true;
  }
  return false;
};

/**
 * Should DONE button on Amount screen be disabled
 * @param existingTransaction
 * @param reviewStateAmount
 * @param reviewStateIsExpense
 * @returns {boolean}
 */
export const shouldDisableAmountDone = (existingTransaction, reviewStateAmount, reviewStateIsExpense) => {
  if (isEmptyObject(existingTransaction)) {
    return !isValidAmount(reviewStateAmount);
  }
  return (
    (existingTransaction.amount === reviewStateAmount && existingTransaction.isExpense === reviewStateIsExpense) ||
    !isValidAmount(reviewStateAmount)
  );
};

/**
 * Returns true if oldCategoryId is changed
 * @param oldCategoryId
 * @param selectedCategoryId
 * @returns {boolean}
 */
export const isCategoryChanged = (oldCategoryId, selectedCategoryId) => {
  return oldCategoryId !== selectedCategoryId;
};

/**
 * Returns true if oldSubcategoryId is changed
 * @param oldSubcategoryId
 * @param selectedSubCategoryId
 * @returns {boolean}
 */
export const isSubCategoryChanged = (oldSubcategoryId, selectedSubCategoryId) => {
  return oldSubcategoryId !== selectedSubCategoryId;
};

/**
 * Returns true if oldSubCategoryId should be deleted from db
 * @param oldSubCategoryId
 * @param selectedSubCategoryId
 * @returns {boolean|*}
 */
export const shouldDeleteSubCategoryId = (oldSubCategoryId, selectedSubCategoryId) => {
  return !selectedSubCategoryId && !isEmptyString(oldSubCategoryId);
};

export const shouldDeleteCategoryId = (oldCategoryId, selectedCategoryId) => {
  return !selectedCategoryId && (oldCategoryId !== 'undefined' || oldCategoryId !== null);
};

/**
 * Returns Action to be performed when a transaction is updated.
 * @param userCategories
 * @param selectedCategory
 * @param selectedSubCategory
 * @param subCategories
 * @param subCategoriesEnabled
 * @param suggestedCategories
 * @returns {string}
 */
export const getActionOnEditTransaction = (
  userCategories,
  selectedCategory,
  selectedSubCategory,
  subCategories,
  subCategoriesEnabled,
  suggestedCategories
) => {
  let isSelectedCategoryEmpty = isEmptyObject(selectedCategory);
  let isSuggested = isSuggestedCategory(suggestedCategories, selectedCategory.id);

  //----Suggested category selected----//
  if (isSuggested) return actionOnEditTransaction.addCategoryEditTransaction;

  //----New category created----//
  if (isNewCategory(selectedCategory)) return actionOnEditTransaction.addCategoryEditTransaction;

  //----Existing category selected with either new sub-category OR subCategories disabled----//
  let isExistingCategory = isMyCategory(userCategories, selectedCategory.id);
  let categoryIsEdited = shouldEditCategory(selectedCategory, subCategories, subCategoriesEnabled);
  if (!isSelectedCategoryEmpty && isExistingCategory && categoryIsEdited)
    return actionOnEditTransaction.editCategoryEditTransaction;

  return actionOnEditTransaction.onlyEditTransaction;
};

/**Return transaction category attribute to delete when user updates category
 * @param deleteCategoryId
 * @param deleteSubCategoryId
 * @returns {string}
 */
export const getTransactionCategoryFieldToDelete = (deleteCategoryId, deleteSubCategoryId) => {
  if (deleteCategoryId) return transactionCategoryFieldToDelete.ALL_CATEGORY_FIELDS;
  if (deleteSubCategoryId) return transactionCategoryFieldToDelete.SUBCATEGORY_ONLY;
  return transactionCategoryFieldToDelete.NO_DELETE;
};
