import React from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import AmountScreen from '../../../Transactions/Add/AmountScreen';

export const BudgetAmount = ({
  shouldOpenAmount,
  onOpenBudgetAmount,
  onCloseBudgetAmount,
  budgetedAmount,
  onAmountDone
}) => {
  return (
    <>
      <Card style={styles.amountStyles}>
        <CardHeader subheader="AMOUNT" />
        <Row className="selected-budget-category-container" onClick={onOpenBudgetAmount}>
          <Col xs={12} sm={12} md={12} lg={12}>
            {budgetedAmount}
          </Col>
        </Row>
      </Card>
      <AmountScreen
        amount={budgetedAmount}
        open={shouldOpenAmount}
        onNextClick={onAmountDone}
        onCloseClick={onCloseBudgetAmount}
      />
    </>
  );
};

const styles = {
  amountStyles: {
    height: '18vh'
  }
};
