import React, { Component } from 'react';
import MonthYear from '../../../components/MonthYear';
import { getCurrencySymbol, isEmptyObject, userIdFromCache } from '../../shared/util';
import { connect } from 'react-redux';
import * as AppFooterActions from '../../../actions/app/appFooterActions';
import * as ListTransactionActions from '../../../actions/transactions/listTransactionActions';
import { bindActionCreators } from 'redux';
import App from '../../app';
import { Transaction } from '../../../components/Transactions/List';
import { withRouter } from 'react-router';
import Onboarding from '../../onboarding';
import * as AppHeaderActions from '../../../actions/app/appHeaderActions';
import { Placeholder } from '../../../components/Placeholder';
import { emptyComponents } from '../../../config/app-constants';
import { MLinearProgress } from '../../../components/mui/MLinearProgress';
import TransactionDetail from '../../../components/Transactions/List/TransactionDetail';

class Transactions extends Component {
  fetchTransactions(year, month, userId) {
    this.props.loadTransactions(year, month, userId);
  }

  componentWillMount() {
    this.props.highlightTransaction();
  }

  componentDidMount() {
    let { selectedYear, selectedMonth } = this.props.state;
    this.fetchTransactions(selectedYear, selectedMonth, userIdFromCache());
  }

  onYearChange = event => {
    let selectedYear = event.target.value;
    this.props.changeTransactionYear(selectedYear);
    this.fetchTransactions(selectedYear, this.props.state.selectedMonth, userIdFromCache());
  };

  onMonthChange = event => {
    let selectedMonth = event.target.value;
    this.props.changeTransactionMonth(selectedMonth);
    this.fetchTransactions(this.props.state.selectedYear, selectedMonth, userIdFromCache());
  };

  onTransactionClick = transaction => {
    this.props.onTransactionSelected(Object.assign({ id: transaction.id }, transaction));
  };

  getSymbol = () => {
    return getCurrencySymbol(this.props.currencies.defaultCurrency.shortName);
  };

  render() {
    let showEmptyTransaction = this.props.state.transactionsLoaded && isEmptyObject(this.props.state.transactions);

    return (
      <div>
        {!this.props.state.showTransactionDetail && (
          <TransactionList
            showAvatar={true}
            selectedYear={this.props.state.selectedYear}
            selectedMonth={this.props.state.selectedMonth}
            onYearChange={this.onYearChange}
            onMonthChange={this.onMonthChange}
            onTransactionClick={this.onTransactionClick}
            transactions={this.props.state.transactions}
            transactionsLoaded={this.props.state.transactionsLoaded}
            showEmptyTransaction={showEmptyTransaction}
            currencySymbol={this.getSymbol()}
          />
        )}
        {this.props.state.showTransactionDetail && (
          <TransactionDetail
            existingTransaction={this.props.state.selectedTransaction}
            onBackToTransactions={this.props.backToTransactionList}
          />
        )}
        {this.props.appHeaderState.showOnBoard && (
          <Onboarding showOnBoard={this.props.appHeaderState.showOnBoard} onSkip={this.props.onCloseOnBoarding} />
        )}
      </div>
    );
  }
}

const TransactionList = ({
  showAvatar,
  selectedYear,
  selectedMonth,
  onYearChange,
  onMonthChange,
  onTransactionClick,
  transactions,
  transactionsLoaded,
  showEmptyTransaction,
  currencySymbol
}) => (
  <App showAvatar={showAvatar} title={'Transactions'}>
    <MonthYear year={selectedYear} month={selectedMonth} onYearChange={onYearChange} onMonthChange={onMonthChange} />

    {!transactionsLoaded && <MLinearProgress />}

    {transactions.map((transaction, index) => (
      <Transaction
        key={index}
        onTransactionClick={onTransactionClick}
        transaction={transaction}
        currencySymbol={currencySymbol}
      />
    ))}

    {showEmptyTransaction && (
      <Placeholder
        title={emptyComponents.transactions.title}
        text={emptyComponents.transactions.text}
        image={emptyComponents.transactions.image}
      />
    )}
  </App>
);

let mapStateToProps = state => {
  return {
    state: state.transactionReducer,
    currencies: state.currencyReducer,
    appHeaderState: state.appHeaderReducer
  };
};

let mapDispatchToProps = dispatch => {
  return {
    highlightTransaction: bindActionCreators(AppFooterActions.highlightTransactions, dispatch),
    loadTransactions: bindActionCreators(ListTransactionActions.loadTransactions, dispatch),
    changeTransactionMonth: bindActionCreators(ListTransactionActions.changeListTransactionMonth, dispatch),
    changeTransactionYear: bindActionCreators(ListTransactionActions.changeListTransactionYear, dispatch),
    onTransactionSelected: bindActionCreators(ListTransactionActions.onTransactionSelected, dispatch),
    backToTransactionList: bindActionCreators(ListTransactionActions.backToTransactionList, dispatch),
    onCloseOnBoarding: bindActionCreators(AppHeaderActions.closeOnBoarding, dispatch)
  };
};

const TransactionsWithRouter = withRouter(Transactions);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsWithRouter);
