import React from 'react';
import {ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {lightBlue} from "@material-ui/core/colors";
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import Typography from "@material-ui/core/Typography/Typography";

export const MListItem = ({onClick, img, primaryText, secondaryText, showRightArrow = false}) => (
    <ListItem button onClick={onClick}>
        <img src={img} alt='' height={40}/>
        <ListItemText disableTypography
                      primary={getTypography('body2', lightBlue["900"], primaryText)}
                      secondary={getTypography('caption', 'gray', secondaryText)}/>
        {showRightArrow && <RightArrow/>}
    </ListItem>
);


const getTypography = (variant, color, text) => (
    <Typography variant={variant} style={{color: color}}>{text}</Typography>
);