export const currencyCodeToName = {
  WST: 'Samoan tala',
  HUF: 'Hungarian forints',
  CUP: 'Cuban pesos',
  FKP: 'Falkland Islands pounds',
  BMD: 'Bermudan dollars',
  CAD: 'Canadian dollars',
  MKD: 'Macedonian denari',
  TTD: 'Trinidad & Tobago dollars',
  ILS: 'Israeli new shekels',
  GTQ: 'Guatemalan quetzals',
  GBP: 'British pounds',
  CHF: 'Swiss francs',
  HNL: 'Honduran lempiras',
  ARS: 'Argentine pesos',
  MYR: 'Malaysian ringgits',
  XOF: 'West African CFA francs',
  TOP: 'Tongan paʻanga',
  PHP: 'Philippine pisos',
  HKD: 'Hong Kong dollars',
  TJS: 'Tajikistani somonis',
  DOP: 'Dominican pesos',
  JPY: 'Japanese yen',
  LTL: 'Lithuanian litai',
  JOD: 'Jordanian dinars',
  BZD: 'Belize dollars',
  UGX: 'Ugandan shillings',
  CDF: 'Congolese francs',
  MWK: 'Malawian kwachas',
  MZN: 'Mozambican meticals',
  NAD: 'Namibian dollars',
  GIP: 'Gibraltar pounds',
  EGP: 'Egyptian pounds',
  KPW: 'North Korean won',
  THB: 'Thai baht',
  UAH: 'Ukrainian hryvnias',
  TRY: 'Turkish Lira',
  IDR: 'Indonesian rupiahs',
  UYU: 'Uruguayan pesos',
  SBD: 'Solomon Islands dollars',
  NOK: 'Norwegian kroner',
  TWD: 'New Taiwan dollars',
  AED: 'UAE dirhams',
  XCD: 'East Caribbean dollars',
  AUD: 'Australian dollars',
  BSD: 'Bahamian dollars',
  NGN: 'Nigerian nairas',
  KHR: 'Cambodian riels',
  SRD: 'Surinamese dollars',
  PGK: 'Papua New Guinean kina',
  MDL: 'Moldovan lei',
  QAR: 'Qatari rials',
  MUR: 'Mauritian rupees',
  ISK: 'Icelandic krónur',
  KES: 'Kenyan shillings',
  UZS: 'Uzbekistani som',
  KWD: 'Kuwaiti dinars',
  KGS: 'Kyrgystani soms',
  SYP: 'Syrian pounds',
  BOB: 'Bolivian bolivianos',
  AWG: 'Aruban florin',
  AZN: 'Azerbaijani manats',
  BIF: 'Burundian francs',
  NZD: 'New Zealand dollars',
  CRC: 'Costa Rican colóns',
  INR: 'Indian rupees',
  RSD: 'Serbian dinars',
  CNY: 'Chinese yuan',
  LAK: 'Laotian kips',
  PLN: 'Polish zlotys',
  MXN: 'Mexican pesos',
  HTG: 'Haitian gourdes',
  PYG: 'Paraguayan guaranis',
  BTN: 'Bhutanese ngultrums',
  BAM: 'Bosnia-Herzegovina convertible marks',
  BBD: 'Barbadian dollars',
  NIO: 'Nicaraguan córdobas',
  SZL: 'Swazi emalangeni',
  SHP: 'St. Helena pounds',
  SOS: 'Somali shillings',
  RUB: 'Russian rubles',
  EUR: 'euros',
  VEF: 'Venezuelan bolívars',
  ANG: 'Netherlands Antillean guilders',
  MNT: 'Mongolian tugriks',
  COP: 'Colombian pesos',
  ZAR: 'South African rand',
  USD: 'US dollars',
  BRL: 'Brazilian reals',
  RWF: 'Rwandan francs',
  LBP: 'Lebanese pounds',
  SVC: 'Salvadoran colones',
  BGN: 'Bulgarian leva',
  BND: 'Brunei dollars',
  RON: 'Romanian lei',
  KYD: 'Cayman Islands dollars',
  SLL: 'Sierra Leonean leones',
  DJF: 'Djiboutian francs',
  TND: 'Tunisian dinars',
  SEK: 'Swedish kronor',
  DZD: 'Algerian dinars',
  OMR: 'Omani rials',
  SGD: 'Singapore dollars',
  LYD: 'Libyan dinars',
  MMK: 'Myanmar kyats',
  TZS: 'Tanzanian shillings',
  CVE: 'Cape Verdean escudos',
  IQD: 'Iraqi dinars',
  SAR: 'Saudi riyals',
  CLP: 'Chilean pesos',
  ERN: 'Eritrean nakfas',
  XPF: 'CFP francs',
  KRW: 'South Korean won',
  GEL: 'Georgian laris',
  XAF: 'Central African CFA francs',
  VND: 'Vietnamese dong',
  AFN: 'Afghan Afghanis',
  FJD: 'Fijian dollars',
  MRO: 'Mauritanian ouguiyas (1973–2017)',
  PKR: 'Pakistani rupees',
  HRK: 'Croatian kunas',
  SCR: 'Seychellois rupees',
  YER: 'Yemeni rials',
  BHD: 'Bahraini dinars',
  MGA: 'Malagasy ariaries',
  CZK: 'Czech korunas',
  BDT: 'Bangladeshi takas',
  NPR: 'Nepalese rupees',
  KZT: 'Kazakhstani tenges',
  GMD: 'Gambian dalasis',
  KMF: 'Comorian francs',
  MAD: 'Moroccan dirhams',
  PEN: 'Peruvian soles',
  ETB: 'Ethiopian birrs',
  VUV: 'Vanuatu vatus',
  MVR: 'Maldivian rufiyaas',
  JMD: 'Jamaican dollars',
  GYD: 'Guyanaese dollars',
  LKR: 'Sri Lankan rupees',
  GNF: 'Guinean francs',
  STD: 'São Tomé & Príncipe dobras (1977–2017)',
  GHS: 'Ghanaian cedis',
  DKK: 'Danish kroner',
  MOP: 'Macanese patacas',
  IRR: 'Iranian rials'
};
