import {actionTypes} from "../../config/app-constants";

let defaultFeedbackState = {
    feedbackSubmitted: false,
    description: '',
};

export default function(state=defaultFeedbackState, action){
    switch(action.type){
        case actionTypes.feedbackActionTypes.SUBMIT_FEEDBACK:
            return Object.assign({}, state, action.value);
        case actionTypes.feedbackActionTypes.SUBMITTED_OK:
            return Object.assign({}, state, action.value);
        case actionTypes.feedbackActionTypes.ON_DESCRIPTION_CHANGE:
            return Object.assign({}, state, action.value);
        default:
            return state
    }
}