import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CurrencyActions from '../../actions/settings/currencyActions';
import { CurrencySettings } from '../../components/CurrencySettings/index';
import App from '../app';
import { applicationVersion, Routes } from '../../config/app-constants';
import './styles.css';

class Settings extends Component {
  componentWillMount() {
    this.props.loadDefaultCurrency();
    this.props.loadAllCurrencies();
  }

  backToSettings = () => {
    this.props.history.push(Routes.more);
  };

  // onCurrencySearch = searchTerm => {
  //   if (searchTerm.trim().length > 0) this.props.onSearch(searchTerm, this.props.currencies.allCurrencies);
  //   else this.props.loadAllCurrencies();
  // };
  onCurrencySearch = searchTerm => {
    this.props.onSearch(searchTerm, this.props.currencies.allCurrencies);
  };

  render() {
    return (
      <App onBackButtonClick={this.backToSettings} title={'Settings'} hideInfoIcon={true}>
        <CurrencySettings
          defaultCurrency={this.props.currencies.defaultCurrency}
          shouldRenderAllCurrencies={this.props.currencies.shouldRenderAllCurrencies}
          saveDefaultCurrency={this.props.saveDefaultCurrency}
          showAllCurrencies={this.props.showAllCurrenciesAction}
          closeAllCurrencies={this.props.closeAllCurrenciesAction}
          allCurrencies={this.props.currencies.filteredCurrencies}
          onSearch={this.onCurrencySearch}
          searchTerm={this.props.currencies.searchTerm}
        />
        <div className="app-version">v {applicationVersion}</div>
        <a className="privacy-style" href={Routes.privacy}>
          Privacy Policy
        </a>
        <a className="terms-style" href={Routes.terms}>
          Terms
        </a>
      </App>
    );
  }
}

function mapStateToProps(state) {
  return {
    currencies: state.currencyReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDefaultCurrency: bindActionCreators(CurrencyActions.loadDefaultCurrency, dispatch),
    loadAllCurrencies: bindActionCreators(CurrencyActions.loadAllCurrencies, dispatch),
    showAllCurrenciesAction: bindActionCreators(CurrencyActions.showAllCurrencies, dispatch),
    closeAllCurrenciesAction: bindActionCreators(CurrencyActions.closeAllCurrencies, dispatch),
    saveDefaultCurrency: bindActionCreators(CurrencyActions.saveDefaultCurrency, dispatch),
    onSearch: bindActionCreators(CurrencyActions.onSearchCurrency, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
