import React from 'react';
import { InputBox } from '../../InputBox';
import { Row, Col } from 'react-flexbox-grid';
import Switch from '@material-ui/core/Switch';
import { colors } from '../../mui/colors';
import { MTextField } from '../../mui/MTextField';
import Button from '@material-ui/core/Button/Button';
import { withStyles } from '@material-ui/core';
import './styles.css';
import Divider from '@material-ui/core/Divider';
import RemoveButton from '@material-ui/icons/RemoveCircleOutline';
import { MButton } from '../../mui/MButton';

export const CreateCategory = ({
  categoryName,
  onCategoryChange,
  enableSubCategory,
  onEnableSubCategory,
  onAddSubCategory,
  subCategoryName,
  onSubCategoryChange,
  onClickActionButton,
  disableActionButton,
  actionButtonLabel,
  onRemoveSubCategory,
  subCategoryTitle,
  subCategories,
  disableAddSubCategoryButton,
  showSubCategorySwitch,
  showSubCategoryBoxOnly,
  disableCategory
}) => {
  return (
    <>
      <CategoryInputBox
        title={'CATEGORY'}
        categoryName={categoryName}
        disableCategory={disableCategory}
        onCategoryChange={onCategoryChange}
      />

      {showSubCategoryBoxOnly && (
        <SubCategoryInputBox
          title={subCategoryTitle || 'SUB-CATEGORY'}
          subCategoryName={subCategoryName}
          onSubCategoryChange={onSubCategoryChange}
        />
      )}

      {enableSubCategory && (
        <SubCategoryInputBoxWithButton
          title={subCategoryTitle || 'SUB-CATEGORY'}
          subCategoryName={subCategoryName}
          onAddSubCategory={onAddSubCategory}
          onSubCategoryChange={onSubCategoryChange}
          disableAddButton={disableAddSubCategoryButton}
        />
      )}

      {showSubCategorySwitch && (
        <EnableSubCategory enableSubCategory={enableSubCategory} onEnable={onEnableSubCategory} />
      )}

      {enableSubCategory && <Divider light />}

      {enableSubCategory && (
        <AddedSubCategories
          subCategory={subCategoryName}
          onRemoveSubCategory={onRemoveSubCategory}
          subCategories={subCategories}
        />
      )}

      <ActionButton
        onClickActionButton={onClickActionButton}
        disableActionButton={disableActionButton}
        actionButtonLabel={actionButtonLabel}
      />
    </>
  );
};

export const CategoryInputBox = ({ title, categoryName, onCategoryChange, disableCategory }) => {
  return (
    <InputBox title={title}>
      <MTextField
        name="category-name"
        value={categoryName}
        style={{ fontSize: '80%' }}
        disabled={disableCategory}
        onChange={event => onCategoryChange(event.target.value)}
      />
    </InputBox>
  );
};

export const SubCategoryInputBox = ({ title, subCategoryName, onSubCategoryChange }) => {
  return (
    <div className="input-box-sc">
      <div className="input-box-sc-title-name">
        {title.toUpperCase()}
        <MTextField
          name="category-name"
          style={{ width: '40vw', fontSize: '80%' }}
          value={subCategoryName}
          onChange={event => onSubCategoryChange(event.target.value)}
        />
      </div>
    </div>
  );
};

export const SubCategoryInputBoxWithButton = ({
  title,
  subCategoryName,
  onSubCategoryChange,
  onAddSubCategory,
  disableAddButton
}) => {
  return (
    <div className="input-box-sc">
      <div className="input-box-sc-title-name">
        {title.toUpperCase()}
        <MTextField
          name="category-name"
          style={{ width: '40vw', fontSize: '80%' }}
          value={subCategoryName}
          onChange={event => onSubCategoryChange(event.target.value)}
        />
      </div>
      <div style={{ marginLeft: '18vw' }}>
        <StyledAddButton size="medium" variant="contained" disabled={disableAddButton} onClick={onAddSubCategory}>
          <div style={{ color: colors.primaryTextColor }}>ADD</div>
        </StyledAddButton>
      </div>
    </div>
  );
};

const styles = {
  contained: {
    backgroundColor: colors.primary3Color,
    minHeight: '10vh',
    minWidth: '20vw'
  }
};
export const StyledAddButton = withStyles(styles)(Button);

export const EnableSubCategory = ({ enableSubCategory, onEnable }) => {
  return (
    <Row style={{ marginTop: '5vh' }}>
      <Col xs={12}>
        <Row around="xs">
          <Col xs={6} style={{ marginTop: '2vh', fontSize: '60%', fontWeight: 'bold', color: '#4A4A4A' }}>
            ENABLE SUB-CATEGORY
          </Col>
          <Col xs={2}>
            <Switch checked={enableSubCategory} onChange={e => onEnable(e.target.checked)} value="enableSubCategory" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const AddedSubCategories = ({ onRemoveSubCategory, subCategories }) => {
  return (
    <div>
      <div style={{ marginTop: '2vh', marginLeft: '4vw', fontSize: '60%', fontWeight: 'bold', color: '#9B9B9B' }}>
        SUB-CATEGORIES
      </div>
      {subCategories &&
        subCategories.map((subCategory, key) => (
          <SubCategoryRow onRemoveSubCategory={onRemoveSubCategory} subCategory={subCategory} key={key} />
        ))}
    </div>
  );
};

export const SubCategoryRow = ({ onRemoveSubCategory, subCategory }) => {
  return (
    <Row>
      {!subCategory.deletedAt && (
        <Col xs={8} style={{ marginTop: '3vh', paddingLeft: '6vw', fontSize: '70%', color: '#4A4A4A' }}>
          {subCategory.name}
        </Col>
      )}
      {!subCategory.deletedAt && (
        <Col xs={4} style={{ marginTop: '2vh', paddingLeft: '18vw' }}>
          <MButton variant="text" onClick={() => onRemoveSubCategory(subCategory)}>
            <RemoveButton />
          </MButton>
        </Col>
      )}
    </Row>
  );
};

export const ActionButton = ({ onClickActionButton, disableActionButton, actionButtonLabel }) => {
  const color = actionButtonLabel === 'SAVE' ? colors.primary5Color : colors.primary3Color;
  return (
    <div style={{ marginBottom: '10vh' }}>
      <MButton
        variant={'text'}
        style={{ color: color, fontWeight: 'bold' }}
        onClick={onClickActionButton}
        disabled={disableActionButton}
      >
        {actionButtonLabel}
      </MButton>
    </div>
  );
};
