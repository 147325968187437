import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { colors } from '../../mui/colors';
import { withStyles } from '@material-ui/core';

// https://material-ui-pickers.firebaseapp.com/usage
// https://github.com/mui-org/material-ui/issues/11503#issuecomment-390502979

const styles = theme => ({
  input: {
    color: colors.primary
  }
});

const MDatePicker = ({ value, id, onChange, classes }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DatePicker
      id={id}
      value={value}
      onChange={onChange}
      disableFuture
      autoOk
      fullWidth={true}
      format={'dd-MMM-yyyy'}
      InputProps={{ disableUnderline: true, className: classes.input }}
    />
  </MuiPickersUtilsProvider>
);
export default withStyles(styles)(MDatePicker);
