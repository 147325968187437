import React from 'react';
import './styles.css';

export const InputBox = ({review, title, onClick, children}) => (
    <div className={review ? 'input-box-review-page' : 'input-box'} onClick={onClick}>
        <div className='input-box-border'>
            <div className='input-box-title'>{title}</div>
            <div className='input-box-children'>{children}</div>
        </div>
    </div>
);