import React from 'react';
import './styles.css';
import { Col, Row } from 'react-flexbox-grid';
import { roundForDisplay } from '../../../../containers/shared/util';

export const OverviewBodyTransactionsAmount = ({ numTransactions, spent, budgeted, currencySymbol }) => (
  <Row className="overview-body-transactions-amount">
    <Col xs={5} sm={5} md={5} lg={5} className="overview-budget-body-transactions">
      {numTransactions} Transactions
    </Col>
    <Col xs={4} sm={4} md={4} lg={4} className="overview-budget-body-amount">
      <span className="overview-budget-body-amount">{currencySymbol + roundForDisplay(spent)}</span>
      <span className="overview-budget-body-of">of</span>
      <span className="overview-budget-body-amount">{currencySymbol + roundForDisplay(budgeted)}</span>
    </Col>
  </Row>
);
