import {actionTypes} from "../../config/app-constants";

let defaultMoreState = {
    openFeedback : false,
    openManageCategories: false
};

export default function moreReducer(state=defaultMoreState, action){
    switch(action.type){
        case actionTypes.moreActionTypes.OPEN_FEEDBACK:
            return Object.assign({}, state, action.value);
        case actionTypes.moreActionTypes.CLOSE_FEEDBACK:
            return Object.assign({}, state, action.value);
        case actionTypes.moreActionTypes.OPEN_MANAGE_CATEGORIES:
            return Object.assign({}, state, action.value);
        case actionTypes.moreActionTypes.BACK_FROM_MANAGE_CATEGORIES:
            return Object.assign({}, state, action.value);
        default:
            return state;
    }
}