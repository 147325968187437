import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { HashLink } from 'react-router-hash-link';
import { blogLink, landingPageMenuItems } from '../../../../../config/app-constants';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../../mui/colors';

export const DesktopMenu = () => (
  <Row center={'xs'}>
    <Col key={4} xs={2} sm={2} md={2} lg={2}>
      <a className="feature-link" target="_blank" rel="noopener noreferrer" href={blogLink}>
        <Typography variant={'body2'} style={{ color: colors.primaryTextColor }}>
          Blog
        </Typography>
      </a>
    </Col>
    {landingPageMenuItems.map((menuItem, index) => (
      <Col key={index} xs={2} sm={2} md={2} lg={2}>
        <MenuLink title={menuItem.key} linkTo={menuItem.value} key={index} />
      </Col>
    ))}
  </Row>
);

const MenuLink = ({ title, linkTo }) => (
  <HashLink className="feature-link" to={`${linkTo}`}>
    <Typography variant={'body2'} style={{ color: colors.primaryTextColor }}>
      {title}
    </Typography>
  </HashLink>
);
