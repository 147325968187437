import React from 'react';
import { OverviewBodyTransactionsAmount } from './OverviewBodyTransactionsAmount';
import { OverviewBodyInBudgetProgressBar } from './OverviewBodyInBudgetProgressBar';
import './styles.css';
import { Col, Row } from 'react-flexbox-grid';
import { OverviewBodyOverBudgetProgressBar } from './OverviewBodyOverBudgetProgressBar';

export const OverviewRowBody = ({ numTransactions, spent, budgeted, isOverBudget, currencySymbol }) => (
  <Row className="overview-budget-row-body" around="xs">
    <Col xs={11} sm={11} md={11} lg={11}>
      <OverviewBodyTransactionsAmount
        numTransactions={numTransactions}
        spent={spent}
        budgeted={budgeted}
        currencySymbol={currencySymbol}
      />
      {isOverBudget && <OverviewBodyOverBudgetProgressBar />}
      {!isOverBudget && <OverviewBodyInBudgetProgressBar spent={spent} budgeted={budgeted} />}
    </Col>
  </Row>
);
