import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ListBudgetActions from '../../../actions/budgets/listBudgetActions';
import App from '../../app/index';
import { getCurrencySymbol, isEmptyObject, userIdFromCache } from '../../shared/util';
import { ListAllBudgets } from '../../../components/Budgets/List';
import { Routes } from '../../../config/app-constants';
import * as AppFooterActions from '../../../actions/app/appFooterActions';
import Onboarding from '../../onboarding';
import * as AppHeaderActions from '../../../actions/app/appHeaderActions';
import { MLinearProgress } from '../../../components/mui/MLinearProgress';
import { DangerWarning } from '../../../components/DangerWarning';

export class ListBudgets extends React.Component {
  componentWillMount() {
    this.props.highlightBudgets();
  }

  componentDidMount() {
    let userId = userIdFromCache();
    this.props.loadBudgets(userId);
  }

  onAddNewBudgetClick = () => {
    this.props.history.push(Routes.budgetsAdd);
  };

  onDeleteClick = budgetId => {
    this.props.onDeleteClicked(budgetId);
  };

  onDeleteOk = () => {
    this.props.deleteBudgetOk(this.props.listBudgetState.budgetIdToDelete);
    this.props.loadBudgets(userIdFromCache());
  };

  onDeleteCancel = () => {
    this.props.deleteBudgetCancel();
  };

  getSymbol = () => {
    return getCurrencySymbol(this.props.currencies.defaultCurrency.shortName);
  };

  render() {
    let isEmptyBudget = isEmptyObject(this.props.listBudgetState.budgets);
    let budgetsLoaded = this.props.listBudgetState.budgetsLoaded;
    let showDeleteAlert = this.props.listBudgetState.showDeleteAlert;
    return (
      <App showAvatar={true} title={'Budgets'}>
        {!budgetsLoaded && <MLinearProgress />}
        {budgetsLoaded && (
          <ListAllBudgets
            onClick={this.onAddNewBudgetClick}
            onDeleteClick={this.onDeleteClick}
            budgets={this.props.listBudgetState.budgets}
            totalBudgetedAmount={this.props.listBudgetState.totalBudgetedAmount}
            isEmptyBudget={isEmptyBudget}
            currencySymbol={this.getSymbol()}
          />
        )}
        {showDeleteAlert && (
          <DangerWarning
            open={showDeleteAlert}
            handleClose={this.onDeleteCancel}
            title={'Delete Budget'}
            text={'This budget will be permanently deleted. Do you want to continue ?'}
            onClick={this.onDeleteOk}
            buttonLabel1={'CANCEL'}
            buttonLabel2={'DELETE'}
          />
        )}

        {this.props.appHeaderState.showOnBoard && (
          <Onboarding showOnBoard={this.props.appHeaderState.showOnBoard} onSkip={this.props.onCloseOnBoarding} />
        )}
      </App>
    );
  }
}

let mapStateToProps = state => {
  return {
    listBudgetState: state.listBudgetReducer,
    appHeaderState: state.appHeaderReducer,
    currencies: state.currencyReducer
  };
};

let mapDispatchToProps = dispatch => {
  return {
    loadBudgets: bindActionCreators(ListBudgetActions.loadBudgets, dispatch),
    onDeleteClicked: bindActionCreators(ListBudgetActions.onDeleteClicked, dispatch),
    deleteBudgetCancel: bindActionCreators(ListBudgetActions.onDeleteCancel, dispatch),
    deleteBudgetOk: bindActionCreators(ListBudgetActions.deleteBudgetOk, dispatch),
    highlightBudgets: bindActionCreators(AppFooterActions.highlightBudgets, dispatch),
    onCloseOnBoarding: bindActionCreators(AppHeaderActions.closeOnBoarding, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListBudgets);
