export const onNumberAdd = (amount, numberPressed) => {
  return (
    parseFloat(
      amount
        .toString()
        .replace('.', '')
        .concat(numberPressed)
    ) / 100
  ).toFixed(2);
};

export const onNumberDelete = amount => {
  let amountStr = amount.toString().replace('.', '');
  let amountLastCharDeleted = amountStr.trim().length < 2 ? amountStr : amountStr.substring(0, amountStr.length - 1);
  return (parseFloat(amountLastCharDeleted) / 100).toFixed(2);
};
