import React from 'react';
import { List, ListItemText, Typography, ListItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { colors } from '../../mui/colors';
import { emphasis } from '../../mui/theme';
import NavigateNext from '@material-ui/icons/NavigateNext';

export const DefaultCurrencySetting = ({ defaultCurrency, showAllCurrencies }) => {
  let currency = defaultCurrency.shortName + ', ' + defaultCurrency.fullName;
  return (
    <List component="nav">
      <ListItem onClick={showAllCurrencies}>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="subtitle1" style={{ color: colors.primary }}>
              Default Currency
            </Typography>
          }
          secondary={<Typography style={{ opacity: emphasis.textOnSurfaceBlack.medium }}>{currency}</Typography>}
        />
        <NavigateNext style={{ opacity: emphasis.textOnSurfaceBlack.medium }} />
      </ListItem>
      <Divider />
    </List>
  );
};
