import { facebookProvider, firebaseAuth, googleProvider, twitterProvider } from '../../../config/firebase-constants';
import { unSubscribeBeforeLogout } from '../../../db';

export function loginWithGoogle() {
  return firebaseAuth().signInWithRedirect(googleProvider);
}

export function loginWithFacebook() {
  return firebaseAuth().signInWithRedirect(facebookProvider);
}

export function loginWithTwitter() {
  return firebaseAuth().signInWithRedirect(twitterProvider);
}

export function logout() {
  unSubscribeBeforeLogout();
  return firebaseAuth().signOut();
}
