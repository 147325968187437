import React from 'react';
import { Divider, withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  AccountBalanceOutlined,
  DateRangeOutlined,
  TextFormatOutlined,
  CategoryOutlined,
  Cancel
} from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import { formatDate, getTransactionTypeSymbol } from '../../../containers/shared/util';
import MDatePicker from '../../mui/MDatePicker';
import { colors } from '../../mui/colors';
import { emphasis } from '../../mui/theme';
import { ReviewSection } from '../../ReviewSection';
import { HeadingContent, HeadingChildrenStyled } from '../../HeadingContent';

const noOpOnClick = () => {};

export const TransactionDetailItems = ({
  amount,
  isExpense,
  currency,
  date,
  name,
  categoryName,
  subCategoryName,
  nameTextRef,
  onAmountClick,
  onDateClick,
  onNameChange,
  onClearNameClick,
  onCategoryClick,
  readOnly = false
}) => (
  <List component={'nav'}>
    <AmountSectionStyled
      amount={amount}
      isExpense={isExpense}
      currency={currency}
      onClick={readOnly ? noOpOnClick : onAmountClick}
    />
    <Divider />
    <DateSection value={date} onClick={readOnly ? noOpOnClick : onDateClick} readOnly={readOnly} />
    <Divider />
    <NameSectionStyled
      inputRef={nameTextRef}
      value={name}
      onChange={readOnly ? noOpOnClick : onNameChange}
      onClearNameClick={readOnly ? noOpOnClick : onClearNameClick}
      readOnly={readOnly}
    />
    <Divider />
    <CategorySectionStyled
      onClick={readOnly ? noOpOnClick : onCategoryClick}
      categoryName={categoryName}
      subCategoryName={subCategoryName}
    />
    <Divider />
  </List>
);

const SectionAdditionalContentStyle = theme => ({
  secondContent: {
    paddingTop: theme.spacing.unit * 2
  }
});

const AmountSection = ({ amount, isExpense, currency, onClick }) => (
  <ListItem onClick={onClick}>
    <ReviewSection icon={<AccountBalanceOutlined color={'primary'} />}>
      <Grid container style={{ border: '0px solid blue' }}>
        <Grid xs={12} item>
          <HeadingContent heading={'Amount'} value={`${getTransactionTypeSymbol(isExpense)} ${amount} ${currency} `} />
        </Grid>
        {/*
          <Grid xs={12} item className={classes.secondContent}>
            <HeadingContent heading={'Account'} value={'RBC Checking'} />
          </Grid>
  */}
      </Grid>
    </ReviewSection>
  </ListItem>
);
const AmountSectionStyled = withStyles(SectionAdditionalContentStyle)(AmountSection);

const DateSection = ({ value, onClick, readOnly }) => (
  <ListItem>
    <ReviewSection icon={<DateRangeOutlined color={'primary'} />}>
      <Grid container style={{ border: '0px solid blue' }}>
        <Grid xs={12} item>
          {readOnly && <HeadingContent heading={'Date'} value={formatDate(value)} />}
          {!readOnly && (
            <HeadingChildrenStyled heading={'Date'}>
              <MDatePicker value={value} onChange={onClick} />
            </HeadingChildrenStyled>
          )}
        </Grid>
      </Grid>
    </ReviewSection>
  </ListItem>
);

// https://material-ui.com/customization/default-theme/
const NameSectionStyles = theme => ({
  name: {
    fontSize: theme.typography.subtitle1.fontSize,
    letterSpacing: theme.typography.subtitle1.letterSpacing,
    color: colors.primary
  }
});
const NameSection = ({ value, inputRef, onChange, onClearNameClick, classes, readOnly }) => (
  <ListItem>
    <ReviewSection icon={<TextFormatOutlined color={'primary'} />}>
      {readOnly && (
        <Grid xs={12} item className={classes.secondContent}>
          <HeadingContent heading={'Name'} value={value} />
        </Grid>
      )}
      {!readOnly && (
        <Grid container alignItems={'center'} style={{ border: '0px solid blue' }}>
          <Grid xs={11} item style={{ border: '0px solid black' }}>
            <InputBase
              fullWidth
              className={classes.name}
              value={value}
              onChange={e => onChange(e.target.value)}
              inputRef={inputRef}
            />
          </Grid>
          <Grid xs={1} item>
            <Cancel
              onClick={onClearNameClick}
              style={{ opacity: emphasis.textOnSurfaceBlack.disabled, alignSelf: 'center' }}
            />
          </Grid>
        </Grid>
      )}
    </ReviewSection>
  </ListItem>
);
const NameSectionStyled = withStyles(NameSectionStyles)(NameSection);

const CategorySection = ({ onClick, classes, categoryName, subCategoryName }) => (
  <ListItem onClick={onClick}>
    <ReviewSection icon={<CategoryOutlined color={'primary'} />}>
      <Grid container style={{ border: '0px solid blue' }}>
        <Grid xs={12} item>
          <HeadingContent heading={'Category'} value={categoryName || 'UNKNOWN'} />
        </Grid>
        {subCategoryName && (
          <Grid xs={12} item className={classes.secondContent}>
            <HeadingContent heading={'Sub-Category'} value={subCategoryName} />
          </Grid>
        )}
      </Grid>
    </ReviewSection>
  </ListItem>
);
const CategorySectionStyled = withStyles(SectionAdditionalContentStyle)(CategorySection);
