import React from 'react';
import { Col } from 'react-flexbox-grid';
import business from '../../../images/icons/categories/business.svg';
import entertainment from '../../../images/icons/categories/entertainment.svg';
import financial from '../../../images/icons/categories/financial.svg';
import gifts from '../../../images/icons/categories/gifts.svg';
import food from '../../../images/icons/categories/food.svg';
import legal from '../../../images/icons/categories/legal.svg';
import pets from '../../../images/icons/categories/pets.svg';
import personal from '../../../images/icons/categories/shopping.svg';
import transportation from '../../../images/icons/categories/transportation.svg';
import travel from '../../../images/icons/categories/travel.svg';
import uncategorized from '../../../images/icons/categories/uncategorized.svg';
import utilities from '../../../images/icons/categories/utilities.svg';
import home from '../../../images/icons/categories/home.svg';
import income from '../../../images/icons/categories/income.svg';
import children from '../../../images/icons/categories/children.svg';
import education from '../../../images/icons/categories/education.svg';
import fitness from '../../../images/icons/categories/fitness.svg';
import health from '../../../images/icons/categories/health.svg';
import office from '../../../images/icons/categories/office.svg';
import technology from '../../../images/icons/categories/technology.svg';
import investment from '../../../images/icons/categories/investment.svg';
import custom from '../../../images/icons/categories/custom.svg';
import noCategory from '../../../images/icons/categories/noCategory.svg';
import { transactionCategoryLevelsToLabels } from '../../../config/app-constants';

const CategoryToImageAndColor = {
  FOOD: food,
  BUSINESS: business,
  ENTERTAINMENT: entertainment,
  FINANCIAL: financial,
  TRANSPORTATION: transportation,
  LEGAL: legal,
  PETS: pets,
  PERSONAL: personal,
  TRAVEL: travel,
  UNCATEGORIZED: uncategorized,
  UTILITIES: utilities,
  GIFTS: gifts,
  HOME: home,
  CHILDREN: children,
  EDUCATION: education,
  FITNESS: fitness,
  HEALTH: health,
  INVESTMENT: investment,
  OFFICE: office,
  TECHNOLOGY: technology,
  INCOME: income
};

export const CategoryImage = ({ categoryName, w, h }) => {
  let getSvgIcon = name => {
    if (name in CategoryToImageAndColor) return CategoryToImageAndColor[name];
    if (
      name === transactionCategoryLevelsToLabels.DELETED_LEVEL.categoryLabel ||
      name === transactionCategoryLevelsToLabels.DELETED_LEVEL.subCategoryLabel
    ) {
      return noCategory;
    }
    return custom;
  };
  return <IconInCircle src={getSvgIcon(categoryName)} w={w} h={h} />;
};

const IconInCircle = ({ src, w, h }) => (
  <Col xs={2}>
    <img src={src} alt="" style={{ width: w, height: h }} />
  </Col>
);
