import React from 'react';
import { SubCategories } from './SubCategories';
import { CategoryRow } from './CategoryRow';
import { colors } from '../../../mui/colors';
import { CreateCategory } from '../../../ManageCategories/CreateCategory';
import MFullScreenDialog from '../../../mui/MFullScreenDialog';
import Button from '@material-ui/core/Button/Button';
import { ManageCategories } from '../../../ManageCategories';
import { AlertDialog } from '../../../AlertDialog';
import { Placeholder } from '../../../Placeholder';
import { emptyComponents } from '../../../../config/app-constants';

export const Category = ({
  showSubcategory,
  subCategories,
  onShowSubCategory,
  onCloseSubcategory,
  onSubCategorySelect,
  onAddCategory,
  showAddCategory,
  onCloseAddCategory,
  onNewCategoryChange,
  onNewSubCategoryChange,
  onAddCategoryDone,
  newCategoryName,
  newSubCategoryName,
  showAddSubCategory,
  onAddSubCategory,
  existingCategory,
  onCloseAddSubCategory,
  onAddSubCategoryDone,
  tabValue,
  onCategoryTabChange,
  enableSubCategory,
  onDisableSubCategory,
  confirmDisableSubCategory,
  onCloseDisableSubCategoryDialog,
  onOkDisableSubCategory,
  suggestedCategories,
  userCategories,
  isDuplicateSubCategory,
  onCloseDuplicateSubCategory,
  isDuplicateCategory,
  onCloseDuplicateCategory,
  showEmptyCategories
}) => {
  let onCategoryClick = categoryClicked => {
    onShowSubCategory(categoryClicked);
  };

  return (
    <div>
      <ManageCategories value={tabValue} onChange={onCategoryTabChange} labels={['My Categories', 'Suggested']} />
      {tabValue === 'one' && (
        <UserCategories
          showSubcategory={showSubcategory}
          subCategories={subCategories}
          onCloseSubcategory={onCloseSubcategory}
          onSubCategorySelect={onSubCategorySelect}
          onAddCategory={onAddCategory}
          showAddCategory={showAddCategory}
          onCloseAddCategory={onCloseAddCategory}
          onNewCategoryChange={onNewCategoryChange}
          onNewSubCategoryChange={onNewSubCategoryChange}
          onAddCategoryDone={onAddCategoryDone}
          newCategoryName={newCategoryName}
          newSubCategoryName={newSubCategoryName}
          showAddSubCategory={showAddSubCategory}
          onAddSubCategory={onAddSubCategory}
          existingCategory={existingCategory}
          onCloseAddSubCategory={onCloseAddSubCategory}
          onAddSubCategoryDone={onAddSubCategoryDone}
          onCategoryClick={onCategoryClick}
          enableSubCategory={enableSubCategory}
          onDisableSubCategory={onDisableSubCategory}
          confirmDisableSubCategory={confirmDisableSubCategory}
          onCloseDisableSubCategoryDialog={onCloseDisableSubCategoryDialog}
          onOkDisableSubCategory={onOkDisableSubCategory}
          userCategories={userCategories}
          isDuplicateSubCategory={isDuplicateSubCategory}
          onCloseDuplicateSubCategory={onCloseDuplicateSubCategory}
          isDuplicateCategory={isDuplicateCategory}
          onCloseDuplicateCategory={onCloseDuplicateCategory}
          showEmptyCategories={showEmptyCategories}
        />
      )}

      {tabValue === 'two' && (
        <SuggestedCategories
          showSubcategory={showSubcategory}
          subCategories={subCategories}
          onCloseSubcategory={onCloseSubcategory}
          onSubCategorySelect={onSubCategorySelect}
          onAddCategory={onAddCategory}
          showAddCategory={showAddCategory}
          onCloseAddCategory={onCloseAddCategory}
          onNewCategoryChange={onNewCategoryChange}
          onNewSubCategoryChange={onNewSubCategoryChange}
          onAddCategoryDone={onAddCategoryDone}
          newCategoryName={newCategoryName}
          newSubCategoryName={newSubCategoryName}
          showAddSubCategory={showAddSubCategory}
          onAddSubCategory={onAddSubCategory}
          existingCategory={existingCategory}
          onCloseAddSubCategory={onCloseAddSubCategory}
          onAddSubCategoryDone={onAddSubCategoryDone}
          onCategoryClick={onCategoryClick}
          enableSubCategory={enableSubCategory}
          onDisableSubCategory={onDisableSubCategory}
          confirmDisableSubCategory={confirmDisableSubCategory}
          onCloseDisableSubCategoryDialog={onCloseDisableSubCategoryDialog}
          onOkDisableSubCategory={onOkDisableSubCategory}
          suggestedCategories={suggestedCategories}
          isDuplicateSubCategory={isDuplicateSubCategory}
          onCloseDuplicateSubCategory={onCloseDuplicateSubCategory}
        />
      )}
    </div>
  );
};
export const UserCategories = ({
  showSubcategory,
  subCategories,
  onCloseSubcategory,
  onSubCategorySelect,
  onAddCategory,
  showAddCategory,
  onCloseAddCategory,
  onNewCategoryChange,
  onNewSubCategoryChange,
  onAddCategoryDone,
  newCategoryName,
  newSubCategoryName,
  showAddSubCategory,
  onAddSubCategory,
  existingCategory,
  onCloseAddSubCategory,
  onAddSubCategoryDone,
  onCategoryClick,
  enableSubCategory,
  onDisableSubCategory,
  confirmDisableSubCategory,
  onCloseDisableSubCategoryDialog,
  onOkDisableSubCategory,
  userCategories,
  isDuplicateSubCategory,
  onCloseDuplicateSubCategory,
  isDuplicateCategory,
  onCloseDuplicateCategory,
  showEmptyCategories
}) => {
  return (
    <div>
      {showEmptyCategories && (
        <Placeholder
          title={emptyComponents.categoriesViaAddTransaction.title}
          text={emptyComponents.categoriesViaAddTransaction.text}
          image={emptyComponents.categoriesViaAddTransaction.image}
        />
      )}

      <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '2vh' }}>
        {userCategories.map((userCategory, key) => (
          <div key={key} style={{ paddingLeft: '4vw' }}>
            <CategoryRow category={userCategory} onCategoryClick={onCategoryClick} />
          </div>
        ))}
      </div>

      {showSubcategory && (
        <SubCategories
          showSubcategory={showSubcategory}
          onSubcategoryClose={onCloseSubcategory}
          subCategories={subCategories}
          onSubCategorySelect={onSubCategorySelect}
          showAddSubCategory={showAddSubCategory}
          onAddSubCategory={onAddSubCategory}
          existingCategory={existingCategory}
          onCloseAddSubCategory={onCloseAddSubCategory}
          newSubCategoryName={newSubCategoryName}
          onAddSubCategoryDone={onAddSubCategoryDone}
          onNewSubCategoryChange={onNewSubCategoryChange}
          enableSubCategory={enableSubCategory}
          onDisableSubCategory={onDisableSubCategory}
          confirmDisableSubCategory={confirmDisableSubCategory}
          onCloseDisableSubCategoryDialog={onCloseDisableSubCategoryDialog}
          onOkDisableSubCategory={onOkDisableSubCategory}
          isDuplicateSubCategory={isDuplicateSubCategory}
          onCloseDuplicateSubCategory={onCloseDuplicateSubCategory}
        />
      )}

      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2vh' }}>
        <Button variant={'text'} style={{ color: colors.primary3Color, fontWeight: '500' }} onClick={onAddCategory}>
          + ADD CATEGORY
        </Button>
      </div>

      {showAddCategory && (
        <MFullScreenDialog open={showAddCategory} onClickClose={onCloseAddCategory} title={'New Category'}>
          <CreateCategory
            subCategoryTitle={'SUB-CATEGORY (Optional)'}
            categoryName={newCategoryName}
            subCategoryName={newSubCategoryName}
            actionButtonLabel={'DONE'}
            showSubCategoryBoxOnly={true}
            onCategoryChange={onNewCategoryChange}
            onSubCategoryChange={onNewSubCategoryChange}
            disableActionButton={newCategoryName.trim().length === 0}
            onClickActionButton={onAddCategoryDone}
            disableCategory={false}
          />

          {isDuplicateCategory && (
            <AlertDialog
              open={isDuplicateCategory}
              handleClose={onCloseDuplicateCategory}
              title={'CATEGORY already exists.'}
              onClick={onCloseDuplicateCategory}
              buttonLabel2={'OK'}
            />
          )}
        </MFullScreenDialog>
      )}
    </div>
  );
};

export const SuggestedCategories = ({
  showSubcategory,
  subCategories,
  onCloseSubcategory,
  onSubCategorySelect,
  showAddCategory,
  onCloseAddCategory,
  onNewCategoryChange,
  onNewSubCategoryChange,
  onAddCategoryDone,
  newCategoryName,
  newSubCategoryName,
  showAddSubCategory,
  onAddSubCategory,
  existingCategory,
  onCloseAddSubCategory,
  onAddSubCategoryDone,
  onCategoryClick,
  enableSubCategory,
  onDisableSubCategory,
  confirmDisableSubCategory,
  onOkDisableSubCategory,
  onCloseDisableSubCategoryDialog,
  suggestedCategories,
  isDuplicateSubCategory,
  onCloseDuplicateSubCategory
}) => {
  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '2vh' }}>
        {suggestedCategories.map((suggestedCategory, key) => (
          <div key={key} style={{ paddingLeft: '4vw' }}>
            <CategoryRow category={suggestedCategory} onCategoryClick={onCategoryClick} />
          </div>
        ))}
      </div>

      {showSubcategory && (
        <SubCategories
          showSubcategory={showSubcategory}
          onSubcategoryClose={onCloseSubcategory}
          subCategories={subCategories}
          onSubCategorySelect={onSubCategorySelect}
          showAddSubCategory={showAddSubCategory}
          onAddSubCategory={onAddSubCategory}
          existingCategory={existingCategory}
          onCloseAddSubCategory={onCloseAddSubCategory}
          newSubCategoryName={newSubCategoryName}
          onAddSubCategoryDone={onAddSubCategoryDone}
          onNewSubCategoryChange={onNewSubCategoryChange}
          enableSubCategory={enableSubCategory}
          onDisableSubCategory={onDisableSubCategory}
          confirmDisableSubCategory={confirmDisableSubCategory}
          onOkDisableSubCategory={onOkDisableSubCategory}
          onCloseDisableSubCategoryDialog={onCloseDisableSubCategoryDialog}
          isDuplicateSubCategory={isDuplicateSubCategory}
          onCloseDuplicateSubCategory={onCloseDuplicateSubCategory}
        />
      )}

      {showAddCategory && (
        <MFullScreenDialog open={showAddCategory} onClickClose={onCloseAddCategory} title={'New Category'}>
          <CreateCategory
            subCategoryTitle={'SUB-CATEGORY (Optional)'}
            categoryName={newCategoryName}
            subCategoryName={newSubCategoryName}
            actionButtonLabel={'DONE'}
            showSubCategoryBoxOnly={true}
            onCategoryChange={onNewCategoryChange}
            onSubCategoryChange={onNewSubCategoryChange}
            disableActionButton={newCategoryName.trim().length === 0}
            onClickActionButton={onAddCategoryDone}
            disableCategory={false}
          />
        </MFullScreenDialog>
      )}
    </div>
  );
};
