import { currentUserId } from '../../config/app-constants';
import moment from 'moment';
import { currencyCodeToName } from './currencyCodeToName';

export const userIdFromCache = () => localStorage.getItem(currentUserId);

export const getRemainingDaysInMonth = (date = new Date()) => {
  let startDate = moment(date);
  let endOfMonthDate = moment(Date.now()).endOf('month');

  // if months do not match OR month match but year do not match,
  // return zero days left, since it is not current month.
  if (startDate.month() !== endOfMonthDate.month() || startDate.year() !== endOfMonthDate.year()) return 0;

  return endOfMonthDate.diff(startDate, 'days');
};

export const isEmptyObject = target => {
  return target === undefined || target === null || Object.keys(target).length === 0;
};

export const isEmptyString = input => input === undefined || input === null || input.trim().length === 0;

export const isValidAmount = amount => {
  let floatAmount = parseFloat(amount);
  return !isNaN(floatAmount) && floatAmount > 0.0;
};

export const filterUniqueCategories = (userCategories, suggestedCategories) => {
  let userCategoriesObject = getCategoryNameToObject(userCategories);
  let uniqueCategories = suggestedCategories.filter(category => !userCategoriesObject.hasOwnProperty(category.name));
  return uniqueCategories;
};

export const getCategoryNameToObject = categoriesCollection => {
  let newObject = {};
  categoriesCollection.forEach(item => (newObject[item.name] = item));
  return newObject;
};

export const didSpentMoreThanBudgeted = (spent, budgeted) => spent > budgeted;

export const roundForDisplay = amount => {
  return amount === undefined ? '0.00' : parseFloat(amount).toFixed(2);
};

/**
 * Performs a - b, but abs and ceil
 * // https://javascript.info/number
 * @param a
 * @param b
 * @returns {number}
 */
export const getAbsDiff = (a, b) => (Math.abs(a - b) * 100) / 100;

/**
 * Performs a - b
 * @param a
 * @param b
 * @returns {number}
 */
export const getDifference = (a, b) => ((a - b) * 100) / 100;

export const getSpentPercent = (spent, budgeted) => (spent * 100.0) / budgeted;

export const getCurrencySymbol = () => {
  // if (shortName === 'SGD') return '$';
  // let number = 0;
  // let currencySymbol = number.toLocaleString('de-DE', { style: 'currency', currency: shortName });
  // return currencySymbol.substr(currencySymbol.length - 1);
  return '';
};

export const formatDate = value => (value ? moment(value).format('D-MMM-Y') : '');

export const getTransactionTypeSymbol = isExpense => (isExpense ? '-' : '+');
export const isValidCurrencySymbol = symbol => currencyCodeToName.hasOwnProperty(symbol);

/**
 * Sorts the transactions in reverse order of their creation date.
 * todo: create a higher order function to order given aray of transactions in any order
 * as the user desires.
 */
export const sortTransactionsByDateDesc = transactions => {
  const copied = [...transactions];
  copied.sort((t1, t2) => t2.date - t1.date); // mutate the state
  return copied;
};
