import React from 'react';
import App from '../../containers/app';
import { ManageCategories } from '../../components/ManageCategories';
import { CreateCategory } from '../../components/ManageCategories/CreateCategory';
import MyCategoriesContainer from './myCategories';
import { getMyCategories, saveCategory } from '../../db';
import { constructNewCategory } from '../shared/transactionsWorkflow';
import uuid from 'uuid';
import { AlertDialog } from '../../components/AlertDialog';
import { maxCategories, maxSubCategories } from '../../config/app-constants';
import { userIdFromCache } from '../shared/util';

export default class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'one',
      enableSubCategory: false,
      disableSaveButton: true,
      subCategoryName: '',
      categoryName: '',
      subCategoriesOfCategory: [],
      showSubCategorySwitch: true,
      warnDuplicate: false,
      warnSubCategoriesExceed: false,
      warnCategoriesExceed: false
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  onClickSave = () => {
    if (this.state.subCategoriesOfCategory.length > maxSubCategories) {
      this.setState({ warnSubCategoriesExceed: true });
    } else {
      getMyCategories(userIdFromCache()).then(categories => {
        if (this.isDuplicateCategory(categories)) {
          this.setState({ warnDuplicate: true });
        } else if (categories.length > maxCategories) {
          this.setState({ warnCategoriesExceed: true });
        } else {
          let categoryToSave = constructNewCategory(
            this.state.categoryName,
            this.state.subCategoriesOfCategory,
            this.state.enableSubCategory,
            true
          );
          saveCategory(categoryToSave);
          this.setState({ enableSubCategory: false, categoryName: '', subCategoriesOfCategory: [] });
        }
      });
    }
  };

  isDuplicateCategory = userCategories => {
    return userCategories.filter(category => category.name === this.state.categoryName).length > 0;
  };

  disableActionButton = () => {
    return (
      this.state.categoryName.trim().length === 0 ||
      (this.state.enableSubCategory && this.state.subCategoriesOfCategory.length === 0)
    );
  };

  onEnableSubCategory = enableSubCategory => {
    this.setState({ enableSubCategory: enableSubCategory });
  };

  onCategoryChange = categoryName => {
    this.setState({ categoryName: categoryName.toUpperCase() });
  };

  onAddSubCategory = () => {
    let isDuplicateSubcategory =
      this.state.subCategoriesOfCategory.filter(subCategory => subCategory.name === this.state.subCategoryName).length >
      0;
    if (!isDuplicateSubcategory) {
      const subCategory = {
        id: uuid(),
        name: this.state.subCategoryName,
        createdAt: new Date()
      };
      this.setState({
        subCategoriesOfCategory: [...this.state.subCategoriesOfCategory, subCategory],
        subCategoryName: ''
      });
    }
  };

  onSubCategoryChange = subCategoryName => {
    this.setState({ subCategoryName: subCategoryName.toUpperCase() });
  };

  onRemoveSubCategory = subCategoryToRemove => {
    let remainingSubCategories = this.state.subCategoriesOfCategory.filter(
      subCategory => subCategory.id !== subCategoryToRemove.id
    );
    this.setState({ subCategoriesOfCategory: remainingSubCategories });
  };

  onWarningClose = () => {
    this.setState({ warnDuplicate: false });
  };

  onWarnSubCategoryExceedClose = () => {
    this.setState({ warnSubCategoriesExceed: false });
  };

  onWarnCategoryExceedClose = () => {
    this.setState({ warnCategoriesExceed: false });
  };

  render() {
    return (
      <App onBackButtonClick={this.props.onBackButtonClick} title={'Categories'} hideInfoIcon={true}>
        <ManageCategories value={this.state.value} onChange={this.handleChange} labels={['My Categories', 'Create']} />
        {this.state.value === 'one' && <MyCategoriesContainer />}

        {this.state.value === 'two' && (
          <CreateCategory
            categoryName={this.state.categoryName}
            onCategoryChange={this.onCategoryChange}
            enableSubCategory={this.state.enableSubCategory}
            onEnableSubCategory={this.onEnableSubCategory}
            onAddSubCategory={this.onAddSubCategory}
            subCategoryName={this.state.subCategoryName}
            onSubCategoryChange={this.onSubCategoryChange}
            onClickActionButton={this.onClickSave}
            disableActionButton={this.disableActionButton()}
            actionButtonLabel={'SAVE'}
            onRemoveSubCategory={this.onRemoveSubCategory}
            disableAddSubCategoryButton={this.state.subCategoryName.trim().length === 0}
            subCategories={this.state.subCategoriesOfCategory}
            showSubCategorySwitch={this.state.showSubCategorySwitch}
            warnDuplicate={this.state.warnDuplicate}
            handleDuplicateClose={this.onWarningClose}
            categoryDuplicateTitle={'Category already exists'}
            warningLabel={'OK'}
          />
        )}

        {this.state.warnDuplicate && (
          <AlertDialog
            open={this.state.warnDuplicate}
            handleClose={this.onWarningClose}
            title={'This Category already exists'}
            onClick={this.onWarningClose}
            buttonLabel2={'OK'}
          />
        )}

        {this.state.warnSubCategoriesExceed && (
          <AlertDialog
            open={this.state.warnSubCategoriesExceed}
            handleClose={this.onWarnSubCategoryExceedClose}
            title={'You exceeded maximum number of SUB-CATEGORIES allowed'}
            onClick={this.onWarnSubCategoryExceedClose}
            buttonLabel2={'OK'}
          />
        )}

        {this.state.warnCategoriesExceed && (
          <AlertDialog
            open={this.state.warnCategoriesExceed}
            handleClose={this.onWarnCategoryExceedClose}
            title={'You exceeded the maximum number of CATEGORIES allowed'}
            onClick={this.onWarnCategoryExceedClose}
            buttonLabel2={'OK'}
          />
        )}
      </App>
    );
  }
}
