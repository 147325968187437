import React from 'react';
import MediaQuery from 'react-responsive';
import { LandingPage } from './LandingPage';

// https://github.com/contra/react-responsive
export const AppRoot = ({ loginWithGoogle, loginWithFacebook, loginWithTwitter, onCloseCookies, showCookies }) => (
  <div style={{ overflowX: 'hidden' }}>
    <MediaQuery minDeviceWidth={1224}>
      <LandingPage
        isMobileView={false}
        loginWithGoogle={loginWithGoogle}
        loginWithFacebook={loginWithFacebook}
        loginWithTwitter={loginWithTwitter}
        onCloseCookies={onCloseCookies}
        showCookies={showCookies}
      />
    </MediaQuery>

    <MediaQuery maxDeviceWidth={1224} orientation="portrait">
      <LandingPage
        isMobileView={true}
        loginWithGoogle={loginWithGoogle}
        loginWithFacebook={loginWithFacebook}
        loginWithTwitter={loginWithTwitter}
        onCloseCookies={onCloseCookies}
        showCookies={showCookies}
      />
    </MediaQuery>

    <MediaQuery maxDeviceWidth={1224} orientation="landscape">
      <h1>This view is not supported</h1>
    </MediaQuery>
  </div>
);
