import { actionTypes, defaultCurrency, defaultCurrencyNewUser } from '../../config/app-constants';
import { fetchUser, saveDefaultCurrencyForThisUser, getAllCurrencies } from '../../db/index';
import { userIdFromCache } from '../../containers/shared/util';
import { analyticsEvent, CATEGORIES, ACTIONS } from '../../analytics/events';
import { currencyCodeToName } from '../../containers/shared/currencyCodeToName';

export function showAllCurrencies() {
  return {
    type: actionTypes.currencyActionTypes.SHOW_ALL_CURRENCIES,
    value: { shouldRenderAllCurrencies: true }
  };
}

export function closeAllCurrencies() {
  return {
    type: actionTypes.currencyActionTypes.CLOSE_ALL_CURRENCIES,
    value: { shouldRenderAllCurrencies: false }
  };
}

export function saveDefaultCurrencySuccess(selectedCurrencyCode) {
  analyticsEvent(CATEGORIES.CURRENCIES, ACTIONS.CURRENCIES.UPDATED);

  return {
    type: actionTypes.currencyActionTypes.SAVE_DEFAULT_CURRENCY,
    value: { currencyCode: selectedCurrencyCode, shouldRenderAllCurrencies: false }
  };
}

export function saveDefaultCurrency(selectedCurrencyCode) {
  return dispatch => {
    return saveDefaultCurrencyForThisUser(userIdFromCache(), selectedCurrencyCode, new Date())
      .then(() => {
        cacheDefaultCurrencyIfNotSavedAlready(selectedCurrencyCode);
        dispatch(saveDefaultCurrencySuccess(selectedCurrencyCode));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadDefaultCurrency() {
  return function(dispatch) {
    return fetchUser(userIdFromCache())
      .then(userData => {
        if (!userData.exists) {
          return;
        }
        let defaultCurrency =
          userData.data().settings && userData.data().settings.defaultCurrency
            ? userData.data().settings.defaultCurrency
            : defaultCurrencyNewUser;

        cacheDefaultCurrencyIfNotSavedAlready(defaultCurrency);
        dispatch(loadDefaultCurrencyOnSuccess(defaultCurrency));
      })
      .catch(error => {
        throw error;
      });
  };
}

function cacheDefaultCurrencyIfNotSavedAlready(defaultCurrencyValue) {
  localStorage.setItem(defaultCurrency, defaultCurrencyValue);
}

export function loadDefaultCurrencyOnSuccess(defaultCurrency) {
  return {
    type: actionTypes.currencyActionTypes.LOAD_DEFAULT_CURRENCY_OK,
    value: { defaultCurrency: defaultCurrency, isLoadingDefaultCurrency: false }
  };
}

export function loadAllCurrencies() {
  let allCurrencies = [];
  return function(dispatch) {
    return getAllCurrencies()
      .then(currencies => {
        currencies.forEach(currency => {
          allCurrencies.push({ code: currency.code, name: currencyCodeToName[currency.code] });
        });
        dispatch(currenciesLoaded(allCurrencies));
      })
      .catch(err => {
        throw err;
      });
  };
}

function currenciesLoaded(currencies) {
  return {
    type: actionTypes.currencyActionTypes.LOAD_ALL_CURRENCIES,
    value: { allCurrencies: currencies, filteredCurrencies: currencies }
  };
}

export function onSearchCurrency(searchTerm, allCurrencies) {
  let filteredCurrencies = getFilteredCurrencies(searchTerm, allCurrencies);
  return {
    type: actionTypes.currencyActionTypes.ON_SEARCH_CURRENCY,
    value: { searchTerm: searchTerm, filteredCurrencies: filteredCurrencies }
  };
}

function getFilteredCurrencies(searchTerm, allCurrencies) {
  if (searchTerm.trim().length === 0) return allCurrencies;
  const searchTermLowerCase = searchTerm.toLowerCase().trim();
  return allCurrencies.filter(
    currency =>
      currency.code.toLowerCase().indexOf(searchTermLowerCase) !== -1 ||
      currency.name.toLowerCase().indexOf(searchTermLowerCase) !== -1
  );
}
