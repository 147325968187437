import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Card } from '@material-ui/core';
import './styles.css';

export default class AppCard extends React.Component {
  render() {
    return (
      <div className="app-card-row">
        <Row className="app-card-row-row" center="xs">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card className="app-card-row-body" style={{ backgroundColor: this.props.color }}>
              {this.props.children}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
