import React from 'react';
import { AllCurrencies } from './AllCurrencies/index';
import { DefaultCurrencySetting } from './DefaultCurrencySetting/index';

export const CurrencySettings = ({
  shouldRenderAllCurrencies,
  closeAllCurrencies,
  showAllCurrencies,
  defaultCurrency,
  saveDefaultCurrency,
  allCurrencies,
  onSearch,
  searchTerm
}) => {
  return (
    <div>
      <AllCurrencies
        shouldRenderAllCurrencies={shouldRenderAllCurrencies}
        closeAllCurrencies={closeAllCurrencies}
        saveDefaultCurrency={saveDefaultCurrency}
        allCurrencies={allCurrencies}
        onSearch={onSearch}
        searchTerm={searchTerm}
      />
      <DefaultCurrencySetting defaultCurrency={defaultCurrency} showAllCurrencies={showAllCurrencies} />
    </div>
  );
};
