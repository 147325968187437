import React from 'react';
import {connect} from "react-redux";
import {Feedback} from "../../components/Feedback";
import {bindActionCreators} from "redux";
import *  as feedbackActions from '../../actions/feedback/feedbackActions';
import {userIdFromCache} from "../shared/util";
import {withRouter} from "react-router";
import {Routes} from "../../config/app-constants";


class UserFeedback extends React.Component {

    onSubmit = () => {
        let userId = userIdFromCache();
        if (this.props.userFeedbackState.description.trim()) {
            this.props.submitFeedback(userId, this.props.userFeedbackState.description);
        }
        this.props.history.push(Routes.overview);
    };


    render() {
        return <Feedback open={this.props.openFeedback}
                         onClose={this.props.onClose}
                         onSubmitFeedback={this.onSubmit}
                         description={this.props.userFeedbackState.description}
                         onDescriptionChange={this.props.onDescriptionChange}
        />
    }
}

let mapStateToProps = (state) => {
    return {userFeedbackState: state.feedbackReducer}
};

let mapDispatchToProps = (dispatch) => {
    return {
        submitFeedback: bindActionCreators(feedbackActions.submitUserFeedback, dispatch),
        onDescriptionChange: bindActionCreators(feedbackActions.onDescriptionChange, dispatch)
    }
};

const UserFeedbackWithRouter = withRouter(UserFeedback);
export default connect(mapStateToProps, mapDispatchToProps)(UserFeedbackWithRouter);