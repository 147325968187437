import { actionTypes } from '../../config/app-constants';
import { deleteBudgetWithId, getAllBudgets, getMyCategories } from '../../db';
import { getBudgetsWithCategoryName, getTotalBudgetedAmount } from '../../containers/shared/budgetsWorkflow';

export function loadBudgets(userId) {
  return function(dispatch) {
    return Promise.all([getAllBudgets(userId), getMyCategories(userId)])
      .then(([allBudgets, userCategories]) => {
        let budgets = getBudgetsWithCategoryName(allBudgets, userCategories);
        let totalBudgetedAmount = getTotalBudgetedAmount(budgets);
        dispatch(onLoadBudgetSuccess(budgets, totalBudgetedAmount, true));
      })
      .catch(error => {
        console.log('Error loading budgets', error.message);
      });
  };
}

export function onLoadBudgetSuccess(budgets, totalBudgetedAmount, budgetsLoaded) {
  return {
    type: actionTypes.listBudgetActionTypes.LOAD_BUDGETS_OK,
    value: { budgets, totalBudgetedAmount, budgetsLoaded }
  };
}

export function onDeleteClicked(budgetId) {
  return {
    type: actionTypes.listBudgetActionTypes.DELETE_CLICKED,
    value: { budgetIdToDelete: budgetId, showDeleteAlert: true }
  };
}

export function deleteBudgetOk(budgetId) {
  return function(dispatch) {
    deleteBudgetWithId(budgetId);
    dispatch(onBudgetDeleteSuccess());
  };
}

function onBudgetDeleteSuccess() {
  return {
    type: actionTypes.listBudgetActionTypes.BUDGET_DELETED,
    value: { showDeleteAlert: false }
  };
}

export function onDeleteCancel() {
  return {
    type: actionTypes.listBudgetActionTypes.BUDGET_DELETE_CANCEL,
    value: { showDeleteAlert: false }
  };
}
