import React from 'react';
import Button from '@material-ui/core/Button/Button';

export const MButton = ({
                            color,
                            onClick,
                            style,
                            children,
                            disabled = false,
                            variant = 'contained',
                            fullWidth = true,
                            size = 'small'
                        }) => (
    <div>
        {disabled && <Button variant={variant}
                             disabled
                             style={{marginTop: style.marginTop}}
                             fullWidth={fullWidth}>
            {children}
        </Button>
        }
        {!disabled && <Button variant={variant}
                              size={size}
                              color={color}
                              fullWidth={fullWidth}
                              onClick={onClick}
                              style={style}>
            {children}
        </Button>
        }
    </div>
);