import category from '../../../../images/icons/features/category.svg';
import transaction from '../../../../images/icons/features/transaction.svg';
import budgets from '../../../../images/icons/features/budgets.svg';
import noAds from '../../../../images/icons/features/noAds.svg';
import coins from '../../../../images/icons/features/coins.svg';
import offline from '../../../../images/icons/features/offline.svg';

export const features = [
  {
    icon: transaction,
    title: 'Quick Tracking',
    description: 'Track your income or expenses in less than 10 seconds'
  },
  {
    icon: category,
    title: 'Custom Categories',
    description: 'Customise suggested categories or create your own.'
  },
  {
    icon: budgets,
    title: 'Budgets',
    description: 'Create spending buckets and view progress with insights '
  },
  {
    icon: offline,
    title: 'Offline First',
    description: 'Use app in offline mode on Android. Yes! without internet'
  },
  {
    icon: coins,
    title: 'Vast Currency Support',
    description: '145 currencies supported. We care about everyone'
  },
  {
    icon: noAds,
    title: 'No Ads',
    description: 'We do not sell your data to make money or to sell you products. Your Personal Finance is "Personal"'
  }
];
