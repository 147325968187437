import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { roundForDisplay } from '../../../../containers/shared/util';

export const OverviewDetailCardHeaderAmount = ({
  totalAmountLabel,
  totalAmountValue,
  totalAmountColor,
  currencySymbol
}) => (
  <Row end="xs">
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ color: `${totalAmountColor}` }}
      className="overview-detail-card-header-amount"
    >
      {currencySymbol + roundForDisplay(totalAmountValue)}
    </Col>
    <Col xs={12} sm={12} md={12} lg={12} className="overview-detail-card-header-label">
      {totalAmountLabel}
    </Col>
  </Row>
);
