import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { CategoryImage } from '../../../containers/shared/categoryImage';

export const OverviewDetailCategoryDaysHeader = ({ category, daysRemaining }) => (
  <div>
    <Row around="xs" middle="xs" start="xs">
      <Col xsOffset={1} xs={1} sm={1} md={1} lg={1}>
        <CategoryImage categoryName={category} w={24} h={24} />
      </Col>
      <Col xsOffset={1} xs={6} sm={6} md={6} lg={6} className="overview-detail-category-name">
        {category}
      </Col>
    </Row>
    <Row start="xs">
      <Col xsOffset={1} xs={12} sm={12} md={12} lg={12} className="overview-detail-category-days">
        {daysRemaining} days remaining
      </Col>
    </Row>
  </div>
);
