import {actionTypes} from '../../config/app-constants';

export function showOnBoarding(){
    return {type: actionTypes.appHeaderActionTypes.SHOW_ONBOARD, value: {showOnBoard: true}};

}

export function closeOnBoarding(){
    return {type: actionTypes.appHeaderActionTypes.SHOW_ONBOARD, value: {showOnBoard: false}};

}