import React from 'react';
import './styles.css';
import { Col, Row } from 'react-flexbox-grid';
import GetStarted from '../GettingStarted';
import { colors } from '../../../mui/colors';
import Typography from '@material-ui/core/Typography';

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultView: true
    };
  }

  render() {
    return (
      <Row style={{ paddingTop: '6vh' }} id="pricing">
        <Col xs={12} sm={12} md={12} lg={12}>
          <PricingHeader />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <DefaultPricingView {...this.props} />
        </Col>
      </Row>
    );
  }
}

const PricingHeader = () => (
  <div className="pricing-headline">
    <Typography variant={'h5'} style={{ color: colors.primary }}>
      Pricing
    </Typography>
  </div>
);

const DefaultPricingView = props => (
  <Row center={'xs'}>
    <PriceCard
      label={'Free'}
      amount={0}
      subtitle={'always free'}
      showGetStarted={true}
      description={'All these features are free to use. Start your journey of financial awareness today.'}
      {...props}
    />
    {/*<PriceCard*/}
    {/*label={'Basic'}*/}
    {/*amount={0}*/}
    {/*subtitle={'per month'}*/}
    {/*{...props}*/}
    {/*description={'For those who are aware and taking control of their financial journey'}*/}
    {/*/>*/}
    {/*<PriceCard*/}
    {/*label={'Premium'}*/}
    {/*amount={0}*/}
    {/*subtitle={'per month'}*/}
    {/*{...props}*/}
    {/*description={'For those who control their financial journey and want more flexibility to suit their needs'}*/}
    {/*/>*/}
  </Row>
);

const PriceCard = ({
  label,
  amount,
  subtitle,
  description,
  showGetStarted = false,
  loginWithGoogle,
  loginWithFacebook,
  loginWithTwitter
}) => (
  <Col xs={9} sm={3} md={3} lg={3}>
    <Row className="price-card">
      <Col xs={12} sm={12} md={12} lg={12} className={'price-card-label'}>
        <Typography variant={'subtitle2'} style={{ color: colors.primary }}>
          {label.toUpperCase()}
        </Typography>
      </Col>
      {!showGetStarted && (
        <Col xs={12} sm={12} md={12} lg={12} className={'price-card-coming-soon'}>
          Coming soon
        </Col>
      )}
      {showGetStarted && (
        <GetStarted
          size={'small'}
          loginWithGoogle={loginWithGoogle}
          loginWithFacebook={loginWithFacebook}
          loginWithTwitter={loginWithTwitter}
        />
      )}
      <Col xs={12} sm={12} md={12} lg={12} className={'price-card-description'}>
        <Typography variant={'body2'} style={{ color: colors.primary }}>
          {description}
        </Typography>
      </Col>
    </Row>
  </Col>
);
