import { actionTypes } from '../../config/app-constants';

let defaultListBudgetState = {
  budgets: [],
  totalBudgetedAmount: 0.0,
  budgetsLoaded: false,
  showDeleteAlert: false,
  budgetIdToDelete: ''
};

export default function listBudgetReducer(state = defaultListBudgetState, action) {
  switch (action.type) {
    case actionTypes.listBudgetActionTypes.LOAD_BUDGETS_OK:
      return Object.assign({}, state, action.value);
    case actionTypes.listBudgetActionTypes.DELETE_CLICKED:
      return Object.assign({}, state, action.value);
    case actionTypes.listBudgetActionTypes.BUDGET_DELETED:
      return Object.assign({}, state, action.value);
    case actionTypes.listBudgetActionTypes.BUDGET_DELETE_CANCEL:
      return Object.assign({}, state, action.value);
    default:
      return state;
  }
}
