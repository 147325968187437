import React from 'react';
import { OverviewDetailTransaction } from './OverviewDetailTransaction';
import { colors } from '../../mui/colors';
import moment from 'moment';

export const OverviewDetailCardBody = ({ transactions, currencySymbol }) => (
  <div>
    {transactions.map(t => {
      let amountColor = t.isExpense ? colors.debitColor : colors.creditColor;
      let transactionMoment = moment(t.date);
      return (
        <OverviewDetailTransaction
          key={t.id}
          dayOfMonth={transactionMoment.date()}
          dayOfWeek={transactionMoment.format('ddd').toUpperCase()}
          name={t.name}
          amount={t.amount}
          amountColor={amountColor}
          currencySymbol={currencySymbol}
        />
      );
    })}
  </div>
);
