import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { colors } from '../../components/mui/colors';
import { withStyles } from '@material-ui/core';

export const ManageCategories = ({ value, onChange, labels }) => {
  return (
    <AppBar position="static">
      <StyledTabs value={value} onChange={onChange} centered>
        <StyledTab value="one" label={labels[0]} style={{ marginRight: '7vw' }} />
        <StyledTab value="two" label={labels[1]} />
      </StyledTabs>
    </AppBar>
  );
};

const styleTabs = {
  root: {
    background: colors.primary6Color
  },
  indicator: {
    backgroundColor: colors.primary1Color,
    height: 2
  }
};
export const StyledTabs = withStyles(styleTabs)(Tabs);

const styleTab = {
  root: {
    color: colors.primary1Color
  }
};
export const StyledTab = withStyles(styleTab)(Tab);
