import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { CategoryImage } from '../../../../containers/shared/categoryImage';

export const OverviewRowIconAndCategory = ({ categoryName }) => (
  <Row className="overview-budget-row-header-icon-category">
    <Col xs={1} sm={1} md={1} lg={1}>
      <CategoryImage categoryName={categoryName} w={24} h={24} />
    </Col>
    <Col xsOffset={2} xs={3} sm={3} md={2} lg={3}>
      {categoryName}
    </Col>
  </Row>
);
