import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { OverviewDetailCategoryDaysHeader } from '../OverviewDetailBudgetHeader/OverviewDetailCategoryDaysHeader';
import { OverviewDetailBudgetHeaderItem } from '../OverviewDetailBudgetHeader/OverviewDetailBudgetHeaderItem';
import { MCard } from '../mui/MCard';
import './styles.css';

export const OverviewDetailHeader = ({
  category,
  daysRemaining,
  spentOrEarnedLabel,
  spentOrEarnedValue,
  spentOrEarnedColor,
  currencySymbol
}) => (
  <MCard style={{ marginBottom: '2vh' }}>
    <Row className="overview-detail-header" between="xs">
      <Col xs={4} sm={4} md={4} lg={4}>
        <OverviewDetailCategoryDaysHeader category={category} daysRemaining={daysRemaining} />
      </Col>
      <Col xsOffset={2} xs={4} sm={4} md={4} lg={4}>
        <OverviewDetailBudgetHeaderItem
          label={spentOrEarnedLabel}
          value={spentOrEarnedValue}
          valueColor={spentOrEarnedColor}
          currencySymbol={currencySymbol}
        />
      </Col>
    </Row>
  </MCard>
);
