// todo[mb-149]: This is O(n) vs lookup if getMyCategory returns a map keyed by categoryId
import { transactionCategoryLevelsToLabels } from '../../config/app-constants';

export const getCategoryIdToLabel = (categoryId, userCategories) => {
  let noCategoryIdExist = cId => undefined === cId;
  let categoryIdDeleted = filteredCategories => filteredCategories.length === 0;

  if (noCategoryIdExist(categoryId)) return transactionCategoryLevelsToLabels.ZERO_LEVEL.categoryLabel;

  let categoriesForTransaction = userCategories.filter(uc => uc.id === categoryId);

  if (categoryIdDeleted(categoriesForTransaction)) {
    return transactionCategoryLevelsToLabels.DELETED_LEVEL.categoryLabel;
  }

  return categoriesForTransaction[0].name;
};

export const getSubCategoryIdToLabel = (subCategoryId, subCategories) => {
  let noSubCategoryExist = scId => undefined === scId;
  let subCategoryIdDeleted = filteredSubCategories => filteredSubCategories.length === 0;

  if (noSubCategoryExist(subCategoryId)) return transactionCategoryLevelsToLabels.ZERO_LEVEL.subCategoryLabel;

  let subCategoriesForTransaction = subCategories.filter(sc => sc.id === subCategoryId);
  if (subCategoryIdDeleted(subCategoriesForTransaction)) {
    return transactionCategoryLevelsToLabels.DELETED_LEVEL.subCategoryLabel;
  }
  return subCategoriesForTransaction[0].name;
};

export const getSubCategoriesFor = (categoryName, userCategories) => {
  if (
    categoryName === transactionCategoryLevelsToLabels.ZERO_LEVEL.categoryLabel ||
    categoryName === transactionCategoryLevelsToLabels.DELETED_LEVEL.categoryLabel
  ) {
    return []; // no subCategories for such categoryLabels
  }
  return userCategories.filter(uc => uc.name === categoryName)[0].subCategories;
};

export const categoryNameToCardColor = name => {
  return name === transactionCategoryLevelsToLabels.DELETED_LEVEL.categoryLabel ||
    name === transactionCategoryLevelsToLabels.DELETED_LEVEL.subCategoryLabel
    ? '#FFF0F0'
    : '#FFF';
};
