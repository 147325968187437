import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';

export const InBudgetStatus = ({ amountRemaining, currencySymbol }) => {
  return (
    <Row>
      <Col xsOffset={2} xs={10} sm={10} md={10} lg={10}>
        <span className="in-budget-amount-status">{currencySymbol + parseFloat(amountRemaining).toFixed(2)}</span>
        <span className="in-budget-amount-text"> left</span>
      </Col>
    </Row>
  );
};
