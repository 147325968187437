import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export const AlertDialog = ({open, handleClose, title, onClick, buttonLabel1, buttonLabel2}) => {
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {title}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {buttonLabel1 && <Button onClick={handleClose}>{buttonLabel1}</Button>}
                {buttonLabel2 && <Button onClick={onClick} autoFocus>{buttonLabel2}</Button>}
            </DialogActions>
        </Dialog>
    </div>;
};