import React from 'react';
import PropTypes from 'prop-types';
import MFullScreenDialog from '../../mui/MFullScreenDialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Typography, withStyles } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BackspaceOutlined from '@material-ui/icons/BackspaceOutlined';
import { FlagOutlined } from '@material-ui/icons';
import MChip from '../../mui/MChip';
import Grid from '@material-ui/core/Grid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { colors } from '../../mui/colors';
import { emphasis, robotoFonts } from '../../mui/theme';
import { transactionTypes } from '../../../config/app-constants';
import { roundForDisplay, getTransactionTypeSymbol } from '../../../containers/shared/util';
import { onNumberAdd, onNumberDelete } from '../../../containers/shared/amountScreenWorkflow';

const styles = theme => ({
  root: {
    margin: '0 auto',
    width: '100%',
    maxWidth: 414,
    backgroundColor: theme.palette.background.paper
  },
  calculator: {
    height: '100%'
  },
  numberPad: {
    bottom: '0px',
    position: 'absolute'
  },
  mchips: {
    padding: theme.spacing.unit
  },
  listItem: {
    paddingBottom: theme.spacing.unit * 2
  },
  amount: {
    fontWeight: theme.typography.fontWeightMedium
  }
});

class AmountScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: this.props.amount,
      isExpense: this.props.isExpense,
      type: this.props.isExpense ? transactionTypes[0] : transactionTypes[1],
      currency: this.props.currency,
      account: this.props.account
    };
  }

  onNumberPadClick = number => {
    const newValue = onNumberAdd(this.state.amount, number);
    this.setState({ amount: newValue });
  };

  onDeleteClick = () => {
    const newValue = onNumberDelete(this.state.amount);
    this.setState({ amount: newValue });
  };

  shouldNextButtonDisable = () => {
    const currentValue = parseFloat(this.state.amount);
    if (currentValue === 0.0) return true;
    return currentValue === parseFloat(this.props.amount) && this.state.isExpense === this.props.isExpense;
  };

  onTypeClick = type => {
    const isExpense = type === transactionTypes[0];
    this.setState({ type, isExpense });
  };

  onNextClick = () => {
    const { amount, type } = this.state;
    // because the local state of transaction only works with this variable
    const isExpense = type === transactionTypes[0];
    this.props.onNextClick(amount, isExpense);
  };

  render() {
    const { open, currency, onCurrencyClick, onCloseClick, classes, onNextClick } = this.props;
    // used when called with add/edit transaction
    const { showTypeCurrencyAccount } = this.props;

    const amountToDisplay = showTypeCurrencyAccount
      ? `${getTransactionTypeSymbol(this.state.isExpense)} ${roundForDisplay(this.state.amount)}`
      : `${roundForDisplay(this.state.amount)}`;

    return (
      <MFullScreenDialog title={'Amount'} open={open} onClickClose={onCloseClick} onClickSave={onNextClick}>
        <Grid container direction={'row'} className={classes.calculator}>
          {showTypeCurrencyAccount && (
            <Grid item xs={12}>
              <List component="nav">
                <ListItem>
                  <Grid container justify="flex-end" spacing={8} className={classes.mchips}>
                    {transactionTypes.map((t, key) => {
                      return (
                        <Grid item key={key}>
                          <MChip
                            label={t}
                            chipColor={this.state.type === t ? colors.primary800 : colors.primaryTextColor}
                            fontColor={this.state.type === t ? colors.primaryTextColor : colors.black}
                            onClick={() => this.onTypeClick(t)}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </ListItem>
                {/* todo : enable when Accounts are available */}
                {/*<Account value={account} onClick={onAccountClick} className={classes.listItem} />*/}
                {/*<Divider />*/}
                <Currency value={currency} onClick={onCurrencyClick} className={classes.listItem} />
                <Divider />
              </List>
            </Grid>
          )}
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography align={'center'} variant={'h4'} color={'primary'} className={classes.amount}>
                      {amountToDisplay}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'flex-end' }}>
            <NumberPad
              onClick={this.onNumberPadClick}
              onDeleteClick={this.onDeleteClick}
              onNextClick={this.onNextClick}
              shouldNextButtonDisable={this.shouldNextButtonDisable}
            />
          </Grid>
        </Grid>
      </MFullScreenDialog>
    );
  }
}

AmountScreen.propTypes = {
  amount: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onNextClick: PropTypes.func.isRequired
};

export default withStyles(styles)(AmountScreen);

const NumberPad = ({ onClick, onDeleteClick, onNextClick, shouldNextButtonDisable }) => {
  return (
    <Grid container style={{ border: '0px solid black' }}>
      <NumberPadRowWithStyle numbers={[7, 8, 9]} onClick={onClick} shouldNextButtonDisable={shouldNextButtonDisable} />
      <NumberPadRowWithStyle numbers={[4, 5, 6]} onClick={onClick} shouldNextButtonDisable={shouldNextButtonDisable} />
      <NumberPadRowWithStyle numbers={[1, 2, 3]} onClick={onClick} shouldNextButtonDisable={shouldNextButtonDisable} />
      <NumberPadRowWithStyle
        numbers={[<BackspaceOutlined />, 0, 'NEXT']}
        onClick={onClick}
        onDeleteClick={onDeleteClick}
        onNextClick={onNextClick}
        shouldNextButtonDisable={shouldNextButtonDisable}
      />
    </Grid>
  );
};

const NumberPadRow = props => {
  const { numbers, classes, shouldNextButtonDisable } = props;
  return numbers.map(value => {
    const getOnClickFunction = number =>
      number === 'NEXT'
        ? props.onNextClick()
        : number >= 0 && number <= 9
        ? props.onClick(number)
        : props.onDeleteClick();

    const disable = value === 'NEXT' && shouldNextButtonDisable();
    const opacity = disable ? emphasis.textOnSecondaryDark.disabled : emphasis.textOnSecondaryDark.high;

    return (
      <Grid key={value} xs={4} item>
        <Button
          fullWidth
          disabled={disable}
          variant="text"
          size={'medium'}
          onClick={() => getOnClickFunction(value)}
          className={classes.button}
        >
          <Typography
            variant={'subtitle1'}
            color={'primary'}
            style={{ fontWeight: robotoFonts.bold, opacity: opacity }}
          >
            {value}
          </Typography>
        </Button>
      </Grid>
    );
  });
};

const numberPadStyle = () => ({
  button: {
    backgroundColor: colors.surface,
    height: '8vh',
    borderRadius: 0, // to make buttons sharp edged
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: colors.surface,
        '&:active': {
          backgroundColor: colors.surface
        }
      }
    }
  }
});

const NumberPadRowWithStyle = withStyles(numberPadStyle)(NumberPadRow);

const Currency = ({ value, onClick, className }) => (
  /* todo: Enable button when multiple currencies is available */
  <ListItem /*button*/ onClick={onClick} className={className}>
    <ListItemIcon>
      <FlagOutlined color={'primary'} />
    </ListItemIcon>
    <ListItemSecondaryAction>
      <ListItemText
        primary={
          <Typography variant={'subtitle1'} color={'primary'} style={{ fontWeight: robotoFonts.medium }}>
            {value}
          </Typography>
        }
      />
    </ListItemSecondaryAction>
  </ListItem>
);

// todo: enable when needed. currently, it shows warnings on the console.
/* const Account = ({ value, onClick, className }) => (
  <ListItem button onClick={onClick} className={className}>
    <ListItemIcon>
      <AccountBalanceWalletOutlined color={'primary'} />
    </ListItemIcon>
    <ListItemSecondaryAction>
      <ListItemText
        primary={
          <Typography variant={'subtitle1'} color={'primary'} style={{ fontWeight: robotoFonts.medium }}>
            {value}
          </Typography>
        }
      />
    </ListItemSecondaryAction>
  </ListItem>
);
 */
