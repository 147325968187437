import { actionTypes } from '../../config/app-constants';
import moment from 'moment';
import { sortTransactionsByDateDesc } from '../../containers/shared/util';

let today = moment();
export let defaultState = {
  selectedMonth: today.month(),
  selectedYear: today.year(),
  transactions: [],
  showTransactionDetail: false,
  selectedTransaction: {},
  transactionsLoaded: false
};

export default function listTransactionReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.listTransaction.LOAD_TRANSACTIONS_OK:
      return Object.assign({}, state, action.value);
    case actionTypes.listTransaction.CHANGE_LIST_TRANSACTION_MONTH:
      return Object.assign({}, state, action.value);
    case actionTypes.listTransaction.CHANGE_LIST_TRANSACTION_YEAR:
      return Object.assign({}, state, action.value);
    case actionTypes.listTransaction.SHOW_TRANSACTION_DETAIL:
      return Object.assign({}, state, action.value);
    case actionTypes.listTransaction.BACK_TO_TRANSACTION_DETAIL:
      return Object.assign({}, state, action.value);
    case actionTypes.listTransaction.TRANSACTION_EDITED_OK:
      let updatedTransactionsAfterEdit = state.transactions.map(t =>
        t.id === action.value.transaction.id ? action.value.transaction : t
      );
      return Object.assign({}, state, {
        showTransactionDetail: action.value.showTransactionDetail,
        transactions: sortTransactionsByDateDesc(updatedTransactionsAfterEdit),
        showEditToast: action.value.showEditToast
      });
    case actionTypes.listTransaction.TRANSACTION_DELETED_OK:
      let updatedTransactionsAfterDelete = state.transactions.filter(t => t.id !== action.value.transaction.id);
      return Object.assign({}, state, {
        showTransactionDetail: action.value.showTransactionDetail,
        transactions: updatedTransactionsAfterDelete
      });
    case actionTypes.listTransaction.TRANSACTION_ADDED_OK:
      const newTransaction = action.value.transaction;
      const transactionDate = moment(newTransaction.date);
      if (today.month() === transactionDate.month() && today.year() === transactionDate.year()) {
        let moreTransactions = [...state.transactions, newTransaction];
        return Object.assign({}, state, { transactions: sortTransactionsByDateDesc(moreTransactions) });
      }
      return state;
    default:
      return state;
  }
}
