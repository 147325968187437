import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { colors } from '../../components/mui/colors';

export const DangerWarning = ({ open, handleClose, title, text, onClick, buttonLabel1, buttonLabel2 }) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: colors.debitColor }}>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{buttonLabel1 || 'CANCEL'}</Button>
          <Button
            variant={'contained'}
            onClick={onClick}
            autoFocus
            style={{ backgroundColor: colors.debitColor, color: colors.primaryTextColor }}
          >
            {buttonLabel2 || 'I AGREE'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
