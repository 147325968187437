import React from 'react';
import {Col, Row} from "react-flexbox-grid";
import './styles.css';

export const SubCategoryAndTransactions = ({subCategory, numTransactions}) => (
    <Row start='xs'>
        <Col xs={12} sm={12} md={12} lg={12} className='overview-detail-card-subcategory'>
            {subCategory}
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}  className='overview-detail-card-num-transactions'>
            {numTransactions} Transactions
        </Col>
    </Row>
);