import React from 'react';
import { colors } from '../../../../mui/colors';
import MFullScreenDialog from '../../../../mui/MFullScreenDialog';
import { MButton } from '../../../../mui/MButton';
import { CreateCategory, EnableSubCategory } from '../../../../ManageCategories/CreateCategory';
import Button from '@material-ui/core/Button/Button';
import { AlertDialog } from '../../../../AlertDialog';

//TODO Here categoryName will be passed from reviewTransactionReducer file for the actual category selected.
export const SubCategories = ({
  showSubcategory,
  onSubcategoryClose,
  subCategories,
  onSubCategorySelect,
  showAddSubCategory,
  onAddSubCategory,
  existingCategory,
  onCloseAddSubCategory,
  newSubCategoryName,
  onNewSubCategoryChange,
  onAddSubCategoryDone,
  enableSubCategory,
  onDisableSubCategory,
  confirmDisableSubCategory,
  onCloseDisableSubCategoryDialog,
  onOkDisableSubCategory,
  isDuplicateSubCategory,
  onCloseDuplicateSubCategory
}) => {
  return (
    <MFullScreenDialog
      open={showSubcategory}
      onClickClose={onSubcategoryClose}
      title={'Sub-Category'}
      appBarStyle={SubcategoriesStyle}
      titleStyle={SubcategoriesStyle.title}
    >
      <SubCategoryRow subCategories={subCategories} onClick={onSubCategorySelect} />

      <EnableSubCategory enableSubCategory={enableSubCategory} onEnable={onDisableSubCategory} />

      {!enableSubCategory && (
        <AlertDialog
          open={confirmDisableSubCategory}
          handleClose={onCloseDisableSubCategoryDialog}
          title={'You can enable SUB-CATEGORIES from More -> Manage Categories'}
          onClick={onOkDisableSubCategory}
          buttonLabel1={'CANCEL'}
          buttonLabel2={'OK'}
        />
      )}

      <MButton
        variant={'text'}
        onClick={onAddSubCategory}
        style={{
          color: colors.primary3Color,
          fontWeight: '500',
          marginTop: '20vh'
        }}
      >
        + ADD SUB-CATEGORY
      </MButton>

      {showAddSubCategory && (
        <MFullScreenDialog open={showAddSubCategory} onClickClose={onCloseAddSubCategory} title={'New Sub-Category'}>
          <CreateCategory
            categoryName={existingCategory}
            subCategoryName={newSubCategoryName}
            actionButtonLabel={'DONE'}
            showSubCategoryBoxOnly={true}
            onSubCategoryChange={onNewSubCategoryChange}
            disableActionButton={newSubCategoryName.trim().length === 0}
            onClickActionButton={onAddSubCategoryDone}
            disableCategory={true}
          />
          {isDuplicateSubCategory && (
            <AlertDialog
              open={isDuplicateSubCategory}
              handleClose={onCloseDuplicateSubCategory}
              title={'SUB-CATEGORY already exists'}
              onClick={onCloseDuplicateSubCategory}
              buttonLabel2={'OK'}
            />
          )}
        </MFullScreenDialog>
      )}
    </MFullScreenDialog>
  );
};

const SubcategoriesStyle = {
  backgroundColor: colors.primary1Color,
  title: { fontSize: '95%' }
};

const SubCategoryRow = ({ subCategories, onClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        paddingTop: '2vh'
      }}
    >
      {subCategories.map((subCategory, key) => (
        <div
          key={key}
          style={{
            paddingLeft: '3vw',
            paddingRight: '3vw',
            paddingBottom: '2vh'
          }}
        >
          <Button onClick={() => onClick(subCategory)} style={{ backgroundColor: colors.primary3Color, width: '40vw' }}>
            <div style={{ color: colors.primaryTextColor }}>{subCategory.name}</div>
          </Button>
        </div>
      ))}
    </div>
  );
};
