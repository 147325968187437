import { actionTypes, defaultCurrencyNewUser, emptyString } from '../../config/app-constants';
import { currencyCodeToName } from '../../containers/shared/currencyCodeToName';

const defaultCurrencyState = {
  allCurrencies: [],
  filteredCurrencies: [],
  shouldRenderAllCurrencies: false,
  isLoadingDefaultCurrency: true,
  defaultCurrency: {
    shortName: defaultCurrencyNewUser,
    fullName: currencyCodeToName[defaultCurrencyNewUser]
  },
  searchTerm: emptyString
};

export default function currencyReducer(state = defaultCurrencyState, action) {
  switch (action.type) {
    case actionTypes.currencyActionTypes.SHOW_ALL_CURRENCIES:
      return Object.assign({}, state, { shouldRenderAllCurrencies: action.value.shouldRenderAllCurrencies });

    case actionTypes.currencyActionTypes.CLOSE_ALL_CURRENCIES:
      return Object.assign({}, state, { shouldRenderAllCurrencies: action.value.shouldRenderAllCurrencies });

    case actionTypes.currencyActionTypes.SAVE_DEFAULT_CURRENCY:
      return Object.assign({}, state, {
        shouldRenderAllCurrencies: action.value.shouldRenderAllCurrencies,
        filteredCurrencies: state.allCurrencies,
        searchTerm: emptyString,
        defaultCurrency: {
          shortName: action.value.currencyCode,
          fullName: currencyCodeToName[action.value.currencyCode]
        }
      });
    case actionTypes.currencyActionTypes.LOAD_DEFAULT_CURRENCY_OK:
      return Object.assign({}, state, {
        isLoadingDefaultCurrency: action.value.isLoadingDefaultCurrency,
        defaultCurrency: {
          shortName: action.value.defaultCurrency.length > 1 ? action.value.defaultCurrency : 'SELECT',
          fullName: action.value.defaultCurrency.length > 1 ? currencyCodeToName[action.value.defaultCurrency] : ''
        }
      });
    case actionTypes.currencyActionTypes.LOAD_ALL_CURRENCIES:
      return Object.assign({}, state, action.value);
    case actionTypes.currencyActionTypes.ON_SEARCH_CURRENCY:
      return Object.assign({}, state, action.value);
    default:
      return state;
  }
}
