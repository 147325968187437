import {actionTypes} from "../../config/app-constants";

export function openFeedbackDialog() {
    return {type: actionTypes.moreActionTypes.OPEN_FEEDBACK, value: {openFeedback: true}}
}

export function closeFeedbackDialog(){
    return {type: actionTypes.moreActionTypes.CLOSE_FEEDBACK, value: {openFeedback: false}}
}

export function openManageCategories(){
    return {type: actionTypes.moreActionTypes.OPEN_MANAGE_CATEGORIES, value: {openManageCategories: true}};
}

export function backFromManageCategories(){
    return {type: actionTypes.moreActionTypes.BACK_FROM_MANAGE_CATEGORIES, value: {openManageCategories: false}};
}