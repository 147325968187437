import React from 'react';
import { Grid } from '@material-ui/core';

export const ReviewSection = ({ icon, children }) => (
  <Grid container justify={'space-between'}>
    <Grid item xs={1} style={{ border: '0px solid black' }}>
      {icon}
    </Grid>
    <Grid item xs={10}>
      {children}
    </Grid>
  </Grid>
);
