exports.colors = {
  primary: '#2e4369',
  primary800: '#3D5E8A',
  primary700: '#456E9C',
  primary600: '#507FAD',
  primary500: '#598CB9',
  primary400: '#699BC2',

  secondary: '#00C556',
  secondary200: '#98E3AD',

  surface: '#DFF5FF',
  lightBlue: '#75D3FD',
  black: '#000000',
  red: '#ef9a9a',

  primary1Color: '#38527C',
  primary2Color: '#416094',
  primary3Color: '#2DC1FC',
  primary4Color: '#39CF71',
  primary5Color: '#28b862',
  primary6Color: '#DCF4FD',
  primaryTextColor: '#ffffff',
  debitColor: '#D50000',
  creditColor: '#39CF71',
  blackColor: '#000000',
  rightArrowColor: '#9B9B9B',
  budgetExceededColor: '#D32F2F',
  grayColor: '#757575',
  disableColor: '#9B9B9B',
  yellow: '#f16821',
  primaryBlue: '#2E4369'
};
