import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const styles = {
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

/**
 * actionToolbar is what save, edit, delete will be all about
 */
class MFullScreenDialog extends React.Component {
  render() {
    const { title, classes, open, onClickClose, actionToolbar } = this.props;
    return (
      <Dialog fullScreen open={open} onClose={onClickClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={onClickClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {title}
            </Typography>
            {actionToolbar}
          </Toolbar>
        </AppBar>
        {/* The content under FullScreen Dialog will appear here */}
        {this.props.children}
      </Dialog>
    );
  }
}

MFullScreenDialog.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClickClose: PropTypes.func.isRequired
};

export default withStyles(styles)(MFullScreenDialog);
