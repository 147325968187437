import React from 'react';
import Dialog from "@material-ui/core/Dialog/Dialog";
import {withStyles} from "@material-ui/core";

const styles = {
    paper: {
        paddingBottom: '10vh',
    }
};

export const MDialog = withStyles(styles)(({children, open, onClose, classes}) => (
    <Dialog open={open} onClose={onClose} classes={classes}>
        {children}
    </Dialog>
));