import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Feature } from './Feature';
import { features } from './features-less';
import { colors } from '../../../mui/colors';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

export default class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  render() {
    return (
      <div>
        <DefaultFeaturesView
          features={features.slice(0, 3)}
          visible={this.state.visible}
          onClick={this.toggleVisibility}
        />

        {this.state.visible && (
          <ShowAllFeatures features={features.slice(3, features.length)} onClick={this.toggleVisibility} />
        )}
      </div>
    );
  }
}

const DefaultFeaturesView = ({ features, visible, onClick }) => (
  <Row center={'xs'}>
    <Col xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '6vh', paddingBottom: '6vh' }}>
      <Typography variant={'h5'} style={{ color: colors.primary }}>
        Features
      </Typography>
    </Col>
    <Col xs={12} sm={12} md={12} lg={12}>
      <RowOfIcons features={features} />
    </Col>
    {!visible && (
      <Col xs={12} sm={12} md={12} lg={12}>
        <ShowMoreLessButton label="More" onClick={onClick} />
      </Col>
    )}
  </Row>
);

function splitIntoSubArray(arr, count) {
  let newArray = [];
  while (arr.length > 0) {
    newArray.push(arr.splice(0, count));
  }
  return newArray;
}

const ShowAllFeatures = ({ features, onClick }) => (
  <Row style={{ width: '100%' }} center={'xs'}>
    <Col xs={12} sm={12} md={12} lg={12}>
      {splitIntoSubArray(features, 3).map((featuresRow, key) => (
        <RowOfIcons features={featuresRow} key={key} />
      ))}
    </Col>
    <Col xs={12} sm={12} md={12} lg={12}>
      <ShowMoreLessButton label="Less" onClick={onClick} />
    </Col>
  </Row>
);

const ShowMoreLessButton = ({ label, onClick }) => (
  <Button variant="contained" style={{ backgroundColor: '#75D3FD' }} onClick={onClick}>
    <div style={{ color: colors.black }}>{label}</div>
  </Button>
);

const RowOfIcons = ({ features }) => (
  <Row around={'xs'} style={{ paddingTop: '3vh', paddingBottom: '3vh' }}>
    {features.map((feature, key) => {
      return (
        <Col xs={6} sm={3} md={3} lg={3} key={key} style={{ paddingBottom: '3vh' }}>
          <Feature icon={feature.icon} title={feature.title} description={feature.description} />
        </Col>
      );
    })}
  </Row>
);
