import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import green from '@material-ui/core/colors/green';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { colors } from '../../mui/colors';
import { DeleteIcon, SuccessCheckCircleIcon, NewReleaseIcon } from '../CustomIcons';
import { MButton } from '../MButton';

export const MSnackBar = ({ open, message, okLabel, onOkClick, onCloseClick, variant, autoHide }) => {
  return (
    <Snackbar
      open={open}
      onClose={onCloseClick}
      autoHideDuration={autoHide ? 4000 : null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <MySnackbarContentWrapper
        okLabel={okLabel}
        onOkClick={onOkClick}
        onCloseClick={onCloseClick}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
};

const variantIcon = {
  success: SuccessCheckCircleIcon,
  newRelease: NewReleaseIcon,
  delete: DeleteIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  newRelease: {
    backgroundColor: colors.primary3Color
  },
  delete: {
    backgroundColor: colors.debitColor
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onCloseClick, onOkClick, variant, okLabel, ...other } = props;
  const Icon = variantIcon[variant];

  const action = (
    <MButton key={'button'} variant={'text'} onClick={onOkClick} fullWidth={false}>
      <p style={{ color: colors.blackColor }}>{okLabel}</p>
    </MButton>
  );

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon />
          {message}
        </span>
      }
      action={[
        action,
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onCloseClick}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'newRelease', 'delete']).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);
