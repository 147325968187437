import React from 'react';
import App from '../../../containers/app';
import * as overviewActions from '../../../actions/overview/overviewActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrencySymbol, isEmptyObject, userIdFromCache } from '../../shared/util';
import OverviewDetail from '../detail';
import * as AppFooterActions from '../../../actions/app/appFooterActions';
import { OverviewPage } from '../../../components/OverviewPage';
import MonthYear from '../../../components/MonthYear';
import { MLinearProgress } from '../../../components/mui/MLinearProgress';
import Onboarding from '../../onboarding';
import * as AppHeaderActions from '../../../actions/app/appHeaderActions';

class Overview extends React.Component {
  componentWillMount() {
    this.props.highlightOverview();
  }

  componentDidMount() {
    let { selectedYear, selectedMonth } = this.props.overviewState;
    this.loadOverviewFor(selectedYear, selectedMonth, userIdFromCache());
  }

  onYearChange = event => {
    let selectedYear = event.target.value;
    this.props.onOverviewYearChange(selectedYear);
    this.loadOverviewFor(selectedYear, this.props.overviewState.selectedMonth, userIdFromCache());
  };

  onMonthChange = event => {
    let selectedMonth = event.target.value;
    this.props.onOverviewMonthChange(selectedMonth);
    this.loadOverviewFor(this.props.overviewState.selectedYear, selectedMonth, userIdFromCache());
  };

  loadOverviewFor = (year, month, userId) => {
    this.props.loadOverview(year, month, userId);
  };

  getSymbol = () => {
    return getCurrencySymbol(this.props.currencies.defaultCurrency.shortName);
  };

  render() {
    let {
      totalSpent,
      totalEarned,
      remainingDaysInMonth,
      spendingByCategory,
      showDetailPage,
      overviewLoaded,
      selectedYear,
      selectedMonth,
      userCategories
    } = this.props.overviewState;

    let showEmptyPlaceholder = overviewLoaded && isEmptyObject(spendingByCategory);
    return (
      <App showAvatar={true} title={'Overview'}>
        <MonthYear
          year={selectedYear}
          month={selectedMonth}
          onYearChange={this.onYearChange}
          onMonthChange={this.onMonthChange}
        />

        {!overviewLoaded && <MLinearProgress />}
        {overviewLoaded && !showDetailPage && (
          <OverviewPage
            totalSpent={totalSpent}
            totalEarned={totalEarned}
            remainingDaysInMonth={remainingDaysInMonth}
            spendingByCategory={spendingByCategory}
            userCategories={userCategories}
            onClick={this.props.showDetailPage}
            showEmptyPlaceholder={showEmptyPlaceholder}
            currencySymbol={this.getSymbol()}
          />
        )}
        {overviewLoaded && showDetailPage && <OverviewDetail />}
        {this.props.appHeaderState.showOnBoard && (
          <Onboarding showOnBoard={this.props.appHeaderState.showOnBoard} onSkip={this.props.onCloseOnBoarding} />
        )}
        {localStorage.getItem('isNewUser') && <Onboarding />}
      </App>
    );
  }
}

const mapStateToProps = state => {
  return {
    overviewState: state.overviewReducer,
    appHeaderState: state.appHeaderReducer,
    currencies: state.currencyReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadOverview: bindActionCreators(overviewActions.loadOverview, dispatch),
    showDetailPage: bindActionCreators(overviewActions.showOverviewDetail, dispatch),
    onOverviewYearChange: bindActionCreators(overviewActions.onOverviewYearChange, dispatch),
    onOverviewMonthChange: bindActionCreators(overviewActions.onOverviewMonthChange, dispatch),
    highlightOverview: bindActionCreators(AppFooterActions.highlightOverview, dispatch),
    onCloseOnBoarding: bindActionCreators(AppHeaderActions.closeOnBoarding, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
