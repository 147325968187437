import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { TagLine } from '../../TagLine';
import GetStarted from '../../GettingStarted';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../../mui/colors';

export const CallToAction = ({ loginWithGoogle, loginWithFacebook, loginWithTwitter }) => (
  <Row style={{ width: '100%' }} center={'xs'}>
    <Col xs={12} sm={12} md={12} lg={12}>
      <TagLine />
    </Col>

    <Col xs={12} sm={12} md={12} lg={12}>
      <GetStarted
        signIn={false}
        loginWithGoogle={loginWithGoogle}
        loginWithFacebook={loginWithFacebook}
        loginWithTwitter={loginWithTwitter}
      />
    </Col>

    <Col xs={12} sm={12} md={12} lg={12}>
      <Typography variant={'body2'} style={{ color: colors.primaryTextColor, paddingTop: '4vh' }}>
        Or
      </Typography>
    </Col>

    <Col xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '4vh' }}>
      <GetStarted
        signIn={true}
        loginWithGoogle={loginWithGoogle}
        loginWithFacebook={loginWithFacebook}
        loginWithTwitter={loginWithTwitter}
      />
    </Col>
  </Row>
);
