import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

const styles = {
  avatar: { margin: 10 }
};
function ImageAvatar(props) {
  const { classes, src } = props;
  return (
    <Grid>
      <Avatar alt="User" src={src} className={classes.avatar} />
    </Grid>
  );
}
ImageAvatar.propTypes = {
  classes: PropTypes.object.isRequired
};

export const UserAvatar = withStyles(styles)(ImageAvatar);
