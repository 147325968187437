import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { MButton } from '../../mui/MButton';
import './styles.css';
import Dialog from '@material-ui/core/Dialog';
import { colors } from '../../mui/colors';

export const OnBoardCard = ({
  title,
  icon,
  description,
  onClickNext,
  onClickPrevious,
  disablePrevious,
  disableNext,
  showOnBoard,
  onSkip
}) => {
  return (
    <Dialog open={showOnBoard}>
      <Row middle="xs">
        <Col xs={12}>
          <Row className="onBoard-card-container">
            <Col xs={12}>
              <Row center="xs" className="onBoard-title-container">
                <Col xs={12}>{title}</Col>
              </Row>

              <Row center="xs">
                <Col xs={12}>
                  <img alt="" src={icon} style={{ width: '20vw', height: '20vh' }} />
                </Col>
              </Row>

              <Row center="xs" className="description-container">
                <Col xs={12}>{description}</Col>
              </Row>

              <Row around="xs">
                <Col xs={4}>
                  <MButton
                    variant="text"
                    style={{ color: colors.primary3Color }}
                    onClick={() => onClickPrevious(title)}
                    disabled={disablePrevious}
                  >
                    <div>BACK</div>
                  </MButton>
                </Col>

                <Col xs={4}>
                  <MButton variant="text" style={{ color: colors.primary3Color }} onClick={onSkip}>
                    <div>SKIP</div>
                  </MButton>
                </Col>

                <Col xs={4}>
                  <MButton
                    variant="text"
                    style={{ color: colors.primary3Color }}
                    onClick={disableNext ? onSkip : () => onClickNext(title)}
                  >
                    <div>{disableNext ? 'DONE' : 'NEXT'}</div>
                  </MButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
};
