import React from 'react';
import { Drawer, Grid, Typography, List, ListItem, ListItemText, withStyles, Divider } from '@material-ui/core';
import { robotoFonts, emphasis } from '../mui/theme';
import { colors } from '../mui/colors';

const BottomDialogStyles = theme => ({
  message: {
    fontWeight: robotoFonts.medium,
    opacity: emphasis.textOnSurfaceBlack.medium,
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit
  }
});
const BottomDialog = ({ open, message, okLabel, onOk, cancelLabel, onCancel, classes }) => (
  <Drawer anchor="bottom" open={open} onClose={onCancel}>
    <Grid container style={{ backgroundColor: colors.surface }}>
      <Grid item xs={12} style={{ alignSelf: 'center', border: '0px solid red' }}>
        <Typography variant={'caption'} className={classes.message}>
          {message}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List component="nav">
          <ListItem button onClick={onOk}>
            <ListItemText
              primary={
                <Typography variant={'body2'} color={'textPrimary'}>
                  {okLabel}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem button onClick={onCancel}>
            <ListItemText
              primary={
                <Typography variant={'body2'} color={'textPrimary'}>
                  {cancelLabel}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  </Drawer>
);
export default withStyles(BottomDialogStyles)(BottomDialog);
