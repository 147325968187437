import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { colors } from '../../../../mui/colors';
import Typography from '@material-ui/core/Typography';

export const Feature = ({ icon, title, description }) => (
  <Row id="features">
    <Col xs={12} sm={12} md={12} lg={12}>
      <img src={icon} alt={title} className="feature-icon" />
    </Col>
    <Col xs={12} sm={12} md={12} lg={12} className="feature-title">
      <Typography variant={'subtitle2'} style={{ color: colors.primary }}>
        {title}
      </Typography>
    </Col>
    <Col xs={12} sm={12} md={12} lg={12} className="feature-description">
      <Typography variant={'body2'} style={{ color: colors.primary }}>
        {description}
      </Typography>
    </Col>
  </Row>
);
