import React from 'react';
import { MyCategories } from '../../../components/ManageCategories/MyCategories';
import { AlertDialog } from '../../../components/AlertDialog';
import { deleteMyCategory, editMyCategory, getMyCategories } from '../../../db';
import { isEmptyObject, userIdFromCache } from '../../shared/util';
import uuid from 'uuid';
import { DangerWarning } from '../../../components/DangerWarning';
import { maxSubCategories } from '../../../config/app-constants';

export default class MyCategoriesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'one',
      subCategoriesEnabled: false,
      disableSaveButton: true,
      showEditPage: false,
      showDeleteDialog: false,
      subCategoryName: '',
      categoryName: '',
      myCategories: [],
      subCategoriesOfCategory: [],
      showSubCategorySwitch: true,
      categoryToEdit: {},
      warnEmptySubCategory: false,
      warnDuplicate: false,
      warnSubCategoriesExceed: false,
      warnSubCategoryDelete: false,
      subCategoryToRemove: {},
      myCategoriesLoaded: false
    };
  }

  componentDidMount() {
    getMyCategories(userIdFromCache()).then(categories => {
      this.setState({ myCategories: categories, myCategoriesLoaded: true });
    });
  }

  onClickSave = () => {
    if (this.isDuplicateCategory()) {
      this.setState({ showEditPage: true, warnDuplicate: true });
    } else if (this.state.subCategoriesOfCategory.length > maxSubCategories) {
      this.setState({ showEditPage: true, warnSubCategoriesExceed: true });
    } else if (this.state.subCategoriesEnabled && this.state.subCategoriesOfCategory.length === 0) {
      this.setState({ showEditPage: true, warnEmptySubCategory: true });
    } else {
      let editedCategory = {
        id: this.state.categoryToEdit.id,
        name: this.state.categoryName,
        subCategories: this.state.subCategoriesOfCategory,
        subCategoriesEnabled: this.state.subCategoriesEnabled,
        modifiedAt: new Date()
      };
      editMyCategory(editedCategory);
      let updatedMyCategories = this.state.myCategories.map(category =>
        category.id === this.state.categoryToEdit.id ? editedCategory : category
      );
      this.setState({
        showEditPage: false,
        myCategories: updatedMyCategories
      });
    }
  };

  /**
   * Is user creating a duplicate category
   * @returns {boolean}
   */
  isDuplicateCategory = () => {
    return (
      this.state.categoryToEdit.name !== this.state.categoryName &&
      this.state.myCategories.filter(category => category.name === this.state.categoryName).length > 0
    );
  };

  onEnableSubCategory = enableSubCategory => {
    this.setState({ subCategoriesEnabled: enableSubCategory });
  };

  onCategoryChange = categoryName => {
    this.setState({ categoryName: categoryName.toUpperCase() });
  };

  onAddSubCategory = () => {
    let isDuplicateSubcategory =
      this.state.subCategoriesOfCategory.filter(subCategory => subCategory.name === this.state.subCategoryName).length >
      0;
    if (!isDuplicateSubcategory) {
      const subCategory = {
        id: uuid(),
        name: this.state.subCategoryName,
        createdAt: new Date()
      };
      this.setState({
        subCategoriesOfCategory: [...this.state.subCategoriesOfCategory, subCategory],
        subCategoryName: ''
      });
    }
  };

  onSubCategoryChange = subCategoryName => {
    this.setState({ subCategoryName: subCategoryName.toUpperCase() });
  };

  onRemoveSubCategory = subCategoryToRemove => {
    this.setState({ subCategoryToRemove: subCategoryToRemove, warnSubCategoryDelete: true });
  };

  onRemoveSubCategoryOk = () => {
    let remainingSubCategories = this.state.subCategoriesOfCategory.filter(
      subCategory => subCategory.id !== this.state.subCategoryToRemove.id
    );
    this.setState({
      subCategoriesOfCategory: remainingSubCategories,
      subCategoriesEnabled: remainingSubCategories.length > 0,
      warnSubCategoryDelete: false
    });
  };

  onRemoveSubCategoryCancel = () => {
    this.setState({ warnSubCategoryDelete: false });
  };

  onClickEdit = category => {
    this.setState({
      showEditPage: true,
      categoryName: category.name,
      subCategoriesOfCategory: category.subCategories,
      subCategoriesEnabled: category.subCategoriesEnabled,
      categoryToEdit: category
    });
  };

  onCloseEdit = () => {
    this.setState({ showEditPage: false, categoryName: '', subCategoriesOfCategory: '', subCategoriesEnabled: false });
  };

  disableActionButton = () => {
    return (
      this.state.categoryToEdit.name === this.state.categoryName &&
      this.state.categoryToEdit.subCategoriesEnabled === this.state.subCategoriesEnabled &&
      this.state.subCategoriesOfCategory.length === this.state.categoryToEdit.subCategories.length &&
      this.state.categoryToEdit.subCategories.filter(
        subCategory => !this.state.subCategoriesOfCategory.includes(subCategory)
      ).length === 0
    );
  };

  onDelete = category => {
    this.setState({ showDeleteDialog: true, categoryToEdit: category });
  };

  onDeleteCancel = () => {
    this.setState({ showDeleteDialog: false });
  };

  onDeleteOk = () => {
    deleteMyCategory(this.state.categoryToEdit);
    let updatedMyCategories = this.state.myCategories.filter(category => category.id !== this.state.categoryToEdit.id);
    this.setState({ showDeleteDialog: false, myCategories: updatedMyCategories });
  };

  onWarningClose = () => {
    this.setState({ warnEmptySubCategory: false });
  };

  onWarnDuplicateClose = () => {
    this.setState({ warnDuplicate: false });
  };

  onWarnSubCategoryExceedClose = () => {
    this.setState({ warnSubCategoriesExceed: false });
  };

  render() {
    let showEmptyMyCategories = this.state.myCategoriesLoaded && isEmptyObject(this.state.myCategories);
    return (
      <>
        <MyCategories
          categoryName={this.state.categoryName}
          onCategoryChange={this.onCategoryChange}
          enableSubCategory={this.state.subCategoriesEnabled}
          onEnableSubCategory={this.onEnableSubCategory}
          onAddSubCategory={this.onAddSubCategory}
          subCategoryName={this.state.subCategoryName}
          onSubCategoryChange={this.onSubCategoryChange}
          onClickActionButton={this.onClickSave}
          disableActionButton={this.disableActionButton()}
          actionButtonLabel={'SAVE'}
          onRemoveSubCategory={this.onRemoveSubCategory}
          showEditPage={this.state.showEditPage}
          onEdit={this.onClickEdit}
          onCloseEdit={this.onCloseEdit}
          myCategories={this.state.myCategories}
          subCategories={this.state.subCategoriesOfCategory}
          showSubCategorySwitch={this.state.showSubCategorySwitch}
          onDelete={this.onDelete}
          myCategoriesLoaded={this.state.myCategoriesLoaded}
          showEmptyMyCategories={showEmptyMyCategories}
        />

        {this.state.showDeleteDialog && (
          <DangerWarning
            open={this.state.showDeleteDialog}
            handleClose={this.onDeleteCancel}
            title={'Delete Category'}
            text={'Transactions or budgets using this category will be categorized as UNKNOWN. Do you want to delete ?'}
            onClick={this.onDeleteOk}
            buttonLabel1={'CANCEL'}
            buttonLabel2={'DELETE'}
          />
        )}

        {this.state.warnEmptySubCategory && (
          <AlertDialog
            open={this.state.warnEmptySubCategory}
            handleClose={this.onWarningClose}
            title={'Add atLeast 1 SUB_CATEGORY or disable sub-categories'}
            onClick={this.onWarningClose}
            buttonLabel2={'OK'}
          />
        )}

        {this.state.warnDuplicate && (
          <AlertDialog
            open={this.state.warnDuplicate}
            handleClose={this.onWarnDuplicateClose}
            title={'CATEGORY already exists'}
            onClick={this.onWarnDuplicateClose}
            buttonLabel2={'OK'}
          />
        )}

        {this.state.warnSubCategoriesExceed && (
          <AlertDialog
            open={this.state.warnSubCategoriesExceed}
            handleClose={this.onWarnSubCategoryExceedClose}
            title={'You exceeded maximum number of SUB-CATEGORIES allowed'}
            onClick={this.onWarnSubCategoryExceedClose}
            buttonLabel2={'OK'}
          />
        )}

        {this.state.warnSubCategoryDelete && (
          <DangerWarning
            open={this.state.warnSubCategoryDelete}
            handleClose={this.onRemoveSubCategoryCancel}
            title={'Delete Sub-Category'}
            text={'Transactions using this sub-category will not have any sub-category. Do you want to delete ?'}
            onClick={this.onRemoveSubCategoryOk}
            buttonLabel1={'CANCEL'}
            buttonLabel2={'DELETE'}
          />
        )}
      </>
    );
  }
}
