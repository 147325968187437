import { actionTypes } from '../../config/app-constants';
import { getRemainingDaysInMonth } from '../../containers/shared/util';

let today = new Date();
let defaultOverviewState = {
  selectedMonth: today.getMonth(),
  selectedYear: today.getFullYear(),
  remainingDaysInMonth: getRemainingDaysInMonth(),
  overviewLoaded: false,
  totalSpent: 0.0,
  totalEarned: 0.0,
  // the structure is created in overviewActions.js
  spendingByCategory: {},
  showDetailPage: false,
  showDetails: {
    category: '',
    logo: '',
    isBudgetedCategory: false,
    budgeted: 0.0,
    spent: 0.0,
    earned: 0.0,
    // the structure is created in overviewActions.js
    spendingBySubCategory: {}
  }
};

export default function overviewReducer(state = defaultOverviewState, action) {
  switch (action.type) {
    case actionTypes.overviewActionTypes.OVERVIEW_LOADED_OK:
      return Object.assign({}, state, action.value);
    case actionTypes.overviewActionTypes.SHOW_OVERVIEW_DETAIL:
      return Object.assign({}, state, action.value);
    case actionTypes.overviewActionTypes.CHANGE_OVERVIEW_YEAR:
      return Object.assign({}, state, action.value);
    case actionTypes.overviewActionTypes.CHANGE_OVERVIEW_MONTH:
      return Object.assign({}, state, action.value);
    default:
      return state;
  }
}
