import React from 'react';
import { Card, CardHeader } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';

export const BudgetFrequency = ({ isMonthlyLabel, onChangeFrequency }) => {
  return (
    <Card style={styles.frequencyStyles}>
      <CardHeader subtitle="REPEATS MONTHLY" />
      <div className="repeat-monthly-toggle-container">
        <FormGroup row>
          <FormControlLabel
            control={<Switch checked={isMonthlyLabel === 'ON'} onChange={event => onChangeFrequency(event)} />}
            label={isMonthlyLabel}
          />
        </FormGroup>
      </div>
    </Card>
  );
};

const styles = {
  frequencyStyles: {
    height: '20vh'
  }
};
