import React from 'react';
import { withRouter } from 'react-router';
import { closeAddTransactionPage, showNotification } from '../../../actions/app/appFooterActions';
import BottomDialogStyled from '../../../components/BottomDialogStyled';
import MFullScreenDialog from '../../../components/mui/MFullScreenDialog';
import AmountScreen from '../../../components/Transactions/Add/AmountScreen';
import { Category } from '../../../components/Transactions/Add/Category';
import ReviewScreen from '../../../components/Transactions/Add/ReviewScreen';
import {
  actionOnAddTransaction,
  actionOnEditTransaction,
  actionTypes,
  defaultTransactionName,
  notifications,
  Routes,
  snackbarVariant,
  transactionCategoryFieldToDelete,
  transactionTypes
} from '../../../config/app-constants';
import {
  addTransaction,
  editMyCategory,
  fetchUser,
  getDefaultCategories,
  getMyCategories,
  saveCategory,
  updateNumTransactionsAndMonth,
  updateTransaction,
  updateTransactionDeleteCategoryId,
  updateTransactionDeleteSubCategoryId
} from '../../../db';
import { store } from '../../../index';
import {
  constructNewCategory,
  constructNewSubCategory,
  constructNewTransaction,
  constructUpdatedTransaction,
  findMatchByName,
  getActionOnEditTransaction,
  getActionOnSubmit,
  getTransactionCategoryFieldToDelete,
  getUpdatedCategory,
  isLocalAndRemoteTransactionSame,
  isSuggestedCategory,
  shouldDeleteCategoryId,
  shouldDeleteSubCategoryId
} from '../../shared/transactionsWorkflow';
import { isEmptyString, userIdFromCache, filterUniqueCategories } from '../../shared/util';

const defaultHideAllPages = {
  showAmountPage: false,
  showReviewPage: false,
  showCategoryPage: false,
  showSubcategory: false
};
const defaultLocalTransaction = currency => ({
  userId: userIdFromCache(),
  showAmountScreen: true,
  openDiscardTransactionDialog: false,
  amount: '0.0',
  isExpense: true,
  name: defaultTransactionName,
  currency: currency,
  // todo: get it from user's default expense account
  account: 'CASH',
  transactionDate: new Date(),
  showAmountPage: true,
  showSubCategory: false,
  showAddCategory: false,
  showAddSubCategory: false,
  enableSubCategory: true,
  confirmDisableSubCategory: false,
  isDuplicateSubCategory: false,
  isDuplicateCategory: false,
  showDeleteTransactionAlert: false,
  showEmptyCategories: false,
  tabValue: 'one',
  userCategories: [],
  suggestedCategories: [],
  subCategories: [],
  categoryName: '',
  subCategoryName: '',
  selectedCategory: {},
  selectedSubCategory: {}
});

class AddEdit extends React.Component {
  constructor(props) {
    super(props);
    this.nameTextRef = React.createRef();
    this.state = props.editTransaction
      ? this.getEditTransactionState(props.remoteTransaction)
      : this.getNewTransactionState();
  }

  getNewTransactionState = () => ({
    remote: {},
    local: { ...defaultLocalTransaction(this.props.currency) }
  });

  getEditTransactionState = remoteTransaction => {
    const localState = Object.assign(
      {},
      { ...defaultLocalTransaction(remoteTransaction.currency) },
      { ...remoteTransaction },
      { ...defaultHideAllPages },
      { showReviewPage: true }
    );
    return Object.assign({}, { local: localState }, { remote: remoteTransaction });
  };

  SharedActions = {
    updateLocalState: keyValuePairsAsObject => {
      this.setState(
        (state /*, props*/) => ({
          local: Object.assign({}, state.local, keyValuePairsAsObject)
        }),
        () => {}
      );
    },
    resetAndLoadTransactions: () => {
      let localState = Object.assign(
        {},
        { ...defaultLocalTransaction(this.props.currency) },
        { ...defaultHideAllPages }
      );
      this.SharedActions.updateLocalState({ ...localState });

      // notify AppFooter that AddTransaction is now closed.
      store.dispatch(closeAddTransactionPage());

      this.props.history.push(Routes.transactions);
    }
  };

  AmountActions = {
    onTypeClick: type => {
      const isExpense = type === transactionTypes[0];
      this.SharedActions.updateLocalState({ isExpense });
    },
    onAmountClose: () => {
      this.SharedActions.updateLocalState({ showAmountPage: false });

      if (!this.state.local.showReviewPage) {
        // notify AppFooter that AddTransaction is now closed.
        store.dispatch(closeAddTransactionPage());
      }
    },
    onCurrencyClick: () => console.log('show all currencies component from bottom'),
    onAccountClick: () => console.log('show all accounts component from bottom'),
    onNextClick: (amount, isExpense) =>
      this.SharedActions.updateLocalState({ showReviewPage: true, showAmountPage: false, amount: amount, isExpense })
  };

  ReviewActions = {
    onAmountClick: () => this.SharedActions.updateLocalState({ showAmountPage: true }),
    onDateClick: newDate => this.SharedActions.updateLocalState({ transactionDate: newDate }),
    onCloseClick: () => this.SharedActions.updateLocalState({ openDiscardTransactionDialog: true }),
    onTransactionDiscardCancel: () => this.SharedActions.updateLocalState({ openDiscardTransactionDialog: false }),
    onTransactionDiscardOk: () => {
      if (this.props.editTransaction) {
        this.props.onEditTransactionDiscard();
      } else {
        const newState = Object.assign({}, defaultLocalTransaction(this.props.currency), defaultHideAllPages);
        this.SharedActions.updateLocalState({ ...newState });
        // notify AppFooter that AddTransaction is now closed.
        store.dispatch(closeAddTransactionPage());
      }
    },
    addTransactionAndUpdateFreeTransactionsRemaining: transaction => {
      addTransaction(transaction)
        .then(transactionId => {
          let transactionUpdatedWithId = Object.assign({}, { ...transaction }, { id: transactionId });
          store.dispatch({
            type: actionTypes.listTransaction.TRANSACTION_ADDED_OK,
            value: { transaction: transactionUpdatedWithId }
          });
          return fetchUser(transaction.userId);
        })
        .then(userDocument => {
          let transactionsSoFar = userDocument.data().account.remainingTransactionThisMonth;
          return updateNumTransactionsAndMonth(transaction.userId, transactionsSoFar - 1);
        });
    },
    createNewCategoryAndTransaction: (selectedCategory, subCategories, subCategoriesEnabled, transaction) => {
      let newUserCategory = isEmptyString(selectedCategory.id)
        ? selectedCategory
        : constructNewCategory(selectedCategory.name, subCategories, subCategoriesEnabled, false);
      saveCategory(newUserCategory).then(categoryId => {
        transaction.categoryId = categoryId;
        this.ReviewActions.addTransactionAndUpdateFreeTransactionsRemaining(transaction);
        this.SharedActions.resetAndLoadTransactions();
      });
    },
    editCategoryAndAddTransaction: (selectedCategory, subCategories, subCategoriesEnabled, transaction) => {
      let editedCategory = getUpdatedCategory(selectedCategory, subCategories, subCategoriesEnabled);
      editMyCategory(editedCategory);
      this.ReviewActions.addTransactionAndUpdateFreeTransactionsRemaining(transaction);
      this.SharedActions.resetAndLoadTransactions();
    },
    onSaveClick: () => {
      if (this.props.editTransaction) this.ReviewActions.updateTransaction();
      else this.ReviewActions.addTransaction();
    },
    onTransactionUpdateSuccess: transaction => {
      return {
        type: actionTypes.listTransaction.TRANSACTION_EDITED_OK,
        value: { showTransactionDetail: false, transaction }
      };
    },
    addTransaction: () => {
      let selectedCategory = this.state.local.selectedCategory;
      let selectedSubCategory = this.state.local.selectedSubCategory;
      let subCategories = this.state.local.subCategories;
      let subCategoriesEnabled = this.state.local.enableSubCategory;
      let userCategories = this.state.local.userCategories;
      let suggestedCategories = this.state.local.suggestedCategories;
      let action = getActionOnSubmit(
        userCategories,
        selectedCategory,
        subCategories,
        subCategoriesEnabled,
        suggestedCategories
      );
      let transaction = constructNewTransaction(
        this.state.local.name,
        this.state.local.amount,
        this.state.local.transactionDate,
        this.state.local.isExpense,
        this.state.local.currency,
        selectedCategory,
        subCategoriesEnabled,
        selectedSubCategory
      );
      if (action === actionOnAddTransaction.addCategoryAddTransaction) {
        this.ReviewActions.createNewCategoryAndTransaction(
          selectedCategory,
          subCategories,
          subCategoriesEnabled,
          transaction
        );
      } else if (action === actionOnAddTransaction.editCategoryAddTransaction) {
        this.ReviewActions.editCategoryAndAddTransaction(
          selectedCategory,
          subCategories,
          subCategoriesEnabled,
          transaction
        );
      } else if (action === actionOnAddTransaction.onlyAddTransaction) {
        this.ReviewActions.addTransactionAndUpdateFreeTransactionsRemaining(transaction);
        this.SharedActions.resetAndLoadTransactions();
      }
      store.dispatch(showNotification(notifications.addTransaction, snackbarVariant.SUCCESS));
    },
    onAddCategoryAndEditTransaction: (category, transaction, deleteSubCategoryId) => {
      let categoryFieldToDelete = getTransactionCategoryFieldToDelete(false, deleteSubCategoryId);

      if (categoryFieldToDelete === transactionCategoryFieldToDelete.NO_DELETE) {
        saveCategory(category).then(categoryId => {
          transaction.categoryId = categoryId;
          updateTransaction(transaction);
        });
      } else if (categoryFieldToDelete === transactionCategoryFieldToDelete.SUBCATEGORY_ONLY) {
        saveCategory(category).then(categoryId => {
          transaction.categoryId = categoryId;
          updateTransactionDeleteSubCategoryId(transaction);
        });
      }
      store.dispatch(this.ReviewActions.onTransactionUpdateSuccess(transaction));
    },
    onEditCategoryAndEditTransaction: (category, transaction, deleteSubCategoryId) => {
      let categoryFieldToDelete = getTransactionCategoryFieldToDelete(false, deleteSubCategoryId);

      if (categoryFieldToDelete === transactionCategoryFieldToDelete.NO_DELETE) {
        editMyCategory(category);
        updateTransaction(transaction);
      } else if (categoryFieldToDelete === transactionCategoryFieldToDelete.SUBCATEGORY_ONLY) {
        editMyCategory(category);
        updateTransactionDeleteSubCategoryId(transaction);
      }
      store.dispatch(this.ReviewActions.onTransactionUpdateSuccess(transaction));
    },
    onEditTransaction: (transaction, deleteSubCategoryId, deleteCategoryId) => {
      let categoryFieldToDelete = getTransactionCategoryFieldToDelete(deleteCategoryId, deleteSubCategoryId);

      if (categoryFieldToDelete === transactionCategoryFieldToDelete.NO_DELETE) {
        updateTransaction(transaction);
      } else if (categoryFieldToDelete === transactionCategoryFieldToDelete.SUBCATEGORY_ONLY) {
        updateTransactionDeleteSubCategoryId(transaction);
      } else if (categoryFieldToDelete === transactionCategoryFieldToDelete.ALL_CATEGORY_FIELDS) {
        updateTransactionDeleteCategoryId(transaction);
      }
      store.dispatch(this.ReviewActions.onTransactionUpdateSuccess(transaction));
    },
    updateTransaction: () => {
      let transactionName = this.state.local.name || defaultTransactionName;
      let transaction = constructUpdatedTransaction(
        this.state.remote.id,
        transactionName,
        this.state.local.amount,
        this.state.local.transactionDate,
        this.state.local.isExpense,
        this.state.local.currency,
        this.state.local.selectedCategory,
        this.state.local.selectedSubCategory
      );

      if (isLocalAndRemoteTransactionSame(this.state.remote, this.state.local)) {
        store.dispatch(this.ReviewActions.onTransactionUpdateSuccess(transaction));
        store.dispatch(showNotification(notifications.editTransaction, snackbarVariant.SUCCESS));
        return;
      }

      let action = getActionOnEditTransaction(
        this.state.local.userCategories,
        this.state.local.selectedCategory,
        this.state.local.selectedSubCategory,
        this.state.local.subCategories,
        this.state.local.enableSubCategory,
        this.state.local.suggestedCategories
      );

      let deleteSubCategoryId = shouldDeleteSubCategoryId(
        this.state.remote.selectedSubCategory.id,
        this.state.local.selectedSubCategory.id
      );

      let deleteCategoryId = shouldDeleteCategoryId(
        this.state.remote.selectedCategory.id,
        this.state.local.selectedCategory.id
      );

      if (action === actionOnEditTransaction.addCategoryEditTransaction) {
        let isSuggested = isSuggestedCategory(
          this.state.local.suggestedCategories,
          this.state.local.selectedCategory.id
        );

        let category = isSuggested
          ? constructNewCategory(
              this.state.local.selectedCategory.name,
              this.state.local.subCategories,
              this.state.local.enableSubCategory,
              false
            )
          : this.state.local.selectedCategory;
        this.ReviewActions.onAddCategoryAndEditTransaction(category, transaction, deleteSubCategoryId);
      }

      if (action === actionOnEditTransaction.editCategoryEditTransaction) {
        let category = getUpdatedCategory(
          this.state.local.selectedCategory,
          this.state.local.subCategories,
          this.state.local.enableSubCategory
        );
        this.ReviewActions.onEditCategoryAndEditTransaction(category, transaction, deleteSubCategoryId);
      }

      if (action === actionOnEditTransaction.onlyEditTransaction) {
        this.ReviewActions.onEditTransaction(transaction, deleteSubCategoryId, deleteCategoryId);
      }
      store.dispatch(showNotification(notifications.editTransaction, snackbarVariant.SUCCESS));
    },
    Name: {
      onNameChange: name => this.SharedActions.updateLocalState({ name }),
      onClearNameClick: () => {
        this.SharedActions.updateLocalState({ name: '' });
        this.nameTextRef.current.focus();
      }
    },

    Category: {
      updateLocalCategoryState: keyValuePairsAsObject => {
        const updatedCategoryState = Object.assign({}, this.state.local, keyValuePairsAsObject);
        this.setState(
          (state /*, props*/) => ({
            local: Object.assign({}, state.local, { ...updatedCategoryState })
          }),
          () => {}
        );
      },

      onCategoryClick: () => {
        getMyCategories(this.state.local.userId).then(userCategories => {
          const updatedCategoryState = Object.assign({}, this.state.local, {
            userCategories,
            showCategoryPage: true
          });
          this.SharedActions.updateLocalState(updatedCategoryState);
        });
      },
      onCategoryClose: () => this.SharedActions.updateLocalState({ showCategoryPage: false }),
      showSubCategory: selectedCategory => {
        if (selectedCategory.subCategoriesEnabled) {
          return {
            showSubcategory: true,
            selectedCategory: selectedCategory,
            subCategories: selectedCategory.subCategories,
            enableSubCategory: selectedCategory.subCategoriesEnabled
          };
        } else {
          return {
            showCategoryPage: false,
            showSubcategory: false,
            selectedCategory: selectedCategory,
            selectedSubCategory: {},
            enableSubCategory: selectedCategory.subCategoriesEnabled
          };
        }
      },
      showSubCategoriesForCategory: selectedCategory => {
        const value = this.ReviewActions.Category.showSubCategory(selectedCategory);
        this.ReviewActions.Category.updateLocalCategoryState({ ...value });
      },
      closeSubcategory: () => {
        this.ReviewActions.Category.updateLocalCategoryState({ showSubcategory: false });
      },
      onSubCategoryClick: selected => {
        const value = {
          selectedSubCategory: selected,
          showSubcategory: false,
          showCategoryPage: false
        };
        this.ReviewActions.Category.updateLocalCategoryState({ ...value });
      },
      showAddCategoryPage: () => {
        const value = {
          showAddCategory: true,
          categoryName: '',
          subCategoryName: ''
        };
        this.ReviewActions.Category.updateLocalCategoryState({ ...value });
      },
      closeAddCategoryPage: () => {
        this.ReviewActions.Category.updateLocalCategoryState({ showAddCategory: false });
      },
      onNewCategoryChange: newCategoryName => {
        this.ReviewActions.Category.updateLocalCategoryState({ categoryName: newCategoryName.toUpperCase() });
      },
      onNewSubCategoryChange: newSubCategoryName => {
        this.ReviewActions.Category.updateLocalCategoryState({ subCategoryName: newSubCategoryName.toUpperCase() });
      },
      newCategoryAdded: (categoryName, subCategoryName, userCategories) => {
        if (findMatchByName(userCategories, { name: categoryName })) {
          return {
            isDuplicateCategory: true
          };
        } else {
          let subCategoriesEnabled = subCategoryName.trim().length > 0;
          let selectedSubCategory = subCategoriesEnabled ? constructNewSubCategory(subCategoryName) : {};
          let subCategories = selectedSubCategory ? new Array(selectedSubCategory) : [];
          let selectedCategory = constructNewCategory(categoryName, subCategories, subCategoriesEnabled, true);
          return {
            showCategoryPage: false,
            showAddCategory: false,
            subCategories: subCategories,
            selectedCategory: selectedCategory,
            selectedSubCategory: selectedSubCategory,
            enableSubCategory: subCategoriesEnabled
          };
        }
      },
      onAddCategoryDone: () => {
        const { categoryName, subCategoryName, userCategories } = this.state.local;
        const value = this.ReviewActions.Category.newCategoryAdded(categoryName, subCategoryName, userCategories);
        if (value.isDuplicateCategory) {
          this.ReviewActions.Category.updateLocalCategoryState({ ...value });
        } else {
          this.ReviewActions.Category.updateLocalCategoryState({ ...value });
        }
      },
      onAddSubCategory: () => {
        this.ReviewActions.Category.updateLocalCategoryState({ showAddSubCategory: true, subCategoryName: '' });
      },
      onCloseAddSubCategory: () => {
        this.ReviewActions.Category.updateLocalCategoryState({ showAddSubCategory: false });
      },
      onNewSubCategoryAdded: (newSubCategory, existingSubCategories) => {
        if (findMatchByName(existingSubCategories, newSubCategory)) {
          return {
            isDuplicateSubCategory: true
          };
        } else {
          return {
            showCategoryPage: false,
            showSubcategory: false,
            showAddCategory: false,
            showAddSubCategory: false,
            selectedSubCategory: newSubCategory,
            subCategories: [...existingSubCategories, newSubCategory]
          };
        }
      },
      onAddSubCategoryDone: () => {
        const { subCategoryName, subCategories } = this.state.local;
        let newSubCategory = constructNewSubCategory(subCategoryName);
        const value = this.ReviewActions.Category.onNewSubCategoryAdded(newSubCategory, subCategories);
        if (value.isDuplicateSubCategory) {
          this.ReviewActions.Category.updateLocalCategoryState({ ...value });
        } else {
          this.ReviewActions.Category.updateLocalCategoryState({ ...value });
        }
      },
      suggestedCategoryLoaded: (tabValue, suggestedCategories, userCategories) => {
        let uniqueSuggestedCategories = filterUniqueCategories(userCategories, suggestedCategories);
        return {
          tabValue: tabValue,
          suggestedCategories: uniqueSuggestedCategories
        };
      },
      onTabChangeToSuggested: (event, tabValue) => {
        getDefaultCategories().then(suggestedCategories => {
          const value = this.ReviewActions.Category.suggestedCategoryLoaded(
            tabValue,
            suggestedCategories,
            this.state.local.userCategories
          );
          this.ReviewActions.Category.updateLocalCategoryState({ ...value });
        });
      },
      onDisableSubCategory: enableSubCategory => {
        const value = {
          enableSubCategory: enableSubCategory,
          confirmDisableSubCategory: true
        };
        this.ReviewActions.Category.updateLocalCategoryState({ ...value });
      },
      onCloseDisableSubCategoryDialog: () => {
        const value = { enableSubCategory: true, confirmDisableSubCategory: false };
        this.ReviewActions.Category.updateLocalCategoryState({ ...value });
      },
      onOkDisableSubCategory: () => {
        const value = {
          confirmDisableSubCategory: false,
          showSubcategory: false,
          showCategoryPage: false,
          selectedSubCategory: {},
          enableSubCategory: false
        };
        this.ReviewActions.Category.updateLocalCategoryState({ ...value });
      },
      onCloseDuplicateSubCategory: () => {
        this.ReviewActions.Category.updateLocalCategoryState({ isDuplicateSubCategory: false });
      },
      onCloseDuplicateCategory: () => {
        this.ReviewActions.Category.updateLocalCategoryState({ isDuplicateCategory: false });
      }
    }
  };

  render() {
    const {
      amount,
      name,
      isExpense,
      transactionDate,
      currency,
      account,
      selectedCategory,
      selectedSubCategory,
      showAmountPage,
      showReviewPage,
      showCategoryPage,
      openDiscardTransactionDialog
    } = this.state.local;
    return (
      <>
        {showReviewPage && (
          <ReviewScreen
            amount={amount}
            isExpense={isExpense}
            currency={currency}
            name={name}
            date={transactionDate}
            categoryName={selectedCategory.name}
            subCategoryName={selectedSubCategory.name}
            open={showReviewPage}
            nameTextRef={this.nameTextRef}
            openDiscardTransactionDialog={openDiscardTransactionDialog}
            onCloseClick={this.ReviewActions.onCloseClick}
            onSaveClick={this.ReviewActions.onSaveClick}
            onAmountClick={this.ReviewActions.onAmountClick}
            onDateClick={this.ReviewActions.onDateClick}
            onNameChange={this.ReviewActions.Name.onNameChange}
            onClearNameClick={this.ReviewActions.Name.onClearNameClick}
            onCategoryClick={this.ReviewActions.Category.onCategoryClick}
          />
        )}

        {showAmountPage && (
          <AmountScreen
            amount={amount}
            open={showAmountPage}
            isExpense={isExpense}
            currency={currency}
            account={account}
            showTypeCurrencyAccount={true}
            onCurrencyClick={this.AmountActions.onCurrencyClick}
            onTypeClick={this.AmountActions.onTypeClick}
            onCloseClick={this.AmountActions.onAmountClose}
            onAccountClick={this.AmountActions.onAccountClick}
            onNextClick={this.AmountActions.onNextClick}
          />
        )}

        {showCategoryPage && (
          <MFullScreenDialog
            open={showCategoryPage}
            title={'Category'}
            onClickClose={this.ReviewActions.Category.onCategoryClose}
          >
            <Category
              showSubcategory={this.state.local.showSubcategory}
              subCategories={this.state.local.subCategories}
              onShowSubCategory={this.ReviewActions.Category.showSubCategoriesForCategory}
              onCloseSubcategory={this.ReviewActions.Category.closeSubcategory}
              onSubCategorySelect={this.ReviewActions.Category.onSubCategoryClick}
              onAddCategory={this.ReviewActions.Category.showAddCategoryPage} //User clicks on ADD CATEGORY button
              showAddCategory={this.state.local.showAddCategory} //To show or close ADD CATEGORY SCREEN
              onCloseAddCategory={this.ReviewActions.Category.closeAddCategoryPage} //User close ADD CATEGORY SCREEN
              onNewCategoryChange={this.ReviewActions.Category.onNewCategoryChange} //OnChange event while typing name of category on ADD CATEGORY SCREEN
              onNewSubCategoryChange={this.ReviewActions.Category.onNewSubCategoryChange} //OnChange event while typing name of sub-category on ADD CATEGORY SCREEN
              onAddCategoryDone={this.ReviewActions.Category.onAddCategoryDone} //onClick event of DONE button once category/sub-category is added
              newCategoryName={this.state.local.categoryName} //name of new category passed
              newSubCategoryName={this.state.local.subCategoryName} //name of new sub-category passed
              showAddSubCategory={this.state.local.showAddSubCategory} //To show or close ADD SUB-CATEGORY Screen
              onAddSubCategory={this.ReviewActions.Category.onAddSubCategory} //User clicks on ADD SUB_CATEGORY button
              existingCategory={this.state.local.selectedCategory.name} //Name of existing Category to display
              onCloseAddSubCategory={this.ReviewActions.Category.onCloseAddSubCategory} //OnClose event to close ADD SUB_CATEGORY Screen
              onAddSubCategoryDone={this.ReviewActions.Category.onAddSubCategoryDone} //onClick event of DONE button once sub-category is added
              tabValue={this.state.local.tabValue}
              onCategoryTabChange={this.ReviewActions.Category.onTabChangeToSuggested}
              onDisableSubCategory={this.ReviewActions.Category.onDisableSubCategory}
              enableSubCategory={this.state.local.enableSubCategory}
              confirmDisableSubCategory={this.state.local.confirmDisableSubCategory}
              onCloseDisableSubCategoryDialog={this.ReviewActions.Category.onCloseDisableSubCategoryDialog}
              onOkDisableSubCategory={this.ReviewActions.Category.onOkDisableSubCategory}
              suggestedCategories={this.state.local.suggestedCategories}
              userCategories={this.state.local.userCategories}
              isDuplicateSubCategory={this.state.local.isDuplicateSubCategory}
              onCloseDuplicateSubCategory={this.ReviewActions.Category.onCloseDuplicateSubCategory}
              isDuplicateCategory={this.state.local.isDuplicateCategory}
              onCloseDuplicateCategory={this.ReviewActions.Category.onCloseDuplicateCategory}
              showEmptyCategories={this.state.local.showEmptyCategories}
            />
          </MFullScreenDialog>
        )}

        {openDiscardTransactionDialog && (
          <BottomDialogStyled
            open={openDiscardTransactionDialog}
            message={'Do you want to discard the changes to this transaction?'}
            okLabel={'Discard'}
            onOk={this.ReviewActions.onTransactionDiscardOk}
            cancelLabel={'Keep editing'}
            onCancel={this.ReviewActions.onTransactionDiscardCancel}
          />
        )}
      </>
    );
  }
}

export const AddEditWithRouter = withRouter(AddEdit);
