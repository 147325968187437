import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AppFooterActions from '../../../actions/app/appFooterActions';
import * as CreateBudgetActions from '../../../actions/budgets/createBudgetActions';
import App from '../../app/index';
import { CreateNewBudget } from '../../../components/Budgets/Create';
import { withRouter } from 'react-router';
import { Routes } from '../../../config/app-constants';
import { userIdFromCache } from '../../shared/util';

class CreateBudgets extends React.Component {
  componentWillMount() {
    this.props.highlightBudgets();
    this.props.clearCreateBudget();
  }

  onChangeFrequency = event => {
    this.props.changeFrequency(event.target.checked);
  };

  onAmountClick = amount => {
    this.props.onBudgetAmountDone(amount);
  };

  onSubmitBudget = budget => {
    let existingBudgets = this.props.listBudgetState.budgets;
    this.props.submitBudget(budget, existingBudgets);
    this.props.history.push(Routes.budgets);
  };

  onBudgetCategoryClicked = () => {
    let budgetedCategoryIds = this.props.listBudgetState.budgets.map(b => b.categoryId);

    this.props.openBudgetCategory(userIdFromCache(), budgetedCategoryIds);
  };

  render() {
    let showEmptyCategory = this.props.budgetState.userCategories.length <= 0;
    return (
      <App showAvatar={true} title={'New Budget'}>
        <CreateNewBudget
          onOpenBudgetCategory={this.onBudgetCategoryClicked}
          userCategories={this.props.budgetState.userCategories}
          shouldOpenCategory={this.props.budgetState.shouldOpenCategory}
          onCloseBudgetCategory={this.props.closeBudgetCategory}
          onBudgetCategorySelected={this.props.budgetCategorySelected}
          selectedCategory={this.props.budgetState.selectedCategory}
          shouldOpenAmount={this.props.budgetState.shouldOpenAmount}
          onOpenBudgetAmount={this.props.openBudgetAmount}
          onCloseBudgetAmount={this.props.closeBudgetAmount}
          isMonthly={this.props.budgetState.isMonthly}
          isMonthlyLabel={this.props.budgetState.isMonthlyLabel}
          onChangeFrequency={this.onChangeFrequency}
          budgetedAmount={this.props.budgetState.amount}
          onAmountDone={this.onAmountClick}
          addBudget={this.onSubmitBudget}
          showEmptyCategory={showEmptyCategory}
        />
      </App>
    );
  }
}

let mapStateToProps = state => {
  return {
    budgetState: state.createBudgetReducer,
    listBudgetState: state.listBudgetReducer
  };
};

let mapDispatchToProps = dispatch => {
  return {
    highlightBudgets: bindActionCreators(AppFooterActions.highlightBudgets, dispatch),
    openBudgetCategory: bindActionCreators(CreateBudgetActions.openBudgetCategory, dispatch),
    closeBudgetCategory: bindActionCreators(CreateBudgetActions.closeBudgetCategory, dispatch),
    openBudgetAmount: bindActionCreators(CreateBudgetActions.openBudgetAmount, dispatch),
    closeBudgetAmount: bindActionCreators(CreateBudgetActions.closeBudgetAmount, dispatch),
    budgetCategorySelected: bindActionCreators(CreateBudgetActions.budgetCategorySelected, dispatch),
    changeFrequency: bindActionCreators(CreateBudgetActions.changeFrequency, dispatch),
    onBudgetAmountDone: bindActionCreators(CreateBudgetActions.onBudgetAmountDone, dispatch),
    submitBudget: bindActionCreators(CreateBudgetActions.submitBudget, dispatch),
    clearCreateBudget: bindActionCreators(CreateBudgetActions.clearCreateBudgetForm, dispatch)
  };
};
const CreateBudgetsWithRouter = withRouter(CreateBudgets);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBudgetsWithRouter);
