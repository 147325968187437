import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import moment from 'moment';
import { MCard } from '../../mui/MCard';
import { MDivider } from '../../mui/MDivider';
import { MIconRightArrow } from '../../mui/MIconRightArrow';
import './styles.css';
import { Typography } from '@material-ui/core';
import { getTransactionTypeSymbol } from '../../../containers/shared/util';

export const Transaction = props => {
  let item = props.transaction;
  let transactionDate = new Date(item.date);
  let date = transactionDate.getDate();
  let weekday = moment(transactionDate)
    .format('ddd')
    .toUpperCase();
  return (
    <div className="transaction-card">
      <MCard onClick={() => props.onTransactionClick(item)}>
        <Row around="xs" className="transaction-row">
          <Col xs={2}>
            <Row center="xs">
              <Typography color={'primary'} variant={'body2'}>
                {date}
              </Typography>
            </Row>
            <Row center="xs" className="transaction-weekday">
              {weekday}
            </Row>
          </Col>
          <Col xs={4}>
            <Typography color={'primary'} variant={'body2'}>
              {item.name}
            </Typography>
          </Col>
          <Col xs={3} className="transaction-debit">
            <Typography color={'primary'} variant={'body2'}>{`${getTransactionTypeSymbol(item.isExpense)} ${
              item.amount
            }`}</Typography>
          </Col>
          <MIconRightArrow />
        </Row>
        <MDivider />
      </MCard>
    </div>
  );
};
