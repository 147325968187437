import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Chip from '@material-ui/core/Chip';
import { MButton } from '../../mui/MButton';
import { colors } from '../../mui/colors';
import { CreateCategory } from '../CreateCategory';
import MFullScreenDialog from '../../mui/MFullScreenDialog';
import { Placeholder } from '../../Placeholder';
import { emptyComponents } from '../../../config/app-constants';
import { MLinearProgress } from '../../mui/MLinearProgress';

export const MyCategories = ({
  categoryName,
  onCategoryChange,
  enableSubCategory,
  onEnableSubCategory,
  onAddSubCategory,
  subCategoryName,
  onSubCategoryChange,
  onClickActionButton,
  disableActionButton,
  actionButtonLabel,
  onRemoveSubCategory,
  showEditPage,
  onEdit,
  onCloseEdit,
  myCategories,
  onDelete,
  subCategories,
  showSubCategorySwitch,
  myCategoriesLoaded,
  showEmptyMyCategories
}) => {
  return (
    <>
      {!myCategoriesLoaded && <MLinearProgress />}

      {showEmptyMyCategories && (
        <Placeholder
          title={emptyComponents.categoriesViaManage.title}
          text={emptyComponents.categoriesViaManage.text}
          image={emptyComponents.categoriesViaManage.image}
        />
      )}

      {myCategories && <NonEmptyCategories myCategories={myCategories} onEdit={onEdit} onDelete={onDelete} />}

      {showEditPage && (
        <MFullScreenDialog open={showEditPage} onClickClose={onCloseEdit} title={'CATEGORY'}>
          <CreateCategory
            subCategoryTitle={'SUB-CATEGORY'}
            categoryName={categoryName}
            subCategoryName={subCategoryName}
            actionButtonLabel={actionButtonLabel}
            onCategoryChange={onCategoryChange}
            onSubCategoryChange={onSubCategoryChange}
            onAddSubCategory={onAddSubCategory}
            disableActionButton={disableActionButton}
            onClickActionButton={onClickActionButton}
            enableSubCategory={enableSubCategory}
            onEnableSubCategory={onEnableSubCategory}
            subCategories={subCategories}
            disableAddSubCategoryButton={subCategoryName.trim().length === 0}
            showSubCategorySwitch={showSubCategorySwitch}
            onRemoveSubCategory={onRemoveSubCategory}
          />
        </MFullScreenDialog>
      )}
    </>
  );
};

export const NonEmptyCategories = ({ myCategories, onEdit, onDelete }) => {
  return (
    <div style={{ marginTop: '1vh' }}>
      {myCategories.map((category, key) => (
        <MyCategory key={key} category={category} onEdit={onEdit} onDelete={onDelete} />
      ))}
    </div>
  );
};

export const MyCategory = ({ category, onEdit, onDelete }) => {
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{category.name}</Typography>
      </ExpansionPanelSummary>
      {category.subCategoriesEnabled && (
        <ExpansionPanelDetails>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {category.subCategories.map((subCategory, key) => (
              <div key={key} style={{ paddingLeft: '1vw', paddingRight: '1vw', paddingTop: '1vh' }}>
                {!subCategory.deletedAt && <Chip label={subCategory.name} key={key} variant="outlined" />}
              </div>
            ))}
          </div>
        </ExpansionPanelDetails>
      )}
      <Row around="xs">
        <Col xs={6}>
          <MButton variant={'text'} style={{ color: colors.primary5Color }} onClick={() => onEdit(category)}>
            EDIT
          </MButton>
        </Col>
        <Col xs={6}>
          <MButton variant={'text'} style={{ color: colors.debitColor }} onClick={() => onDelete(category)}>
            DELETE
          </MButton>
        </Col>
      </Row>
    </ExpansionPanel>
  );
};
