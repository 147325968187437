import { fieldValue, firebaseDb } from '../config/firebase-constants';
import moment from 'moment';
import { budgetFrequency } from '../config/app-constants';

// https://firebase.google.com/docs/firestore/manage-data/data-types
// includeDocumentMetadataChanges to know when backend has written the local changes
// includeQueryMetadataChanges to know if changes come from cache using 'fromCache' property
// https://firebase.google.com/docs/firestore/manage-data/enable-offline?authuser=0#listen_to_offline_data

const transactionCollection = 'transactions';
const userCollection = 'users';
const budgetCollection = 'budgets';
const feedbackCollection = 'feedbacks';
const categoryCollection = 'categories';
const suggestedCategoryCollection = 'suggested_categories';
const currencies = 'currencies';
const transactionRef = firebaseDb.collection(transactionCollection);
const budgetRef = firebaseDb.collection(budgetCollection);
const feedbackRef = firebaseDb.collection(feedbackCollection);
const categoryRef = firebaseDb.collection(categoryCollection);
const suggestedCategoryRef = firebaseDb.collection(suggestedCategoryCollection);
const currenciesRef = firebaseDb.collection(currencies);
const listeners = [];

export const fetchTransactions = (year, month, userId) => {
  let startDate = moment(new Date(year, month, 1, 0, 0, 0, 0));
  let endDate = moment(new Date(year, month, 1, 0, 0, 0, 0)).add(1, 'M');

  return new Promise(resolve => {
    let listener = transactionRef
      .where('date', '>=', startDate.toDate())
      .where('date', '<', endDate.toDate())
      .where('userId', '==', userId)
      .orderBy('date', 'desc')
      .onSnapshot(querySnapshot => {
        let transactions = [];
        querySnapshot.forEach(doc => {
          let transaction = doc.data();
          transaction.id = doc.id;
          transaction.createdAt = transaction.createdAt.toDate();
          transaction.date = transaction.date.toDate();
          if (transaction.lastModified) transaction.lastModified = transaction.lastModified.toDate();
          transactions.push(transaction);
        });
        resolve(transactions);
      });
    listeners.push(listener);
  });
};

/**
 * https://firebase.google.com/docs/firestore/query-data/get-data#get_a_document
 * https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#doc
 * @param userId
 * @returns {Promise<firebase.firestore.DocumentSnapshot>}
 */
export const fetchUser = userId => {
  return getUserById(userId).get();
};

export const addTransaction = transaction => {
  let doc = transactionRef.doc();
  doc.set(transaction);
  return new Promise(res => res(doc.id));
};

/**
 * todo: Only performed this action for freemium users
 * @param userId
 * @param remaining
 * @returns {Promise<void>}
 */
export const updateNumTransactionsAndMonth = (userId, remaining) => {
  let updatePayload = {
    'account.remainingTransactionThisMonth': remaining,
    'account.currentMonth': new Date().getMonth() + 1
  };
  return getUserById(userId).update(updatePayload);
};

/**
 * Save userInfo to db if it doesn't exist
 * @param userInfo
 */
export const saveUserInfo = userInfo => {
  return firebaseDb
    .collection(userCollection)
    .doc(userInfo.uid)
    .set(userInfo);
};

export const deleteTransactionWithId = transactionId => {
  return firebaseDb
    .collection(transactionCollection)
    .doc(transactionId)
    .delete();
};

/**
 * Add 1 free transaction for a given user who deletes a current month transaction
 * @param userId
 */
export const addOneFreeTransaction = userId => {
  fetchUser(userId)
    .then(docRef => {
      return docRef.data().account.remainingTransactionThisMonth;
    })
    .then(remainingTransactionThisMonth => {
      return updateRemainingTransactionsAfterDelete(userId, remainingTransactionThisMonth + 1);
    });
};

/**
 * Update remainingTransactionThisMonth for the given user
 * @param userId
 * @param remaining
 * @returns {Promise<void>}
 */
export const updateRemainingTransactionsAfterDelete = (userId, remaining) => {
  return getUserById(userId).update({
    'account.remainingTransactionThisMonth': remaining
  });
};

export const updateTransaction = transaction => {
  return transactionRef.doc(transaction.id).update(transaction);
};

export const updateTransactionDeleteCategoryId = transaction => {
  return transactionRef.doc(transaction.id).update({
    name: transaction.name,
    amount: transaction.amount,
    date: transaction.date,
    isExpense: transaction.isExpense,
    currency: transaction.currency,
    lastModified: transaction.lastModified,
    userId: transaction.userId,
    categoryId: fieldValue.delete(),
    subCategoryId: fieldValue.delete()
  });
};

export const updateTransactionDeleteSubCategoryId = transaction => {
  return transactionRef.doc(transaction.id).update({
    name: transaction.name,
    amount: transaction.amount,
    date: transaction.date,
    isExpense: transaction.isExpense,
    currency: transaction.currency,
    lastModified: transaction.lastModified,
    userId: transaction.userId,
    categoryId: transaction.categoryId,
    subCategoryId: fieldValue.delete()
  });
};

function getUserById(userId) {
  return firebaseDb.collection(userCollection).doc(userId);
}

export const saveDefaultCurrencyForThisUser = (userId, defaultCurrency, lastModified) => {
  return getUserById(userId).update({
    lastModified: lastModified,
    settings: { defaultCurrency: defaultCurrency }
  });
};

/**
 * Add the given budget
 * @param budget
 * @returns {Promise<firebase.firestore.DocumentReference>}
 */
export const addBudget = budget => {
  return budgetRef.add(budget);
};

/**
 * Get all Budgets for this user
 * budget.createdAt gives Firebase Timestamp Value.
 * We need to call toDate() to convert it back to JavaScript Date
 * https://firebase.google.com/docs/reference/android/com/google/firebase/Timestamp.html#toDate()
 * @param userId
 * @returns {Promise<any>}
 */
export const getAllBudgets = userId => {
  return new Promise(resolve => {
    let listener = budgetRef
      .where('userId', '==', userId)
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        let budgets = [];
        querySnapshot.forEach(doc => {
          let budget = doc.data();
          if (budget.frequency === budgetFrequency.monthly || isInCurrentMonth(budget.createdAt.toDate())) {
            budget.id = doc.id;
            budgets.push(budget);
          }
        });
        resolve(budgets);
      });
    listeners.push(listener);
  });
};

/**
 * Returns true if the date is in current month.
 * @param date
 * @returns {boolean}
 */
function isInCurrentMonth(date) {
  var dateAsMoment = moment(date);

  let now = moment();
  let currentMonth = now.month();
  let currentYear = now.year();
  return dateAsMoment.month() === currentMonth && dateAsMoment.year() === currentYear;
}

/**
 * Delete a budget given the budgetId
 * @param budgetId
 * @returns {Promise<WriteResult>}
 */
export const deleteBudgetWithId = budgetId => {
  return budgetRef.doc(budgetId).delete();
};

export const submitFeedback = (userId, description) => {
  let feedbackRecord = {
    userId: userId,
    description: description,
    createdAt: new Date()
  };
  return feedbackRef.add(feedbackRecord);
};

export const saveCategory = category => {
  let doc = categoryRef.doc();
  doc.set(category);
  return new Promise(res => res(doc.id));
};

export const getMyCategories = userId => {
  return new Promise(resolve => {
    let listener = categoryRef.where('userId', '==', userId).onSnapshot(querySnapshot => {
      let categories = [];
      querySnapshot.forEach(doc => {
        let category = doc.data();
        category.id = doc.id;
        categories.push(category);
      });
      resolve(categories);
    });
    listeners.push(listener);
  });
};

export const editMyCategory = category => {
  return categoryRef.doc(category.id).update(category);
};

export const deleteMyCategory = category => {
  return categoryRef.doc(category.id).delete();
};

export const getDefaultCategories = () => {
  return new Promise(resolve => {
    let listener = suggestedCategoryRef.onSnapshot(querySnapshot => {
      let suggestedCategories = [];
      querySnapshot.forEach(doc => {
        let suggestedCategory = doc.data();
        suggestedCategory.id = doc.id;
        suggestedCategories.push(suggestedCategory);
      });
      resolve(suggestedCategories);
    });
    listeners.push(listener);
  });
};

export const unSubscribeBeforeLogout = () => {
  listeners.forEach(listener => listener());
};

export const getAllCurrencies = () => {
  return new Promise(resolve => {
    let listener = currenciesRef.onSnapshot(querySnapshot => {
      let allCurrencies = [];
      querySnapshot.forEach(doc => {
        let currency = doc.data();
        currency.id = doc.id;
        allCurrencies.push(currency);
      });
      resolve(allCurrencies);
    });
    listeners.push(listener);
  });
};
