import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as overviewActions from '../../../actions/overview/overviewActions';
import { OverviewDetailPage } from '../../../components/OverviewDetailPage';
import { getCurrencySymbol } from '../../shared/util';

class OverviewDetail extends React.Component {
  getSymbol = () => {
    return getCurrencySymbol(this.props.currencies.defaultCurrency.shortName);
  };

  render() {
    let {
      category,
      isBudgetedCategory,
      budgeted,
      spent,
      earned,
      spendingBySubCategory
    } = this.props.overviewState.showDetails;
    return (
      <OverviewDetailPage
        category={category}
        daysRemaining={this.props.overviewState.remainingDaysInMonth}
        isBudgetedCategory={isBudgetedCategory}
        budgeted={budgeted}
        spent={spent}
        earned={earned}
        spendingBySubCategory={spendingBySubCategory}
        onClick={this.props.hideDetailPage}
        currencySymbol={this.getSymbol()}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    overviewState: state.overviewReducer,
    currencies: state.currencyReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideDetailPage: bindActionCreators(overviewActions.hideOverviewDetail, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewDetail);
