import React from 'react';
import { UserOnboard } from '../../components/UserOnboard';
import addImage from '../../images/icons/onboard/add-transaction.svg';
import categoryIcon from '../../images/icons/onboard/hierarchy.svg';
import currencyIcon from '../../images/icons/onboard/international.svg';
import budgetIcon from '../../images/icons/onboard/calculator.svg';
import overviewIcon from '../../images/icons/onboard/bar-chart.svg';
import feedbackIcon from '../../images/icons/onboard/feedback.svg';

export default class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'ADD TRANSACTION',
      description: 'Add transaction in less than 5 sec and categorize them later using edit transaction.',
      icon: addImage,
      disablePrevious: true,
      disableNext: false,
      openOnBoard: props.showOnBoard || true
    };
  }

  getNextCard = currentCard => {
    switch (currentCard) {
      case 'ADD TRANSACTION':
        return this.getCategoryCard();
      case 'CUSTOMIZE YOUR CATEGORY':
        return this.getCurrencyCard();
      case 'CHOOSE YOUR CURRENCY':
        return this.getBudgetCard();
      case 'CREATE YOUR OWN BUDGETS':
        return this.getOverviewCard();
      case 'MONTHLY OVERVIEW':
        return this.getFeedbackCard();
      default:
        return this.getAddTransactionCard();
    }
  };

  getPreviousCard = currentCard => {
    switch (currentCard) {
      case 'CUSTOMIZE YOUR CATEGORY':
        return this.getAddTransactionCard();
      case 'CHOOSE YOUR CURRENCY':
        return this.getCategoryCard();
      case 'CREATE YOUR OWN BUDGETS':
        return this.getCurrencyCard();
      case 'MONTHLY OVERVIEW':
        return this.getBudgetCard();
      case 'SEND US FEEDBACK':
        return this.getOverviewCard();
      default:
        return this.getAddTransactionCard();
    }
  };

  onClickNextCard = currentCard => {
    let nextCard = this.getNextCard(currentCard);
    this.setState({
      title: nextCard.title,
      description: nextCard.description,
      icon: nextCard.icon,
      disablePrevious: nextCard.disablePrevious,
      disableNext: nextCard.disableNext
    });
  };

  onClickPreviousCard = currentCard => {
    let previousCard = this.getPreviousCard(currentCard);
    this.setState({
      title: previousCard.title,
      description: previousCard.description,
      icon: previousCard.icon,
      disablePrevious: previousCard.disablePrevious,
      disableNext: previousCard.disableNext
    });
  };

  onSkip = () => {
    this.setState({ openOnBoard: false });
    localStorage.removeItem('isNewUser');
  };

  getAddTransactionCard = () => {
    return {
      title: 'ADD TRANSACTION',
      description: 'Add transaction in less than 5 sec and categorize them later using edit transaction.',
      icon: addImage,
      disablePrevious: true,
      disableNext: false
    };
  };

  getCategoryCard = () => {
    return {
      title: 'CUSTOMIZE YOUR CATEGORY',
      description: 'Customize suggested categories or create your own',
      icon: categoryIcon,
      disablePrevious: false,
      disableNext: false
    };
  };

  getCurrencyCard = () => {
    return {
      title: 'CHOOSE YOUR CURRENCY',
      description: 'We support all major currencies.Choose your default currency from settings.',
      icon: currencyIcon,
      disablePrevious: false,
      disableNext: false
    };
  };

  getBudgetCard = () => {
    return {
      title: 'CREATE YOUR OWN BUDGETS',
      description: 'Create either recurring or one time budgets and track your progress.',
      icon: budgetIcon,
      disablePrevious: false,
      disableNext: false
    };
  };

  getOverviewCard = () => {
    return {
      title: 'MONTHLY OVERVIEW',
      description: 'Get unified and detailed view of monthly income, expenses,remaining budget under each category.',
      icon: overviewIcon,
      disablePrevious: false,
      disableNext: false
    };
  };

  getFeedbackCard = () => {
    return {
      title: 'SEND US FEEDBACK',
      description:
        'Let us know if we can help you on your way to financial freedom. Your feedback is important for us.',
      icon: feedbackIcon,
      disablePrevious: false,
      disableNext: true
    };
  };

  render() {
    return (
      <UserOnboard
        title={this.state.title}
        description={this.state.description}
        icon={this.state.icon}
        onClickNext={this.onClickNextCard}
        onClickPrevious={this.onClickPreviousCard}
        disablePrevious={this.state.disablePrevious}
        disableNext={this.state.disableNext}
        showOnBoard={this.state.openOnBoard}
        onSkip={this.props.onSkip || this.onSkip}
      />
    );
  }
}
