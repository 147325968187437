import React from 'react';
import AppCard from '../AppCard';
import { OverviewDetailCardHeader } from './OverviewDetailCardHeader';
import { OverviewDetailCardBody } from './OverviewDetailCardBody';
import { MDivider } from '../mui/MDivider';

export const OverviewDetailCard = ({
  cardColor,
  subCategory,
  numTransactions,
  totalAmountLabel,
  totalAmountValue,
  totalAmountColor,
  transactions,
  currencySymbol
}) => (
  <AppCard color={cardColor || '#FFF'}>
    <OverviewDetailCardHeader
      subCategory={subCategory}
      numTransactions={numTransactions}
      totalAmountLabel={totalAmountLabel}
      totalAmountValue={totalAmountValue}
      totalAmountColor={totalAmountColor}
      currencySymbol={currencySymbol}
    />
    <MDivider style={{ marginTop: '1vh', marginBottom: '2vh' }} />
    <OverviewDetailCardBody transactions={transactions} currencySymbol={currencySymbol} />
  </AppCard>
);
