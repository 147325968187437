import * as ReactGA from "react-ga";

// reference: https://gitlab.com/bonsaii/moneybonsai/wikis/google-analytics-events
export const CATEGORIES = {
    TRANSACTIONS: 'TRANSACTIONS',
    CURRENCIES: 'CURRENCIES',
    OVERVIEW_DETAIL: 'OVERVIEW_DETAIL'
};

export const ACTIONS = {
    [CATEGORIES.TRANSACTIONS]: {
        ADDED: 'ADDED',
        UPDATED: 'UPDATED',
        DELETED: 'DELETED',
    },
    [CATEGORIES.CURRENCIES]: {
        UPDATED: 'UPDATED'
    },
    [CATEGORIES.OVERVIEW_DETAIL]: {
        VIEWED: 'VIEWED'
    }
};

export const analyticsEvent = (category, action) => {
    ReactGA.event({category, action});
};