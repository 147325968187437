import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import Transactions from './containers/transactions/list/index';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Welcome from './containers/home/welcome/index';
import { notifications, Routes, snackbarVariant } from './config/app-constants';
import Settings from './containers/settings/index';
import { Provider } from 'react-redux';
import configureStore from './store/index';
import { theme } from '../src/components/mui/theme';
import CreateBudgets from './containers/budgets/create/index';
import ListBudgets from './containers/budgets/list/index';
import * as currencyActions from './actions/settings/currencyActions';
import { userIdFromCache } from './containers/shared/util';
import More from './containers/more';
import withTracker from './analytics/withTracker';
import Overview from './containers/overview/list';
import { Website } from './containers/website';
import { Privacy } from './components/Legal/Privacy';
import { Terms } from './components/Legal/Terms';
import { Cookies } from './components/Legal/Cookies';
import { showNotification } from './actions/app/appFooterActions';
import { getMyCategories, getDefaultCategories, getAllCurrencies } from './db';

export const store = configureStore();

export const upgradeReady = serviceWorker => {
  let onOk = () => {
    serviceWorker.postMessage('skipWaiting');
  };

  let notification = notifications.upgrade;
  store.dispatch(
    showNotification(notification.message, snackbarVariant.NEW_RELEASE, notification.okLabel, onOk, false)
  );
};

/**
 * This is needed if user is on AddTransaction and refreshes the page.
 * But if user is logged out and app is loaded it crashes since userId is
 * not in cache. In such case, we only fire this action if user if logged in.
 */
let userIdCache = userIdFromCache();
if (userIdCache) {
  currencyActions.loadDefaultCurrency()(store.dispatch);
  getMyCategories(userIdCache);
  getDefaultCategories();
  getAllCurrencies();
}

const customHistory = createBrowserHistory();
const Root = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Router history={customHistory}>
        <div>
          <Route path={Routes.appRoot} exact={true} component={withTracker(Website)} />
          <Route path={Routes.privacy} exact={true} component={withTracker(Privacy)} />
          <Route path={Routes.terms} exact={true} component={withTracker(Terms)} />
          <Route path={Routes.cookies} exact={true} component={withTracker(Cookies)} />
          <Route path={Routes.welcome} component={withTracker(Welcome)} />
          <Route path={Routes.overview} component={withTracker(Overview)} />
          <Route path={Routes.transactions} component={withTracker(Transactions)} />
          <Route path={Routes.settings} component={withTracker(Settings)} />
          <Route path={Routes.budgets} exact={true} component={withTracker(ListBudgets)} />
          <Route path={Routes.budgetsAdd} component={withTracker(CreateBudgets)} />
          <Route path={Routes.more} component={withTracker(More)} />
          {/* Redirect other paths to custom 404 Page */}
        </div>
      </Router>
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
registerServiceWorker();
