import React from 'react';
import { firebaseAuth } from '../../../config/firebase-constants';
import welcome from '../../../images/icons/others/welcome.svg';
import {
  currentUserId,
  currentUserPhoto,
  defaultCurrencyNewUser,
  freemiumAccount,
  freemiumUsersMaxAllowedTransactionsPerMonth,
  isNewUser,
  Routes,
  welcomeTimeoutInSeconds
} from '../../../config/app-constants';
import { fetchUser, saveUserInfo } from '../../../db/index';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as currencyActions from '../../../actions/settings/currencyActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

let timeOutFunction;

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    let initiateAuthentication = props.location.state ? props.location.state.initiateAuthentication : false;
    this.state = {
      initiateAuthentication: initiateAuthentication
    };
  }

  componentDidMount() {
    timeOutFunction = setTimeout(
      function() {
        this.props.history.push(Routes.appRoot);
      }.bind(this),
      welcomeTimeoutInSeconds
    );
  }

  updateUserInfo = user => {
    fetchUser(user.uid)
      .then(function(doc) {
        if (!doc.exists) {
          let userInfo = {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email || '',
            providerId: user.providerData[0]['providerId'],
            createdAt: new Date(),
            account: {
              type: freemiumAccount,
              currentMonth: new Date().getMonth() + 1,
              remainingTransactionThisMonth: freemiumUsersMaxAllowedTransactionsPerMonth
            },
            settings: { defaultCurrency: defaultCurrencyNewUser }
          };
          localStorage.setItem(isNewUser, 'true');
          saveUserInfo(userInfo);
        }
      })
      .catch(function(/* error */) {
        // console.log('Error fetching user');
      });
  };

  componentWillMount() {
    firebaseAuth().onAuthStateChanged(user => {
      if (user) {
        let userId = firebaseAuth().currentUser.uid;
        localStorage.setItem(currentUserId, userId);
        localStorage.setItem(currentUserPhoto, user.photoURL);
        /**
         * If user logs in, we want to fetch their defaultCurrency
         * and set in redux store proactively.
         */
        this.props.loadDefaultCurrency();
        firebaseAuth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(
            function(/* idToken */) {
              this.updateUserInfo(user);
              this.props.history.push(Routes.overview);
            }.bind(this)
          )
          .catch(
            function(error) {
              // console.log('Error while fetching summary:', error);
              this.props.history.push(Routes.appRoot);
            }.bind(this)
          );
      }
    });
  }

  componentWillUnmount() {
    clearTimeout(timeOutFunction);
  }

  render() {
    if (this.state.initiateAuthentication) return <div />;
    return (
      <Grid container direction={'column'} spacing={8} justify={'center'} alignItems={'center'}>
        <Grid item style={{ paddingTop: '8vh' }}>
          <Typography variant={'h5'} style={{ color: '#3D5E8A' }}>
            Getting your account ready!
          </Typography>
        </Grid>

        <Grid item style={{ paddingTop: '8vh', paddingLeft: '5vw' }}>
          <CircularProgress size={40} status="loading" style={RefreshStyle} />
        </Grid>

        <Grid item style={{ paddingTop: '8vh' }}>
          <img src={welcome} alt={welcome} />
        </Grid>
      </Grid>
    );
  }
}

let RefreshStyle = {
  display: 'inline-block',
  position: 'relative',
  color: '#3D5E8A'
};

let mapDispatchToProps = dispatch => {
  return {
    loadDefaultCurrency: bindActionCreators(currencyActions.loadDefaultCurrency, dispatch)
  };
};
export default connect(
  null,
  mapDispatchToProps
)(Welcome);
