import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { colors } from '../../components/mui/colors';
import AppCard from '../AppCard';
import { MIconRightArrow } from '../mui/MIconRightArrow';
import { CategoryImage } from '../../containers/shared/categoryImage';
import { roundForDisplay } from '../../containers/shared/util';

export const OverviewCard = ({ cardColor, categoryName, amount, isOverSpent, onClick, currencySymbol }) => {
  let expenseStyle = {
    color: isOverSpent ? colors.debitColor : colors.creditColor,
    fontWeight: 500,
    fontSize: '90%'
  };

  return (
    <AppCard color={cardColor}>
      <Row middle="xs" center="xs" onClick={onClick}>
        <Col xs={2} sm={2} md={2} lg={2}>
          <CategoryImage categoryName={categoryName} w={24} h={24} />
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} className="overview-body-category-name">
          {categoryName}
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} style={expenseStyle}>
          {currencySymbol + roundForDisplay(amount)}
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <MIconRightArrow />
        </Col>
      </Row>
    </AppCard>
  );
};
