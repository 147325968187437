import {actionTypes} from "../../config/app-constants";
import {submitFeedback} from "../../db";

export function submitUserFeedback(userId, description) {
    return function(dispatch){
        return submitFeedback(userId, description)
            .then(() => dispatch(feedbackSubmitted()))
    }
};

export function feedbackSubmitted() {
    return {type: actionTypes.feedbackActionTypes.SUBMITTED_OK, value: {feedbackSubmitted: true, description:''}}
}

export function onDescriptionChange(description){
    return {type: actionTypes.feedbackActionTypes.ON_DESCRIPTION_CHANGE, value: {description: description}}
}