import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';

export const Placeholder = ({ title, text, image }) => {
  return (
    <Row>
      <Col xs={12}>
        <Row center="xs" style={{ paddingBottom: '5vh', paddingTop: '3vh' }}>
          <Col xs={10}>
            <Typography variant="h6">{title}</Typography>
          </Col>
        </Row>

        <Row center="xs" style={{ paddingBottom: '3vh' }}>
          <Col xs={10}>
            <Typography component="p" color="textSecondary" gutterBottom>
              {text}
            </Typography>
          </Col>
        </Row>

        <Row center="xs">
          <Col xs={10}>
            <img alt="" src={image} style={{ height: '30' }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
