import { getCategoryIdToLabel, getSubCategoryIdToLabel } from './categoryWorkflow';
import { colors } from '../../components/mui/colors';
import { getAbsDiff, getDifference } from './util';

/**
 * This will be called from UI only when a user clicks on the Overview card.
 * This means that, technically, it is NEVER possible that transactions will
 * be empty. Because if that's the case, the OverviewCard itself will not be present
 * for the click from the UI in the first place.
 * Also, this method does not take budgets into account.
 * @param transactions: for the category for which the user tapped the Overview Card
 * @param subCategories: of the category for which the user tapped the Overview Card
 */
export const getOrderedSpendingsBySubCategory = (transactions, subCategories) => {
  let subCategoryPayload = getSpendingsBySubCategory(transactions, subCategories);

  // format: [{netSpending: someValue, payload: {}]
  let netSpendingToPayload = Object.keys(subCategoryPayload).map(subCategory => {
    let payload = subCategoryPayload[subCategory];
    let netSpending = payload.spent - payload.earned;
    return { subCategory, netSpending, payload };
  });

  // array is sorted in place
  return netSpendingToPayload.sort((a, b) => b.netSpending - a.netSpending);
};

let getSpendingsBySubCategory = (transactions, subCategories) => {
  return transactions.reduce((accumulator, transaction) => {
    let transactionAmount = parseFloat(transaction.amount);
    let subCategoryName = getSubCategoryIdToLabel(transaction.subCategoryId, subCategories);
    let subCategorySpendingSoFar = accumulator[subCategoryName] || {};

    let spentInCategoryBefore = subCategorySpendingSoFar['spent'] || 0.0;
    subCategorySpendingSoFar['spent'] = transaction.isExpense
      ? spentInCategoryBefore + transactionAmount
      : spentInCategoryBefore;

    let earnedInCategoryBefore = subCategorySpendingSoFar['earned'] || 0.0;
    subCategorySpendingSoFar['earned'] = !transaction.isExpense
      ? earnedInCategoryBefore + transactionAmount
      : earnedInCategoryBefore;

    let transactionsInCategoryBefore = subCategorySpendingSoFar['transactions'] || [];
    subCategorySpendingSoFar['transactions'] = [...transactionsInCategoryBefore, transaction];
    return Object.assign({}, accumulator, {
      [subCategoryName]: subCategorySpendingSoFar
    });
  }, {});
};

export const getOverviewPayload = (transactions, budgets, userCategories) => {
  let initialValue = {
    totalSpent: 0.0,
    totalEarned: 0.0,
    spendingByCategory: {}
  };

  let budgetsMap = getBudgetsMapForNonDeletedCategories(budgets, userCategories);

  return transactions.reduce((accumulator, transaction) => {
    let transactionAmount = parseFloat(transaction.amount);
    let totalSpent = transaction.isExpense ? accumulator.totalSpent + transactionAmount : accumulator.totalSpent;
    let totalEarned = !transaction.isExpense ? accumulator.totalEarned + transactionAmount : accumulator.totalEarned;

    let transactionCategoryName = getCategoryIdToLabel(transaction.categoryId, userCategories);
    let isBudgetedCategory = budgetsMap.hasOwnProperty(transactionCategoryName);

    let spendingForCategorySoFar = accumulator.spendingByCategory[transactionCategoryName] || {};
    spendingForCategorySoFar['isBudgetedCategory'] = isBudgetedCategory;
    spendingForCategorySoFar['budgeted'] = isBudgetedCategory
      ? parseFloat(budgetsMap[transactionCategoryName].amount)
      : 0.0;

    let totalSpentInCategoryBefore = spendingForCategorySoFar['spent'] || 0.0;
    spendingForCategorySoFar['spent'] = transaction.isExpense
      ? totalSpentInCategoryBefore + transactionAmount
      : totalSpentInCategoryBefore;

    let totalEarnedInCategoryBefore = spendingForCategorySoFar['earned'] || 0.0;
    spendingForCategorySoFar['earned'] = !transaction.isExpense
      ? totalEarnedInCategoryBefore + transactionAmount
      : totalEarnedInCategoryBefore;

    let transactionsInCategoryBefore = spendingForCategorySoFar['transactions'] || [];
    spendingForCategorySoFar['transactions'] = [...transactionsInCategoryBefore, transaction];

    let spendingByCategory = Object.assign({}, accumulator.spendingByCategory, {
      [transactionCategoryName]: spendingForCategorySoFar
    });
    return { totalSpent, totalEarned, spendingByCategory };
  }, initialValue);
};

let getBudgetsMapForNonDeletedCategories = (budgets, userCategories) => {
  return budgets.reduce((acc, budget) => {
    // todo[mb-149]: This is O(n) vs lookup if getMyCategory returns a map keyed by categoryId
    let categoriesForBudget = userCategories.filter(uc => uc.id === budget.categoryId);
    if (categoriesForBudget.length === 1) {
      let categoryPayload = categoriesForBudget[0];
      let payload = {
        [categoryPayload.name]: {
          amount: parseFloat(budget.amount),
          subCategory: categoryPayload.subCategories[0], // subCategory does not matter
          budgetSubCategory: budget.subCategory
        }
      };
      return Object.assign({}, acc, payload);
    }
    return Object.assign({}, acc);
  }, {});
};

export const getNetSpentFromBudgeted = (spent, earned, budgeted) => {
  let netSpent = getDifference(spent, earned);
  return getAbsDiff(budgeted, netSpent);
};

export const getOverBudgetOrRemainingColor = (spent, budgeted) =>
  spent > budgeted ? colors.debitColor : colors.creditColor;

export const getOverBudgetOrRemainingLabel = (spent, budgeted) => (spent > budgeted ? 'OVER BUDGET' : 'REMAINING');

export const getSpentOrEarnedColor = (spent, earned) => (spent > earned ? colors.debitColor : colors.creditColor);

export const getSpentOrEarnedLabel = (spent, earned) => (spent > earned ? 'SPENT' : 'EARNED');
