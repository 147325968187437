import { actionTypes } from '../../config/app-constants';

export let defaultBudget = {
  userCategories: [],
  selectedCategory: '',
  isMonthly: true,
  isMonthlyLabel: 'ON',
  shouldOpenCategory: false,
  shouldOpenAmount: false,
  amount: '0.0'
};

export default function addBudgetReducer(state = defaultBudget, action) {
  switch (action.type) {
    case actionTypes.createBudgetActionTypes.OPEN_BUDGET_CATEGORY_SUCCESS:
      return Object.assign({}, state, action.value);
    case actionTypes.createBudgetActionTypes.OPEN_BUDGET_CATEGORY:
      return Object.assign({}, state, action.value);
    case actionTypes.createBudgetActionTypes.CLOSE_BUDGET_CATEGORY:
      return Object.assign({}, state, action.value);
    case actionTypes.createBudgetActionTypes.BUDGET_CATEGORY_SELECTED:
      return Object.assign({}, state, action.value);
    case actionTypes.createBudgetActionTypes.OPEN_BUDGET_AMOUNT:
      return Object.assign({}, state, action.value);
    case actionTypes.createBudgetActionTypes.BUDGET_AMOUNT_DONE:
      return Object.assign({}, state, { shouldOpenAmount: action.value.shouldOpenAmount, amount: action.value.amount });
    case actionTypes.createBudgetActionTypes.CHANGE_FREQUENCY:
      return Object.assign({}, state, action.value);
    case actionTypes.createBudgetActionTypes.CLEAR_CREATE_BUDGET:
      return defaultBudget;
    default:
      return state;
  }
}
