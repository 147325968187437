import React from 'react';
import { Col } from 'react-flexbox-grid';
import './styles.css';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../mui/colors';

export const TagLine = () => (
  <Col xs={12} sm={12} md={12} lg={12} className="tag-line">
    <Typography variant="h6" style={{ color: colors.primaryTextColor }} gutterBottom>
      Track, Manage and Analyze your Finances
    </Typography>
  </Col>
);
