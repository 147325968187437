import React from 'react';
import { TextField, withStyles } from '@material-ui/core';

// https://github.com/mui-org/material-ui/issues/11503#issuecomment-390502979

const styles = {
  root: {}
};

class InputText extends React.Component {
  render() {
    const { value, style, onChange, disabled = false, placeholder } = this.props;

    return (
      <TextField
        id="transaction-amount"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth={true}
        InputProps={{ disableUnderline: true }}
        style={style}
      />
    );
  }
}

export const MTextField = withStyles(styles)(InputText);
