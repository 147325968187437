import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { MIconInfo } from '../../../mui/MIconInfo';
import { colors } from '../../../mui/colors';

export const BudgetExceededStatus = () => (
  <Row className="budget-exceeded-status">
    <Col xsOffset={1} xs={1} sm={1} md={1} lg={1}>
      <MIconInfo style={{ height: 15 }} color={colors.budgetExceededColor} />
    </Col>
    <Col xsOffset={1} xs={4} sm={4} md={4} lg={4} className="budget-exceeded-status-text">
      budget exceeded
    </Col>
  </Row>
);
