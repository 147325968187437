import React from 'react';
import { MButton } from '../../../mui/MButton';
import { colors } from '../../../mui/colors';
import { SignInButtons } from '../SignInButtons';
import { Col, Row } from 'react-flexbox-grid';
import { MDialog } from '../../../mui/MDialog';
import './styles.css';

const SignInPopUp = ({ open, onClose, loginWithGoogle, loginWithFacebook, loginWithTwitter, message }) => (
  <MDialog open={open} onClose={onClose}>
    <Row center="xs">
      <Col xs={12} sm={12} md={12} lg={12}>
        <p className="sign-in-header-label">{message}</p>
      </Col>

      <Col xs={12} sm={12} md={12} lg={12}>
        <SignInButtons
          loginWithGoogle={loginWithGoogle}
          loginWithFacebook={loginWithFacebook}
          loginWithTwitter={loginWithTwitter}
        />
      </Col>
    </Row>
  </MDialog>
);

class GetStarted extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    let buttonColor = this.props.signIn ? colors.lightBlue : colors.primary4Color;
    let buttonLabel = this.props.signIn ? 'LOG IN' : 'SIGN UP FREE';
    let signInOrSignUpMessage = this.props.signIn ? 'Login with' : 'Sign up using your';
    return (
      <Col xs={12} sm={12} md={12} lg={12}>
        <MButton
          size={'small'}
          fullWidth={false}
          onClick={this.handleClickOpen}
          style={{ backgroundColor: buttonColor, color: colors.black }}
        >
          {buttonLabel}
        </MButton>

        <SignInPopUp
          message={signInOrSignUpMessage}
          open={this.state.open}
          onClose={this.handleClose}
          loginWithGoogle={this.props.loginWithGoogle}
          loginWithFacebook={this.props.loginWithFacebook}
          loginWithTwitter={this.props.loginWithTwitter}
        />
      </Col>
    );
  }
}

export default GetStarted;
