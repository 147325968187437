import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackButton from '@material-ui/icons/ArrowBack';
import Avatar from '@material-ui/icons/PersonOutlined';
import PropTypes from 'prop-types';
import WifiOff from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { UserAvatar } from '../UserAvatar';
import { currentUserPhoto } from '../../config/app-constants';

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
};

function Header(props) {
  const { classes, title, onBackButtonClick, showAvatar, onClickInfo, hideInfoIcon } = props;
  const photo = localStorage.getItem(currentUserPhoto);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          {!showAvatar && (
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={onBackButtonClick}>
              <BackButton />
            </IconButton>
          )}

          {showAvatar && (
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
              {photo && <UserAvatar src={photo} />}
              {!photo && <Avatar />}
            </IconButton>
          )}

          <Typography variant="h6" color="inherit" className={classes.grow}>
            {title}
          </Typography>

          <div>
            {!navigator.onLine && (
              <IconButton color="inherit">
                <WifiOff />
              </IconButton>
            )}
            {!hideInfoIcon && (
              <IconButton color="inherit" onClick={onClickInfo}>
                <HelpIcon />
              </IconButton>
            )}
          </div>
          {props.toolbar}
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};
export const MBHeader = withStyles(styles)(Header);
