import { actionTypes } from '../../config/app-constants';
import { addBudget, getMyCategories } from '../../db';
import { getRemainingCategoriesToBudget } from '../../containers/shared/budgetsWorkflow';

export function openBudgetCategory(userId, budgetedCategoryIds) {
  return function(dispatch) {
    return getMyCategories(userId)
      .then(userCategories => {
        let categoriesAvailableToBudget = getRemainingCategoriesToBudget(userCategories, budgetedCategoryIds);
        dispatch(openBudgetCategorySuccess(categoriesAvailableToBudget));
      })
      .catch(error => {
        console.log('Error fetching userCategories for budgets create: ', error.message);
      });
  };
}

export function openBudgetCategorySuccess(userCategories) {
  return {
    type: actionTypes.createBudgetActionTypes.OPEN_BUDGET_CATEGORY_SUCCESS,
    value: { shouldOpenCategory: true, userCategories: userCategories }
  };
}

export function closeBudgetCategory() {
  return {
    type: actionTypes.createBudgetActionTypes.CLOSE_BUDGET_CATEGORY,
    value: { shouldOpenCategory: false }
  };
}

export function budgetCategorySelected(selectedCategory) {
  return {
    type: actionTypes.createBudgetActionTypes.BUDGET_CATEGORY_SELECTED,
    value: { selectedCategory: selectedCategory, shouldOpenCategory: false }
  };
}

export function openBudgetAmount() {
  return {
    type: actionTypes.createBudgetActionTypes.OPEN_BUDGET_AMOUNT,
    value: { shouldOpenAmount: true }
  };
}

export function closeBudgetAmount() {
  return {
    type: actionTypes.createBudgetActionTypes.OPEN_BUDGET_AMOUNT,
    value: { shouldOpenAmount: false }
  };
}

export function onBudgetAmountDone(amount) {
  return {
    type: actionTypes.createBudgetActionTypes.BUDGET_AMOUNT_DONE,
    value: { shouldOpenAmount: false, amount: amount }
  };
}

export function changeFrequency(isMonthly) {
  const isMonthlyLabel = isMonthly ? 'ON' : 'OFF';
  return {
    type: actionTypes.createBudgetActionTypes.CHANGE_FREQUENCY,
    value: { isMonthly: isMonthly, isMonthlyLabel: isMonthlyLabel }
  };
}

export function submitBudget(newBudget, existingBudgets) {
  if (isBudgetDuplicate(newBudget, existingBudgets)) {
    return function(dispatch) {
      dispatch(showDuplicateBudget());
    };
  } else {
    return function(dispatch) {
      addBudget(newBudget);
      dispatch(clearCreateBudgetForm());
    };
  }
}

function isBudgetDuplicate(newBudget, existingBudgets) {
  return existingBudgets.filter(budget => budget.categoryId === newBudget.categoryId).length > 0;
}

export function showDuplicateBudget() {
  return {
    type: actionTypes.createBudgetActionTypes.SHOW_DUPLICATE_BUDGET
  };
}

export function clearCreateBudgetForm() {
  return { type: actionTypes.createBudgetActionTypes.CLEAR_CREATE_BUDGET };
}
