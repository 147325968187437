import React from 'react';
import {Col, Row} from "react-flexbox-grid";
import './styles.css';
import {MButton} from "../../../mui/MButton";
import {MIcon} from "../../../mui/MIcon";

export const SignInButtons = ({loginWithGoogle, loginWithFacebook, loginWithTwitter}) => {
    return <Row center={'xs'} className='sign-in-buttons'>
        <SignInButton provider={'Google'} backgroundColor='#E05139' labelColor='#FFFFFF'
                      iconClass="fa fa-google-plus" onClick={loginWithGoogle}/>
        <SignInButton provider={'Facebook'} backgroundColor='#3A5898' labelColor='#FFFFFF'
                      iconClass="fa fa-facebook" onClick={loginWithFacebook}/>
        <SignInButton provider={'Twitter'} backgroundColor='#55ACEE' labelColor='#FFFFFF'
                      iconClass="fa fa-twitter" onClick={loginWithTwitter}/>
    </Row>
};

const SignInButton = ({icon, backgroundColor, labelColor, provider, iconClass, onClick}) => (
    <Col xs={12} sm={12} md={12} lg={12} className='sign-in-button'>
        <Row center={'xs'}>
            <Col xs={9} sm={6} md={6} lg={6}>
                <MButton color="secondary"
                         onClick={onClick}
                         style={{backgroundColor: backgroundColor}}>
                    <SignInButtonContent labelColor={labelColor}
                                         iconClass={iconClass}
                                         provider={provider}/>
                </MButton>
            </Col>
        </Row>
    </Col>
);

const SignInButtonContent = ({labelColor, iconClass, provider}) => (
    <Row style={{color: labelColor}}>
        <Col xs={4} sm={4} md={4} lg={4}>
            <MIcon style={{fontSize: '120%', width: '2em'}} className={iconClass}/>
        </Col>
        <Col xs={5} sm={5} md={5} lg={5}>
            <span>{provider}</span>
        </Col>
    </Row>
);