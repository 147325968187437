import React from 'react';
import { MListItem } from '../../../mui/MListItem';

export const CurrencyItem = ({ currency, saveDefaultCurrency }) => {
  const currencyCode = currency.code;
  const currencyDetailName = currency.name;
  return (
    <MListItem
      primaryText={currencyCode}
      secondaryText={currencyDetailName}
      onClick={() => saveDefaultCurrency(currencyCode)}
    />
  );
};
