import React from 'react';
import './styles.css';
import { colors } from '../../../components/mui/colors';
import { BottomNavigation, BottomNavigationAction, Paper, withStyles } from '@material-ui/core';
import { AddCircleOutlined, InsertChartOutlined, List, MoreHoriz, SwapVert } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Routes } from '../../../config/app-constants';
import { withRouter } from 'react-router';
import { MSnackBar } from '../../../components/mui/MSnackBar';
import { bindActionCreators } from 'redux';
import * as appFooterActions from '../../../actions/app/appFooterActions';
import classNames from 'classnames';
import { AddEditWithRouter } from '../../transactions/AddEdit';

const featureNavigationStyles = theme => ({
  root: {
    color: colors.surface
  },
  selected: {
    color: colors.lightBlue
  }
});

class FeatureNavigation extends React.Component {
  render() {
    const { classes, selected } = this.props;

    const className = classNames(classes.root, {
      [classes.selected]: selected
    });

    return (
      <BottomNavigationAction
        showLabel
        label={this.props.label}
        icon={this.props.icon}
        onClick={this.props.onClick}
        className={className}
      />
    );
  }
}

const StyledFeatureNavigation = withStyles(featureNavigationStyles)(FeatureNavigation);

class AppFooter extends React.Component {
  load = route => {
    this.props.history.push(route);
  };

  render() {
    let { notification, openAddTransaction } = this.props.appFooter;
    const { classes } = this.props;

    return (
      <div className="app-footer">
        <MSnackBar
          open={notification.show}
          autoHide={notification.autoHide}
          okLabel={notification.okLabel}
          onOkClick={notification.onOkClick}
          onCloseClick={this.props.closeNotification}
          message={notification.message}
          variant={notification.variant}
        />
        {openAddTransaction && <AddEditWithRouter currency={this.props.appFooter.defaultCurrency} />}
        <Paper style={{ width: '100%' }}>
          <BottomNavigation showLabels value={this.props.appFooter.selectedIndex} className={classes.root}>
            <StyledFeatureNavigation
              label={'Overview'}
              icon={<InsertChartOutlined />}
              onClick={() => this.load(Routes.overview)}
            />

            <StyledFeatureNavigation label="Budgets" icon={<SwapVert />} onClick={() => this.load(Routes.budgets)} />

            <BottomNavigationAction
              icon={<AddCircleOutlined color={'secondary'} fontSize={'large'} />}
              onClick={this.props.openAddTransaction}
            />

            <StyledFeatureNavigation
              label="Transactions"
              icon={<List />}
              onClick={() => this.load(Routes.transactions)}
            />
            <StyledFeatureNavigation label="More" icon={<MoreHoriz />} onClick={() => this.load(Routes.more)} />
          </BottomNavigation>
        </Paper>
      </div>
    );
  }
}

const bottomNavigationStyles = {
  root: {
    backgroundColor: colors.primaryBlue
  }
};

const AppFooterWithStyles = withStyles(bottomNavigationStyles)(AppFooter);

let mapStateToProps = state => {
  return {
    appFooter: state.appFooterReducer
  };
};

let mapDispatchToProps = dispatch => {
  return {
    closeNotification: bindActionCreators(appFooterActions.closeNotification, dispatch),
    openAddTransaction: bindActionCreators(appFooterActions.openAddTransactionPage, dispatch),
    closeAddTransaction: bindActionCreators(appFooterActions.closeAddTransactionPage, dispatch)
  };
};

const AppFooterWithRouter = withRouter(AppFooterWithStyles);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppFooterWithRouter);
