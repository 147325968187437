import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './styles.css';
import { roundForDisplay } from '../../../containers/shared/util';

export const OverviewDetailBudgetHeaderItem = ({ label, value, valueColor, currencySymbol }) => (
  <Row>
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ color: `${valueColor}` }}
      className="overview-detail-budget-header-item-value"
    >
      {currencySymbol + roundForDisplay(value)}
    </Col>
    <Col xs={12} sm={12} md={12} lg={12} className="overview-detail-budget-header-item-label">
      {label}
    </Col>
  </Row>
);
