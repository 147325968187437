import React from 'react';
import App from '../../containers/app';
import { List, ListItem } from '@material-ui/core';
import SettingsImage from '@material-ui/icons/Settings';
import FeedbackImage from '@material-ui/icons/Feedback';
import ManageCategoriesImage from '@material-ui/icons/Category';
import { currentUserId, defaultCurrency, Routes } from '../../config/app-constants';
import LogoutImage from '@material-ui/icons/Input';
import { logout } from '../../containers/home/login/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AppFooterActions from '../../actions/app/appFooterActions';
import * as MoreActions from '../../actions/more/moreActionTypes';
import UserFeedback from '../feedback';
import { colors } from '../../components/mui/colors';
import * as AppHeaderActions from '../../actions/app/appHeaderActions';
import Onboarding from '../onboarding';
import Categories from '../manageCategories';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CurrencyDisplay } from '../../components/CurrencyDisplay';

class More extends React.Component {
  componentWillMount() {
    this.props.highlightMore();
  }

  signOut = () => {
    logout().then(
      function() {
        localStorage.removeItem(currentUserId);
        localStorage.removeItem(defaultCurrency);
        this.props.history.push(Routes.appRoot);
      }.bind(this)
    );
  };

  render() {
    let userCurrencyCode = this.props.currencies.defaultCurrency.shortName;
    let userCurrencyName = this.props.currencies.defaultCurrency.fullName;

    return (
      <div>
        {!this.props.moreState.openManageCategories && (
          <App showAvatar={true} title={'More'}>
            <CurrencyDisplay currencyCode={userCurrencyCode} currencyName={userCurrencyName} />

            <List component={'nav'}>
              <ListItem button onClick={() => this.props.history.push(Routes.settings)}>
                <ListItemIcon>
                  <SettingsImage nativeColor={colors.grayColor} color="inherit" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>

              <ListItem button onClick={this.props.onOpenManageCategories}>
                <ListItemIcon>
                  <ManageCategoriesImage nativeColor={colors.grayColor} color="inherit" />
                </ListItemIcon>
                <ListItemText primary="Manage Categories" />
              </ListItem>

              <ListItem button onClick={this.props.openFeedback}>
                <ListItemIcon>
                  <FeedbackImage nativeColor={colors.grayColor} color="inherit" />
                </ListItemIcon>
                <ListItemText primary="Feedback" />
              </ListItem>

              <UserFeedback openFeedback={this.props.moreState.openFeedback} onClose={this.props.closeFeedback} />

              <ListItem button onClick={this.signOut}>
                <ListItemIcon>
                  <LogoutImage nativeColor={colors.grayColor} color="inherit" />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </ListItem>
            </List>
            {this.props.appHeaderState.showOnBoard && (
              <Onboarding showOnBoard={this.props.appHeaderState.showOnBoard} onSkip={this.props.onCloseOnBoarding} />
            )}
          </App>
        )}
        {this.props.moreState.openManageCategories && (
          <Categories onBackButtonClick={this.props.backFromManageCategories} />
        )}
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    moreState: state.moreReducer,
    appHeaderState: state.appHeaderReducer,
    currencies: state.currencyReducer
  };
};

let mapDispatchToProps = dispatch => {
  return {
    highlightMore: bindActionCreators(AppFooterActions.highlightMore, dispatch),
    openFeedback: bindActionCreators(MoreActions.openFeedbackDialog, dispatch),
    closeFeedback: bindActionCreators(MoreActions.closeFeedbackDialog, dispatch),
    onCloseOnBoarding: bindActionCreators(AppHeaderActions.closeOnBoarding, dispatch),
    onOpenManageCategories: bindActionCreators(MoreActions.openManageCategories, dispatch),
    backFromManageCategories: bindActionCreators(MoreActions.backFromManageCategories, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(More);
