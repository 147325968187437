import React from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import { CategoryRow } from '../../../Transactions/Add/Category/CategoryRow';
import MFullScreenDialog from '../../../mui/MFullScreenDialog';
import { Placeholder } from '../../../Placeholder';
import { emptyComponents } from '../../../../config/app-constants';

export const BudgetCategory = ({
  shouldOpenCategory,
  onOpenBudgetCategory,
  userCategories,
  onCloseBudgetCategory,
  onBudgetCategorySelected,
  selectedCategory,
  showEmptyCategory
}) => {
  return (
    <div>
      <Card>
        <CardHeader subheader="CATEGORY" />
        <Row className="selected-budget-category-container" onClick={onOpenBudgetCategory}>
          <Col xs={12} sm={12} md={12} lg={12}>
            {selectedCategory.name}
          </Col>
        </Row>
      </Card>
      <MFullScreenDialog
        open={shouldOpenCategory}
        onClickClose={onCloseBudgetCategory}
        title={'Select Budget Category'}
      >
        {showEmptyCategory && (
          <Placeholder
            title={emptyComponents.categoriesViaBudgetCreate.title}
            text={emptyComponents.categoriesViaBudgetCreate.text}
            image={emptyComponents.categoriesViaBudgetCreate.image}
          />
        )}

        <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '2vh' }}>
          {userCategories.map((userCategory, key) => (
            <div key={key}>
              <CategoryRow category={userCategory} onCategoryClick={onBudgetCategorySelected} />
            </div>
          ))}
        </div>
      </MFullScreenDialog>
    </div>
  );
};
