import React from 'react';
import { OverviewHeader } from '../OverviewHeader';
import { OverviewBudgetCard } from '../OverviewBudgetCard';
import { OverviewCard } from '../OverviewCard';
import { didSpentMoreThanBudgeted, getAbsDiff } from '../../containers/shared/util';
import { categoryNameToCardColor, getSubCategoriesFor } from '../../containers/shared/categoryWorkflow';
import { Placeholder } from '../Placeholder';
import { emptyComponents } from '../../config/app-constants';

/**
 * ToDo: The iteration happens twice to club budget cards before non-budgeted ones
 *
 */
export const OverviewPage = ({
  totalSpent,
  totalEarned,
  remainingDaysInMonth,
  spendingByCategory,
  userCategories,
  onClick,
  showEmptyPlaceholder,
  currencySymbol
}) => {
  let getPayloadFor = categoryName => {
    let spendingForThisCategory = spendingByCategory[categoryName];
    let budgeted = spendingForThisCategory.budgeted;
    let isOverSpent = didSpentMoreThanBudgeted(spendingForThisCategory.spent, budgeted);
    let transactions = spendingForThisCategory.transactions;
    let subCategories = getSubCategoriesFor(categoryName, userCategories);

    let callback = () =>
      onClick(
        categoryName,
        spendingForThisCategory.isBudgetedCategory,
        isOverSpent,
        budgeted,
        spendingForThisCategory.spent,
        spendingForThisCategory.earned,
        transactions,
        subCategories
      );
    return { budgeted, isOverSpent, callback };
  };

  return (
    <div style={{ paddingBottom: '10vh' }}>
      <OverviewHeader
        spent={totalSpent}
        earned={totalEarned}
        daysLeft={remainingDaysInMonth}
        currencySymbol={currencySymbol}
      />

      {showEmptyPlaceholder && (
        <Placeholder
          title={emptyComponents.overview.title}
          text={emptyComponents.overview.text}
          image={emptyComponents.overview.image}
        />
      )}

      {Object.keys(spendingByCategory).map(categoryName => {
        let { budgeted, isOverSpent, callback } = getPayloadFor(categoryName);
        let cardColor = categoryNameToCardColor(categoryName);
        let currentCategory = spendingByCategory[categoryName];

        return (
          currentCategory.isBudgetedCategory && (
            <OverviewBudgetCard
              cardColor={cardColor}
              key={categoryName}
              categoryName={categoryName}
              spent={getAbsDiff(currentCategory.spent, currentCategory.earned)}
              budgeted={budgeted}
              numTransactions={currentCategory.transactions.length}
              isOverBudget={isOverSpent}
              onClick={callback}
              currencySymbol={currencySymbol}
            />
          )
        );
      })}

      {Object.keys(spendingByCategory).map(categoryName => {
        let { isOverSpent, callback } = getPayloadFor(categoryName);
        let cardColor = categoryNameToCardColor(categoryName);
        let currentCategory = spendingByCategory[categoryName];
        return (
          !currentCategory.isBudgetedCategory && (
            <OverviewCard
              cardColor={cardColor}
              key={categoryName}
              categoryName={categoryName}
              amount={getAbsDiff(currentCategory.spent, currentCategory.earned)}
              isOverSpent={isOverSpent}
              onClick={callback}
              currencySymbol={currencySymbol}
            />
          )
        );
      })}
    </div>
  );
};
