import React from 'react';
import { Fab } from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import { colors } from '../../mui/colors';
import './styles.css';
import { BudgetCategory } from './BudgetCategory';
import { BudgetAmount } from './BudgetAmount';
import { BudgetFrequency } from './BudgetFrequency';
import { userIdFromCache } from '../../../containers/shared/util';
import { budgetFrequency } from '../../../config/app-constants';

export const CreateNewBudget = ({
  shouldOpenCategory,
  onOpenBudgetCategory,
  userCategories,
  onCloseBudgetCategory,
  onBudgetCategorySelected,
  selectedCategory,
  isMonthly,
  shouldOpenAmount,
  onOpenBudgetAmount,
  onCloseBudgetAmount,
  budgetedAmount,
  onAmountDone,
  isMonthlyLabel,
  onChangeFrequency,
  addBudget,
  showEmptyCategory
}) => {
  let submitBudget = () => {
    const budget = {
      userId: userIdFromCache(),
      amount: budgetedAmount,
      categoryId: selectedCategory.id,
      frequency: isMonthly ? budgetFrequency.monthly : budgetFrequency.once,
      createdAt: new Date(),
      subCategory: false
    };
    addBudget(budget);
  };

  let inValidForm = budgetedAmount <= 0.0 || !selectedCategory.name;
  let doneColor = inValidForm ? '' : colors.primaryTextColor;

  return (
    <div>
      <BudgetCategory
        onOpenBudgetCategory={onOpenBudgetCategory}
        userCategories={userCategories}
        shouldOpenCategory={shouldOpenCategory}
        onCloseBudgetCategory={onCloseBudgetCategory}
        onBudgetCategorySelected={onBudgetCategorySelected}
        selectedCategory={selectedCategory}
        showEmptyCategory={showEmptyCategory}
      />
      <BudgetAmount
        shouldOpenAmount={shouldOpenAmount}
        onOpenBudgetAmount={onOpenBudgetAmount}
        onCloseBudgetAmount={onCloseBudgetAmount}
        budgetedAmount={budgetedAmount}
        onAmountDone={onAmountDone}
      />

      <BudgetFrequency isMonthlyLabel={isMonthlyLabel} onChangeFrequency={onChangeFrequency} />

      <div className="budget-ok">
        <Fab
          color={'secondary'}
          disabled={inValidForm}
          children={<Done nativeColor={doneColor} />}
          onClick={submitBudget}
        />
      </div>
    </div>
  );
};
